<app-navbar></app-navbar>
<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="mb-0">{{labName}}</h2>
            <!-- <ul>
                <li><a routerLink="/">Home</a></li>

            </ul> -->
        </div>
    </div>
</div>
<!-- End Page Title -->
<!--Breadcrumb-->
<section class="contact-page register-page my-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;"> {{labName }}</span>
            </div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<div class="container bg-light">
    <div class="row" style="margin-bottom: 25px;">
        <div class="col-lg-4">
            <div class="partner-item border-0" style="box-shadow:0 0 20px 3px rgba(0, 0, 0, 0.05);">
                <a style="display: block;">
                    <div style="background:#fff;height:250px;">
                        <div style="height: 200px;align-items: center;display: flex;padding:25px">
                            <img src="{{labLogo}}" alt="Image" class="img-fluid"
                                style="object-fit: cover;max-height: 100%;">
                        </div>
                        <div style="height:60px">
                            <h4 style="color:#ef8017; text-align: center;">{{labName}}</h4>
                        </div>
                    </div>
                </a>
            </div>
                    </div>
        <div class="col-lg-8">
            <div class=" row">
                <div class="d-inline" *ngFor="let labCertificate of labCertificate" style="margin-right: 15px;">
                    <img src="{{labCertificate.Certificate_Logo}}" class="d-inline rounded-circle">
                    <h6 style="text-align: center;">{{labCertificate.Certificate_Name}}</h6>
                </div>
            </div>
            <div class="text-justify container1 mt-2">{{aboutLabInfo}}</div>

        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 pl-0">
            <h3>Popular Tests</h3>
            <hr>

            <div class="row">
                <div class="col-lg-3 pr-0" *ngFor="let labTest of labWiseTest">
                    <div class="packagecard card border-0 rightMargin"
                        style="box-shadow:0 0 20px 3px rgba(0, 0, 0, 0.05); padding-top:5px;margin-bottom: 10px;">
                        <div class="card-body" style="height: 330px;padding: 15px;">
                            <div style="cursor: pointer;" (click)="redirectTestDetail(labTest.Lab_Id,labTest.Test_Id)">
                                <h6 class=" mb-2" style="height: 30px;">{{labTest.Test_Name}}</h6>
                                <div style="height: 225px;">
                                    <div style="height:130px">
                                        <p class="mb-0 pt-2"> Includes:<b>{{labTest.Parameter_Included}}
                                                Parameter</b></p>
                                        <ul class="highlight-test">
                                            <li *ngIf="labTest.Highlighted_Test_1_Web">
                                                {{labTest.Highlighted_Test_1_Web}}
                                            </li>
                                            <li *ngIf="labTest.Highlighted_Test_2_Web">
                                                {{labTest.Highlighted_Test_2_Web}}
                                            </li>
                                            <li *ngIf="labTest.Highlighted_Test_3_Web">
                                                {{labTest.Highlighted_Test_3_Web}}
                                            </li>
                                        </ul>
                                    </div>
                                    <p class="font-weight-bolder mb-0"
                                        style="text-align: center;font-size: 18px;border-bottom: 1px solid #eaecf1;margin-top: 5px;color: #ef8017;padding-bottom: 10px;">
                                        {{labTest.Lab_Name}}
                                    </p>
                                    <div class="mt-2" style="border-bottom: 1px solid #eaecf1;padding-bottom: 4px;">
                                        <h4 style="font-size:20px;display:inline">₹{{labTest.Selling_Amt}}
                                            <del *ngIf="labTest.Disc_Per!=0" style="color: #aaa;font-size: 16px;">
                                                ₹{{labTest.Test_MRP}}
                                            </del>
                                        </h4>
                                        <span *ngIf="labTest.Disc_Per>0;"
                                            style="color: orange;border-radius: 5px 0 0 5px;float: right;">
                                            {{labTest.Disc_Per | number:'0.0-0'}}% OFF
                                        </span>
                                    </div>
                                </div>
                                <ul style="padding-top: 10px;">
                                    <li class="read">
                                        <a style="cursor: pointer;color:#ef8017"
                                            (click)="redirectTestDetail(labTest.Lab_Id,labTest.Test_Id)">
                                            Know More <i class="flaticon-right-arrow"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>