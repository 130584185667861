import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { FooterComponent } from './component/footer/footer.component';
import { HomePageComponent } from './component/home-page/home-page.component';
import { PreloaderComponent } from './component/preloader/preloader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ViewAllCategoriesComponent } from './component/view-all-categories/view-all-categories.component';
import { PopularTestComponent } from './component/popular-test/popular-test.component';
import { ViewAllPackagesComponent } from './component/view-all-packages/view-all-packages.component';
import { PackageDetailComponent } from './component/package-detail/package-detail.component';
import { LabDetailComponent } from './component/lab-detail/lab-detail.component';
import { MycartComponent } from './component/mycart/mycart.component';
import { BillSummaryComponent } from './component/bill-summary/bill-summary.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { NgOtpInputModule } from 'ng-otp-input';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { CategorywisetestComponent } from './component/categorywisetest/categorywisetest.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RefreshComponentComponent } from './component/refresh-component/refresh-component.component';
import { AboutusComponent } from './component/aboutus/aboutus.component';
import { ContactusComponent } from './component/contactus/contactus.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { ViewprofileComponent } from './component/viewprofile/viewprofile.component';
import { WalletComponent } from './component/wallet/wallet.component';
import { MobilevalidationDirective } from './directive/mobilevalidation.directive';
import { MyordersComponent } from './component/myorders/myorders.component';
import { MyprescriptionorderComponent } from './component/myprescriptionorder/myprescriptionorder.component';
import { MyreportsComponent } from './component/myreports/myreports.component';
import { SearchpackagetestComponent } from './component/searchpackagetest/searchpackagetest.component';
import { BillSummaryService } from './service/billsummary.service';
import { CategoryService } from './service/category.service';
import { CategoryTestService } from './service/categorytest.service';
import { CustomerService } from './service/customer.service';
import { HomeService } from './service/home.service';
import { LabDetailService } from './service/labdetail.service';
import { LabPackageService } from './service/labpackage.service';
import { OrderService } from './service/order.service';
import { MyCartService } from './service/mycart.service';
import { LoginService } from './service/login.service';
import { PrivacypolicyComponent } from './component/privacypolicy/privacypolicy.component';

const config = {
  apiKey: "AIzaSyAFH3zfeYmF-oqEervo38TinjcsBRY1CGE",
  authDomain: "mygeniebox-f661a.firebaseapp.com",
  databaseURL: "https://mygeniebox-f661a.firebaseio.com",
  projectId: "mygeniebox-f661a",
  storageBucket: "mygeniebox-f661a.appspot.com",
  messagingSenderId: "293102162029",
  appId: "1:293102162029:web:cbe5dba5d4d5f6a2482f64",
  measurementId: "G-Y1SENQQZ35"
};
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomePageComponent,
    PreloaderComponent,
    ViewAllCategoriesComponent,
    PopularTestComponent,
    ViewAllPackagesComponent,
    PackageDetailComponent,
    LabDetailComponent,
    MycartComponent,
    BillSummaryComponent,
    PaginationComponent,
    CategorywisetestComponent,
    RefreshComponentComponent,
    AboutusComponent,
    ContactusComponent,
    ViewprofileComponent,
    WalletComponent,
    MobilevalidationDirective,
    MyordersComponent,
    MyprescriptionorderComponent,
    MyreportsComponent,
    SearchpackagetestComponent,
    PrivacypolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgOtpInputModule,
    AngularFireModule.initializeApp(config),
    AngularFireAuthModule,
    FormsModule,

    ReactiveFormsModule,

    ToastrModule.forRoot(),
  ],


  providers: [BillSummaryService, CategoryService, CategoryTestService, CustomerService, HomeService, LabDetailService, LabPackageService, LoginService, MyCartService, OrderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
