<app-navbar></app-navbar>
<div class="page-title-area register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;"> My Orders</span>
            </div>
        </div>
       
    </div>
</div>
<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 class="headingcolor">My Orders</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<section class="checkout-area">
    <div class="container">
        <div class="row">
            <div class="col-md-10 leftoffset">
                <div *ngIf="isMyOrder" class="success-order-msg">
                <span style="width:50%;text-align:center;color: #056905;">
                    Your Order Placed Successfully..!!
                </span>
                <i class="flaticon-close" style="float: right;padding: 3px;color: #756f6f;font-size: 10px;"
                    (click)="removeMessage()"></i>
            </div>
            <ng-container *ngIf="userOrderGroup && userOrderGroup.length>0">
                <div *ngFor="let userOrder of userOrderGroup"
                    style="margin-bottom:20px;width:100%;border-radius:20px;box-shadow: 0 0 10px 3px rgb(0,0,0,0.30);" class="cart-totals">
                    <div class="my-order-title" style="position: relative;">
                        <div class="grid-title-container" style="background-color: #dddddd4d;">
                            <div class="grid-item">
                                <span style="color: #8c8080;">Order No </span>
                            </div>
                            <div class="grid-item" style="color: #8c8080;">
                                <span>Payment Mode</span>
                            </div>
                            <div class="grid-item" style="color: #8c8080;text-align: right;">Total</div>
                        </div>
                        <div class="grid-title-container"
                            style="border-bottom: 1px solid #ddd;background-color: #dddddd4d;">
                            <div class="grid-item">
                                <span style="font-size:16px; font-weight:600; text-align: center;">
                                    {{userOrder.Order_Id}}
                                </span>
                            </div>
                            <div class="grid-item">
                                <span style="font-size:16px; font-weight:600; text-align: center;"
                                    *ngIf="userOrder.Payment_Mode == 'COD'">
                                    {{userOrder.Payment_Mode}}
                                </span>
                                <span style="font-size:16px; font-weight:600;"
                                    *ngIf="!(userOrder.Payment_Mode == 'COD')">
                                    Paid
                                </span>
                            </div>
                            <div class="grid-item" style="text-align: right;">
                                <span style="font-size:16px; font-weight:600;">
                                    {{userOrder.TotalAmount | currency:'INR':'symbol'}}
                                    <a style="color:#ef8017;cursor: pointer;" (click)="openPaymentInfo(userOrder)"><i
                                            class="bx bxs-info-circle"></i></a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class=" packagecard card-body border-0"
                        style="box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.30); margin-top: 5px;"
                        *ngFor="let order of userOrder.Orders">
                        <div class="package-detail-title grid-container1" style="position: relative;">
                            <div class=" item1">
                                <h4  class="headingcolor mb-1"style="width: 100%;">{{order.Test_Name}}</h4>
                                <h4  style="width: 100%;color: #e68d1d;margin-bottom: 0;">*{{order.Lab_Name}}</h4>
                            </div>
                            <!-- <div class="item1">
                           
                        </div> -->
                            <div class="item1" style="text-align: right;">{{order.Order_Date_Time | date:'dd-MMM-yyyy'}}
                            </div>


                        </div>
                        <div class="grid-container">
                            <div class="item1">
                                <span>Parameters Includes: </span>
                                <span style="color:#000;font-weight: 600;">
                                    {{order.Parameter_Included}} Test
                                </span>
                            </div>
                            <div class="item1 align-right">
                                <span>Report Time: </span>
                                <span *ngIf="(order.Reporting_Time_In_Hours && order.Reporting_Time_In_Hours!='0')"
                                    style="color:#000;font-weight: 600;">{{order.Reporting_Time_In_Hours}} Hr</span>
                                <span *ngIf="(order.Reporting_Time_In_Hours && order.Reporting_Time_In_Hours=='0')"
                                    style="color:#000;font-weight: 600;">
                                    Not Applicable</span>
                                &nbsp;
                                <span>Fasting Time: </span>
                                <span *ngIf="order.Fasting_Time_In_Hours>0"
                                    style="color:#000;font-weight: 600;">{{order.Fasting_Time_In_Hours}} Hr</span>
                                <span *ngIf="order.Fasting_Time_In_Hours==0" style="color:#000;font-weight: 600;">Not
                                    Applicable</span>
                            </div>

                            <div class="item1">
                                <span>Name : </span><span
                                    style="color:#000;font-weight: 600;">{{order.Member_Name}}</span>
                                &nbsp;&nbsp;
                                <span style="color:#000;font-weight: 600;">{{order.Member_Gender}}</span>&nbsp;&nbsp;
                                <span style="color:#000;font-weight: 600;">{{order.Member_Age}} Years</span>
                            </div>
                            <div class="item1 align-right">
                                <span>Collection Date & Time:
                                </span>
                                <span style="color:#000;font-weight: 600;">{{order.Sample_Collection_Date |
                                    date:'dd-MMM-yyyy'}} {{order.Time_Slot_Name}}</span>
                            </div>

                            <div class="item1">
                                 <h4 style="font-size:18px">₹{{order.Selling_Amt}}
                                    <del *ngIf="order.Disc_Per!=0" style="color: #aaa;font-size: 16px;">
                                        ₹{{order.Test_MRP}}
                                    </del>
                                    <span *ngIf="order.Disc_Per>0;" style="padding: 10px;color: #ef8017;">
                                        {{order.Disc_Per | number:'0.0-0'}}% OFF
                                    </span>
                                </h4>
                            </div>
                            <!-- <div class="item1"></div> -->
                            <div class="item1 mt-1" style="text-align: right;font-weight: 600;">
                                <span style="color: #ffffff; padding:7px"
                                    [style.background-color]="order.OrderStatusColorCode">{{order.Test_Status}}</span>
                            </div>

                            <div class="item1" *ngIf="order.Phlebo_Name">
                                <span>Phlebotomist {{order.Phlebo_Name}} (
                                    <span *ngIf="order.Phlebo_Contact_No">{{order.Phlebo_Contact_No}}</span>
                                    )</span>
                            </div>
                            <div class="item1" *ngIf="order.Test_Status=='Complete'">
                                <span>
                                    {{order.Complete_Date_Time | date:'dd-MMM-yyyy'}}
                                </span>
                            </div>
                            <div class="item1"></div>
                            <div class="item1 align-right" style="font-weight: 600;">
                                <a (click)="openCancelOrderDialog(order)" *ngIf="order.Test_Status=='Pending'"
                                    style="cursor: pointer;color:#ff2600">Cancel <i
                                        class="flaticon-right-arrow"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="!(userOrderGroup && userOrderGroup.length>0)" class="card-body border-0"
                style="height:395px;box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.30); margin-top: 5px;display: flex;width: 100%;align-items: center;justify-content: center;">
                <h5>You will see your orders here.</h5>
            </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>

<ng-container *ngIf="isCancelPrescriptionOrderDialog">
    <div
        style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
        <div style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
            role="dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Cancel Test</h5>
                    <p class="text-right"><i class="flaticon-close" style="cursor: pointer;"
                            (click)="closeCancelOrderDialog()"></i></p>
                </div>
                <div class="modal-body modal13" style="max-height: calc(100vh - 240px);overflow: auto;">
                    <div class="container-fluid p-0">
                        <div class="row">

                            <div class="col-12">
                                <div class="modal-bg checkout-page">
                                    <div class="age-content checkout-form">

                                        <form id="CancelForm" #CancelForm="ngForm" class="theme-form">
                                            <div class="form-row">
                                                <!-- <div class="col-md-12">
                                                    <input type="text" class="form-control" id="review" name="review"
                                                        placeholder="Reason for {{title}}"
                                                        [(ngModel)]="productCancel.Order_Cancelation_Reason"
                                                        #ReviewTitle="ngModel" required>
                                                    <div class="text text-danger"
                                                        *ngIf="ReviewTitle.invalid && (ReviewTitle.dirty || ReviewTitle.touched)">
                                                        Reason is required!
                                                    </div>
                                                </div> -->
                                                <div class="col-md-12">
                                                    <textarea class="form-control" name="desc" style="height: 130px"
                                                        placeholder="Cancellation Reason"
                                                        id="exampleFormControlTextarea1" rows="6"
                                                        [(ngModel)]="reasonDesc" #ReviewTitle="ngModel" required>
                                                </textarea>
                                                    <div class="text text-danger"
                                                        *ngIf="ReviewTitle.invalid && (ReviewTitle.dirty || ReviewTitle.touched)">
                                                        Reason is required!!
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="default-btn viewbtn" type="submit" (click)="cancelOrder(CancelForm)">Continue</button>
                    <button type="submit" (click)="closeCancelOrderDialog()" class="default-btn viewbtn">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isPaymentInfoDialog">
    <div
        style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
        <div style="position: fixed;top: 10%;left: 35%;width: 31%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
            role="dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Payment Info</h5>
                    <p class="text-right"><i class="flaticon-close" style="cursor: pointer;"
                            (click)="closePaymentInfoDialog()"></i></p>
                </div>
                <div class="modal-body modal13" style="max-height: calc(100vh - 240px);overflow: auto;">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="modal-bg checkout-page">
                                    <div class="age-content checkout-form">
                                        <ul>
                                            <li style="padding-bottom: 10px;margin-bottom: 10px;">
                                                Value of Tests
                                                <span style="float: right;">{{valueOfTest |
                                                    currency:'INR':'symbol'}}</span>
                                            </li>
                                            <li
                                                style="padding-bottom: 10px;margin-bottom: 10px;border-bottom: 1px solid #eaecf1;">
                                                Discount(-)
                                                <span style="color:#e68d1d;float: right;">{{discount |
                                                    currency:'INR':'symbol'}}</span>
                                            </li>
                                            <li
                                                style="padding-bottom: 10px;margin-bottom: 10px;border-bottom: 1px solid #eaecf1;">
                                                Sub Total
                                                <span style="float: right;">{{subTotal |
                                                    currency:'INR':'symbol'}}</span>
                                            </li>
                                            <li style="padding-bottom: 10px;margin-bottom: 10px;border-bottom: 1px solid #eaecf1;"
                                                *ngIf="walletCoupon>0">
                                                Wallet/Coupon(-)
                                                <span style="color:#5ccc11;float: right;">{{walletCoupon |
                                                    currency:'INR':'symbol'}}</span>
                                            </li>
                                            <li
                                                style="padding-bottom: 10px;margin-bottom: 10px;font-size: 18px;font-weight: 600;border-bottom: 1px solid #eaecf1;">
                                                Final Amount
                                                <span style="float: right;">{{finalTotal |
                                                    currency:'INR':'symbol'}}</span>
                                            </li>
                                            <li style="padding-bottom: 10px;margin-bottom: 10px;">
                                                Payment Mode
                                                <span style="float: right;">{{payMode}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>