<app-navbar></app-navbar>
<div class="page-title-area  register-pag">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;"> My Cart</span>
            </div>
        </div>
       
    </div>
</div>
<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 class="headingcolor">My Cart</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<div class="container">
    <div class="row ml-0 mr-0 checkout-area">
        <div class=" cart-totals col-lg-3 col-sm-12" style="box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.20);border-radius: 10px;">
            <h3 class="d-inline">Member</h3>
           
            <div class="packagecard card-body border-0"
                style="box-shadow:0 0 10px 3px rgba(0, 0, 0, 0.30);margin-bottom: 0;margin-top: 40px;">
                <h5 class="d-inline headingcolor">{{memberName | titlecase}}</h5>
                <h6>{{memberGender | titlecase}} | {{memberAge}} years</h6>
            </div>
            <button (click)="ismemeberlist = true;isaddmemberform=false;openAddMemberDialog()" style="cursor: pointer;"
            class="default-btn m-4 align-center viewbtn"> <i class="bx bx-edit"></i>Change</button>
        </div>
        <div class="col-lg-9 col-sm-12 checkout-area">
            <div class="cart-totals mb-5" style="box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.20);border-radius: 10px;">
                <div class="rounded">
                    <h3 class="d-inline">Profile/Tests</h3>
                </div>
                <div class="inner-border card-body">
                    <div class="row mt-3">
                        <ng-container *ngIf="(myCart.length>0)">
                            <div class="col-lg-4" *ngFor="let cart of myCart">
                                <div class="packagecard card border-0 rightMargin"
                                    style="box-shadow:0 0 10px 3px rgba(0, 0, 0, 0.30); padding-top:5px;">
                                    <div class="card-body" style="height: 330px;padding: 15px;">

                                        <h6 class=" mb-2" style="height: 30px;">{{cart.Test_Name}}</h6>
                                        <div style="height: 225px;">
                                            <div style="height:130px">
                                                <p class="mb-0 pt-2"> Includes:<b>{{cart.Parameter_Included}}
                                                        Parameter</b></p>
                                                <ul class="highlight-test">
                                                    <li *ngIf="cart.Highlighted_Test_1_Web">
                                                       {{cart.Highlighted_Test_1_Web}}
                                                    </li>
                                                    <li *ngIf="cart.Highlighted_Test_2_Web">
                                                        {{cart.Highlighted_Test_2_Web}}
                                                    </li>
                                                    <li *ngIf="cart.Highlighted_Test_3_Web">
                                                        {{cart.Highlighted_Test_3_Web}}
                                                    </li>
                                                </ul>
                                            </div>

                                            <p class="font-weight-bolder mb-0"
                                                style="text-align: center;font-size: 18px;border-bottom: 1px solid #919292;margin-top: 10px;color: #079ca3;padding-bottom: 10px;">
                                                {{cart.Lab_Name}}
                                            </p>
                                            
                                            <div class="mt-2"
                                                style="border-bottom: 1px solid #919292;padding-bottom: 4px;">
                                                <h4 style="font-size:20px;display:inline">₹{{cart.Selling_Amt}}
                                                    <del *ngIf="cart.Disc_Per!=0" style="color: #aaa;font-size: 16px;">
                                                        ₹{{cart.Test_MRP}}
                                                    </del>
                                                </h4>
                                                <span *ngIf="cart.Disc_Per>0;"
                                                    style="color: #ef8017;border-radius: 5px 0 0 5px;float: right;">
                                                    {{cart.Disc_Per | number:'0.0-0'}}% OFF
                                                </span>
                                            </div>
                                        </div>
                                        <ul style="padding-top: 10px;">
                                            
                                            <li class="read">
                                                <a style="cursor: pointer;color:#ef8017" title="Remove"
                                                    (click)="deletecartdialog(cart)">
                                                    <i style="font-size:20px;" class="bx bx-trash"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="!(myCart.length>0)"
                            style="height: 395px;display: flex;width: 100%;align-items: center;justify-content: center;"
                            class="ng-star-inserted">
                            <h5>No package available in your cart</h5>
                        </div>
                    </div>
                    <div class="mt-3 text-center">
                        <div class="banner-btn wow fadeInUp text-center" data-wow-delay="1s">
                            <button (click)="navigateToSummary()" style="cursor: pointer;" class="default-btn viewbtn"
                                *ngIf="(myCart.length>0)">Proceed</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
     </div>


    <div class=" mt-3 row">
       
    </div>

</div>
<ng-container *ngIf="isDelete">
    <div
        style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
        <div style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;padding: 20px;"
            role="dialog">
            <div class="modal-content resmodal">
                <div class="modal-body modal13" style="max-height: calc(100vh - 240px);overflow: auto;overflow-x: hidden;">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="modal-bg checkout-page">
                                    <div class="container" style="padding: 16px;text-align: center;">
                                       <div class="row">
                                           <div class="col-md-6">

                                                <h1 class="headingcolor">{{mainTitle}}</h1>
                                                <p>{{deleteMsg}}</p>

                                                <div class="mt-3 clearfix">
                                                    <button type="button" class="default-btn viewbtn" style="
                                                    background-color: darkgray;color: #fff;
                                                    border-color: lightgray;
                                                    margin-right: 10px;" (click)="closedelcart();">No</button>
                                                    <button type="button" *ngIf="isremoveAll == false"
                                                        (click)="deletecart(delcart.My_Cart_Id)"
                                                        class="default-btn viewbtn">Yes</button>
                                                    <button type="button" *ngIf="isremoveAll == true" (click)="removeAll();"
                                                        class="default-btn viewbtn">Yes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>
<ng-container *ngIf="isOpenMemberDialog">
    <div
        style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
        <div class="modal-media" style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;padding: 20px;"
            role="dialog">
            <div class="inner-border modal-content m-0" style="overflow:auto">
                <div class="modal-header">
                    <h5 class="modal-title headingcolor" id="exampleModalLabel">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="closeAddMemberDialog()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="max-height: calc(100vh - 240px);overflow: auto;overflow-x: hidden;">
                    <div class=" mt-3 row">
                        <div class="col-lg-12">
                            <div>
                                <div *ngIf="ismemeberlist">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="mt-2 card member-border" style="border:none"
                                                *ngFor="let familymem of familymember">
                                                <div class=" p-2  card-body">
                                                    <h5 class="d-inline headingcolor" >
                                                        {{familymem.Member_Name |  titlecase }}</h5>
                                                        &nbsp;
                                                    <button (click)="deleteDialog(familymem)" class="default-btn viewbtn deletebtn mr-2"
                                                        style="width:40px;cursor: pointer;float: right;padding: 9px 10px;">
                                                        <i class="bx bx-trash">
                                                        </i>
                                                    </button>


                                                    <button
                                                        (click)="isaddmemberform = !isaddmemberform;ismemeberlist = !ismemeberlist;bindEditData(familymem)"
                                                        class="default-btn viewbtn mr-2"
                                                        style="width:40px;cursor: pointer;float: right;padding: 9px 10px;">
                                                        <i class="bx bx-edit">
                                                        </i>
                                                    </button>

                                                    <button (click)="selectMemeberForTest(familymem)"
                                                        class="default-btn viewbtn mr-2" style="cursor:pointer;float:right;">
                                                        Select
                                                    </button>
                                                    <h6>{{familymem.Member_Gender}} |
                                                        {{familymem.Member_Age}}
                                                        years</h6>
                                                </div>

                                            </div>
                                            <div class="text-center mt-5">
                                                <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
                                                    <button type="submit" (click)="addNewMember()" class="default-btn viewbtn" style="width:200px;">
                                                        Add New Member
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isaddmemberform" class="container">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                        <form class="theme-form" #AddMemberForm="ngForm" style="padding: 10px;">
                            <label for="Name">Name</label>
                            <div class=" form-group">
                                <input type="text" class=" form-control" name="name" #name="ngModel" required
                                    pattern="[a-zA-Z ]*" placeholder="Enter Name"
                                    [(ngModel)]="selectedMember.Member_Name">
                                <span class="text-danger"
                                    *ngIf="(name.touched || AddMemberForm.submitted) && name.errors?.required">
                                    Name is required!!
                                </span>
                                <span class="text-danger"
                                    *ngIf="(name.touched || AddMemberForm.submitted) && name.errors?.pattern">
                                    Name only allow a-z A-Z ,-,_
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Gender</label>
                                <div class="form-control">
                                    <label class="radio-inline mr-3" style="margin-bottom: 0px;">
                                        <input type="radio" name="gender" value="male"
                                            [(ngModel)]="selectedMember.Member_Gender" required #gender="ngModel">
                                        Male
                                    </label>
                                    <label class="radio-inline" style="margin-bottom: 0px;">
                                        <input type="radio" name="gender" value="female"
                                            [(ngModel)]="selectedMember.Member_Gender" required>
                                        Female
                                    </label>
                                    <span class="text-danger"
                                        *ngIf="(gender.touched || AddMemberForm.submitted) && gender.errors?.required">
                                        Gender is required!!
                                    </span>
                                </div>
                            </div>
                            <label for="Age">Age</label>
                            <div class="form-group">
                                <input type="text" class="form-control" [(ngModel)]="selectedMember.Member_Age" id="Age"
                                    name="age" placeholder="Enter Age" required #Age="ngModel">
                                <span class="text text-danger" *ngIf="Age.invalid && (Age.dirty || Age.touched)">
                                    Age is required!!
                                </span>
                            </div>
                            <label for="Relation">Relation</label>
                            <div class="form-group">
                                <input type="text" class="form-control" required #Relation="ngModel"
                                    [(ngModel)]="selectedMember.Member_Relation" name="relation"
                                    placeholder="Enter Relation" />
                                <span class="text text-danger"
                                    *ngIf="Relation.invalid && (Relation.dirty || Relation.touched)">
                                    Relation is required!!
                                </span>
                            </div>
                            <div class="text-center mt-5">
                                <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
                                    <button type="submit" [disabled]="!(AddMemberForm.valid)"
                                        (click)="addMember(AddMemberForm)" class="default-btn viewbtn">
                                        Save
                                    </button>
                                    <button type="submit"
                                        (click)="isaddmemberform = false;ismemeberlist=true;title='Select Member'"
                                        class="default-btn viewbtn deletebtn ml-1">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isDeleteMemeber">
    <div
        style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
        <div style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
            role="dialog">
            <div class="modal-content resmodal">
                <div class="modal-body modal13" style="max-height: calc(100vh - 240px);overflow: auto;overflow-x: hidden;">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="modal-bg checkout-page">
                                    <div class="container" style="padding: 16px;text-align: center;">
                                        <div class="row">
                                            <div class="col-md-6">
                                        <h2 class="headingcolor">{{mainTitle}}</h2>
                                        <p>{{deleteMsg}}</p>

                                        <div class="mt-3 clearfix">
                                            <button type="button" class="default-btn viewbtn" style="
                                            background-color: darkgray;
                                            color: #fff;
                                            border-color: #079ca3;
                                            margin-right: 10px;" (click)="closeDeleteMemeberDialog()">No</button>
                                            <button type="button"
                                                (click)="deletemember(deletefamilymem.Cust_Family_Member_Id)"
                                                class="default-btn viewbtn">Yes</button>
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>
<app-footer></app-footer>