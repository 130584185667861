<!-- <div class="product-pagination" *ngIf="labPackages && labPackages.length">
    <div class="theme-paggination-block">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <nav aria-label="Page navigation">
                        <ul class="pagination" *ngIf="paginate.pages && paginate.pages.length">
                            <li class="page-item" [ngClass]="{disabled:paginate.currentPage === 1}">
                                <a href="javascript:void(0)" class="page-link" (click)="pageSet(paginate.currentPage - 1)" aria-label="Previous">
                                    <span aria-hidden="true"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                            </li>
                            <li class="page-item active" *ngFor="let page of paginate.pages" [ngClass]="{ active: paginate.currentPage == page }">
                                <a href="javascript:void(0)" class="page-link" (click)="pageSet(page)">{{ page }}</a>
                            </li>
                            <li class="page-item" [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }">
                                <a href="javascript:void(0)" class="page-link" (click)="pageSet(paginate.currentPage + 1)" aria-label="Next">
                                    <span aria-hidden="true"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>                
            </div>
        </div>
    </div>
</div> -->


<div class="col-lg-12 col-md-12" *ngIf="labPackages && labPackages.length">
    <div class="pagination-area mt-5">
        <div class="d-inline page-item" [ngClass]="{ disabled: paginate.currentPage == 1 }">
            <a class="page-numbers" (click)="pageSet(paginate.currentPage - 1)"><i class="bx bx-chevron-left"></i></a>
        </div>
        <span class="page-numbers active" *ngFor="let page of paginate.pages"
            [ngClass]="{ active: paginate.currentPage == page }" >
            <a [ngClass]="{activePage:paginate.currentPage == page}" href="javascript:void(0)" class="" (click)="pageSet(page)">{{ page }}</a>
        </span>
        <div class="d-inline page-item" [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }">
            <a href="javascript:void(0)" class="page-numbers" aria-hidden="true"
                (click)="pageSet(paginate.currentPage + 1)" aria-label="Next"><i class="bx bx-chevron-right"></i></a>
        </div>
    </div>
</div>