import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../src/environments/environment";
import { CertificateResponse } from '../entities/labdetail/certificateresponse';
import { PackageResponse } from '../entities/home/packageresponse';
import { LabHomeResponse } from '../entities/home/labhomeresponse';

@Injectable({
  providedIn: 'root'
})
export class LabDetailService {

  constructor(private http: HttpClient) { }

  async getLabCertificate(LabId: number): Promise<CertificateResponse> {
    let parameter = JSON.parse(JSON.stringify({ "Lab_Id": LabId }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_lab_certificate", parameter)
      .toPromise() as CertificateResponse;
  }
  async getLabWiseTest(CityId: number, LabId: number, start: number, end: number): Promise<PackageResponse> {
    let parameter = JSON.parse(JSON.stringify({ "City_Id": CityId, "Lab_Id": LabId, "Start": start, "End": end }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_lab_wise_test", parameter)
        .toPromise() as PackageResponse;
  }
  async getLabsForHome(cityId: number): Promise<LabHomeResponse> {
    let parameter = JSON.parse(JSON.stringify({ "City_Id": cityId }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_labs_for_home_page", parameter)
      .toPromise() as LabHomeResponse;
  }
 
}