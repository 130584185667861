import { Component, OnInit } from '@angular/core';
import { LabDetailService } from '../../service/labdetail.service';
import { CertificateResponse } from '../../entities/labdetail/certificateresponse';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LabCertificate } from '../../entities/labdetail/labcertificate';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageResponse } from 'src/app/entities/home/packageresponse';
import { Package } from '../../entities/home/package';
import { LabHome } from 'src/app/entities/home/labhome';
import { LabHomeResponse } from 'src/app/entities/home/labhomeresponse';
import { LoginCredential } from 'src/app/entities/login/logincredential';

@Component({
  selector: 'app-lab-detail',
  templateUrl: './lab-detail.component.html',
  styleUrls: ['./lab-detail.component.scss']
})
export class LabDetailComponent implements OnInit {

  public start = 0;
  public end = 20;
  public labName: string;
  private cred: LoginCredential;
  public labCertificate: LabCertificate[];

  public labWiseTest: Package[];
  LabHome: LabHome[];
  aboutLabInfo: string;
  labLogo: string;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private labDetailService: LabDetailService,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
  ) { }


  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    let sub = this.route.params.subscribe(async (params) => {
      let labId = +params['labId'];
      let cityId = +params['cityId'];

      // (+) converts string 'id' to a number  
      await this.getLabInfo(cityId, labId);
      await this.getLabCertificate(labId);
      await this.getLabWiseTest(cityId, labId);
      //this.spinnerService.hide();

    });

  }
  private async getLabWiseTest(cityId: number, labId: number) {
    try {
      this.spinnerService.show();
      let res: PackageResponse = await this.labDetailService.getLabWiseTest(cityId, labId, this.start, this.end)
      if (res && res.res_code == 1) {

        if (res.data && res.data.length > 0) {
          this.labWiseTest = res.data;
          this.labName = res.data[0].Lab_Name;

        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }
  private async getLabCertificate(labId: number) {
    try {
      this.spinnerService.show();
      let res: CertificateResponse = await this.labDetailService.getLabCertificate(labId);
      if (res && res.res_code == 1) {

        if (res.data && res.data.length > 0) {
          this.labCertificate = res.data;

        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  private async getLabInfo(cityId: number, LabId: number) {
    try {
      this.spinnerService.show();
      let res: LabHomeResponse = await this.labDetailService.getLabsForHome(cityId);
      if (res && res.res_code == 1) {
        let index = res.data.findIndex(c => c.Lab_Id == LabId);
        if (index >= 0) {
          this.labLogo = res.data[index].Lab_Logo;
          this.labName = res.data[index].Lab_Name;
          this.aboutLabInfo = res.data[index].About_Lab_Info;
        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }



  public redirectTestDetail(labId: number, testId: number) {
    this.router.navigate(['packagedetail', labId, testId]);
  }


}