import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../service/category.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TestCategory } from 'src/app/entities/home/testcategory';
import { TestCategoryResponse } from 'src/app/entities/home/testcategoryresponse';
import { LoginCredential } from 'src/app/entities/login/logincredential';


@Component({
  selector: 'app-view-all-categories',
  templateUrl: './view-all-categories.component.html',
  styleUrls: ['./view-all-categories.component.scss']
})
export class ViewAllCategoriesComponent implements OnInit {

  public allCategory: TestCategory[];
  private cred: LoginCredential;
  public cityBeforeLogin: { City_Id: number, City_Name: string } = { City_Id: 1, City_Name: "Nashik" };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private CategoryService: CategoryService,

  ) { }

  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    let data = JSON.parse(sessionStorage.getItem("CityBeforeLogin")) as any;
    if (data)
      this.cityBeforeLogin = JSON.parse(sessionStorage.getItem("CityBeforeLogin")) as any;
    await this.getCategory();
    this.spinnerService.hide();
  }


  private async getCategory() {
    try {
      this.spinnerService.show();
      let res: TestCategoryResponse = await this.CategoryService.getAllTestCategories();
      if (res && res.res_code == 1) {
        this.allCategory = res.data;
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public redirectCategoryWiseTest(testCategoryName: string, testCategoryId: number) {
    let cityId = 1;
    if (this.cred && this.cred.City_Id)
      cityId = this.cred.City_Id;
    else if (this.cityBeforeLogin.City_Id)
      cityId = this.cityBeforeLogin.City_Id;
    this.router.navigate(['categorywisetest', testCategoryName, testCategoryId, cityId]);
  }

}
