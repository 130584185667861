<app-navbar></app-navbar>
<div class="page-title-area register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;"> Wallet</span>
            </div>
        </div>
        
    </div>
</div>
<!--Breadcrumb-->
<section class="contact-page ">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 class="headingcolor">Wallet Detail</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<section class="wallet mb-3">
    <div class="container">
        <div class="row ml-0 mr-0 checkout-area" style="padding: 0 15px;">
        <div class="col-lg-9 col-sm-12 checkout-area">
            <div class="mt-3 p-0 cart-totals">
                <div class=" mainHeight">
                    <table class=" table">
                        <thead class="text-muted" style="background-color: #079ca3;color: #fff !important;">
                            <th colspan="2">Balance</th>
                            <th style="text-align: end;">{{availWalletAmt | currency:'INR':'symbol'}}</th>
                        </thead>
                        <thead class="text-muted ">
                            <th>Transaction Detail</th>
                            <th>Transaction Date</th>
                            <th style="text-align: end;">Amount</th>
                        </thead>
                        <tbody style="font-size: 15px;">
                            <tr *ngFor="let wa of wallet">
                                <td>{{wa.Transaction_Detail}}</td>
                                <td>{{wa.Transaction_Date | date:"dd MMM YYYY HH:MM"}}</td>
                                <td *ngIf="wa.Amount>0" style="color:#5ccc11;text-align: end;">{{wa.Amount |
                                    currency:'INR':'symbol' }}
                                </td>
                                <td *ngIf="wa.Amount<0" style="color:#f04646;text-align: end;">{{wa.Amount |
                                    currency:'INR':'symbol' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="mt-3 cart-totals col-lg-3 col-sm-12" style="padding:10px;">
            <h2 class="text-center mt-3">Refer & Earn</h2>
            <h5 class="text-center mt-3" style="color: #7d7d7d;">Your Code to Invite</h5>

            <div class="m-0 card-body p-0 border-0" style="margin-bottom: 0;margin-top: 20px;">
                <div class="p-0 input-group input-group-lg  mt-5">
                    <input type="text" class="form-control" placeholder="{{cred.My_Referral_Code}}"
                        aria-label="Large" aria-describedby="inputGroup-sizing-sm" readonly
                        value="{{cred.My_Referral_Code}}" #refercode>
                    <div class="input-group-append">
                        <button class="input-group-text text-white viewbtn" id="inputGroup-sizing-lg"
                            style="background-color: #079ca3;" (click)="copyclip(refercode)">COPY</button>
                    </div>

                </div>
            </div>
        </div>
        </div>
    </div>

    <!-- <div class="container">
        <div class="row ml-0 mr-0 checkout-area" style="padding: 0 15px;">
            
        </div>
    </div> -->
</section>
<app-footer></app-footer>