import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../src/environments/environment";
import { Prescription } from '../entities/prescription';
import { ResponseWithoutData } from '../entities/responsewithoutdata';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  async deleteAccount(Cust_Id: number): Promise<any> {
    let parameter = JSON.parse(JSON.stringify({ "Cust_Id": Cust_Id }))
    return await this.http.post(environment.apiUrl + "lab_door/api/delete_customer", parameter)
      .toPromise() as any;
  }
  async getmycartcount(Cust_Id: number): Promise<any> {
    let parameter = JSON.parse(JSON.stringify({ "Cust_Id": Cust_Id }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_my_cart_count", parameter)
      .toPromise() as any;
  }

  async getwalletamount(Cust_Id: number): Promise<any> {
    let parameter = JSON.parse(JSON.stringify({ "Cust_Id": Cust_Id }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_customer_wallet_amount", parameter)
      .toPromise() as any;
  }

  async changecity(CustId: number, cityId: number): Promise<ResponseWithoutData> {
    let parameter = JSON.parse(JSON.stringify({ "Cust_Id": CustId, "City_Id": cityId }))
    return await this.http.post(environment.apiUrl + "lab_door/api/change_city", parameter)
      .toPromise() as ResponseWithoutData;
  }
  async uploadPrescription(prescription: Prescription): Promise<ResponseWithoutData> {
    return await this.http.post(environment.apiUrl + "lab_door/api/prescription_order_place", prescription)
      .toPromise() as ResponseWithoutData;
  }

  async callrequest(custId: number): Promise<any> {
    let parameter = JSON.parse(JSON.stringify({ "Cust_Id": custId }))
    return await this.http.post(environment.apiUrl + "lab_door/api/insert_customer_call_request", parameter)
      .toPromise() as any;
  } 

  async getwalletdetail(custId: number): Promise<any> {
    let parameter = JSON.parse(JSON.stringify({ "Cust_Id": custId }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_customer_wallet_detail", parameter)
      .toPromise() as any;
  }

}