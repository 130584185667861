import { Injectable } from '@angular/core';

export interface IcustomeWindow extends Window {
    __custome_global_stuff: string;
}

function getWindow(): any {
    return window;
}

@Injectable({
    providedIn: 'root'
})

export class WindowRefService {

    get nativeWindow(): IcustomeWindow {
        return getWindow();
    }

}