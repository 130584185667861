import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AddAddress } from 'src/app/entities/billsummary/addaddress';
import { addAddressResponse } from 'src/app/entities/billsummary/addaddressresponse';
import { MyCart } from 'src/app/entities/cart/mycart';
import { MycartResponse } from 'src/app/entities/cart/mycartresponse';
import { GetCityResponse } from 'src/app/entities/login/getcityresponse';
import { LoginCredential } from 'src/app/entities/login/logincredential';
import { BillSummaryService } from 'src/app/service/billsummary.service';
import { LoginService } from 'src/app/service/login.service';
import { MyCartService } from 'src/app/service/mycart.service';
import { GetCity } from "../../entities/login/getcity";
import { TimeSlot } from '../../entities/timeslot';
import { IcustomeWindow, WindowRefService } from 'src/app/service/window-ref.service';
import { PlaceOrder } from 'src/app/entities/order/placeorder';
import { ResponseWithoutData } from 'src/app/entities/responsewithoutdata';
import { OrderService } from 'src/app/service/order.service';
import { Router } from '@angular/router';
import { CouponCode } from 'src/app/entities/order/couponcode';
import { HomeService } from 'src/app/service/home.service';
import { CompanyDataResponse } from 'src/app/entities/home/companydataresponse';
import { CompanyData } from 'src/app/entities/home/companydata';
@Component({
  selector: 'app-bill-summary',
  templateUrl: './bill-summary.component.html',
  styleUrls: ['./bill-summary.component.scss']
})
export class BillSummaryComponent implements OnInit {


  public isCollapsed = false;
  public cred: LoginCredential;
  public cities: GetCity[];
  public selectedCity: GetCity;
  public selectedtimeslot: TimeSlot;
  public addaddress: AddAddress = new AddAddress;
  public address: AddAddress[];
  public timeslot: TimeSlot[];
  public deliveryAddresses: AddAddress[] = new Array<AddAddress>();
  public selectedAddress: AddAddress = new AddAddress();
  public isViewForm: boolean;
  public myCart: MyCart[];
  public memberName: string;
  public memberGender: string;
  public memberAge: number;

  public totalMrp: number;
  public totalDiscount: number;
  public subTotal: number;
  public finalTotal: number;
  public walletCouponDiscAmt: number;
  public couponCode: string;
  public couponResponse: CouponCode = new CouponCode();

  public isCouponDisc: boolean;
  public isWalletDisc: boolean;
  public walletAmount: number;

  public minDate: string;
  public scDate: string;
  //public errMsg: string;

  public paymentType: string = "Online";

  public companyData: CompanyData;
  //#region razorpay
  private _window: IcustomeWindow;
  public rzp: any;
  public option: any;
  public paymentTransactionID: string;
  //#endregion razorpay

  constructor(
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private loginService: LoginService,
    private billsummaryService: BillSummaryService,
    private mycartservice: MyCartService,
    private orderService: OrderService,
    private homeService: HomeService,
    private zone: NgZone,
    private winRef: WindowRefService,
    private router: Router) {
    this._window = this.winRef.nativeWindow;
  }

  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    if (this.cred) {
      this.walletAmount = JSON.parse(JSON.stringify(this.cred.Wallet_Amount));
    }
    let member = JSON.parse(sessionStorage.getItem("TestMemberObj")) as any;
    this.memberAge = member.memberAge;
    this.memberGender = member.memberGender;
    this.memberName = member.memberName;
    this.minDate = new Date().getFullYear() + "-" + new Date().getMonth() + 1 + "-" + new Date().getDate();

    await this.getProfileDetail();
    await this.getmycarttest(this.cred.Cust_Id);
    await this.getCity();
    await this.getDeliverAddress();
    await this.getTimeSlot();
    await this.getCompanyData();
  }

  private async getProfileDetail() {
    try {
      this.spinnerService.show();
      let res = await this.loginService.getProfile(this.cred.Cust_Id);
      if (res && res.res_code == 1) {
        let data = res.data[0];
        sessionStorage.setItem("Credential", JSON.stringify(data));
        this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
        if (this.cred) {
          this.walletAmount = JSON.parse(JSON.stringify(this.cred.Wallet_Amount));
        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  private async getmycarttest(custId) {
    try {
      this.spinnerService.show();
      let res: MycartResponse = await this.mycartservice.getmycarttest(custId)
      if (res && res.res_code == 1) {
        if (res.data && res.data.length > 0) {
          this.myCart = res.data;

          this.totalMrp = parseFloat(this.myCart.reduce((a, b) => a + (b.Test_MRP * b.Qty), 0).toFixed(2));
          this.totalDiscount = parseFloat(this.myCart.reduce((a, b) => a + (b.Disc_Amt * b.Qty), 0).toFixed(2));
          this.subTotal = parseFloat(this.myCart.reduce((a, b) => a + (b.Selling_Amt * b.Qty), 0).toFixed(2));
          this.finalTotal = parseFloat(this.myCart.reduce((a, b) => a + (b.Selling_Amt * b.Qty), 0).toFixed(2));
        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  private async getCity() {
    try {
      this.spinnerService.show();
      let res: GetCityResponse = await this.loginService.getCity();
      if (res && res.res_code == 1) {
        if (res.data && res.data.length > 0) {
          this.cities = res.data;
          // console.log(this.getCity.City_Name);
        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  private async getDeliverAddress() {
    try {
      this.spinnerService.show();
      let res = await this.billsummaryService.getaddress(this.cred.Cust_Id)
      if (res && res.res_code == 1) {
        this.deliveryAddresses = res.data;
        if (this.deliveryAddresses && this.deliveryAddresses.length > 0)
          this.selectedAddress = this.deliveryAddresses.find(c => c.Is_Default);
        if (this.deliveryAddresses.length == 0)
          this.isViewForm = true;
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }

  private async getTimeSlot() {
    try {
      let res = await this.billsummaryService.getcollectiontimeslot();
      if (res && res.res_code == 1) {
        this.timeslot = res.data;

      }
    }
    catch (error) {
      this.toastrService.error(error, "Error")
    }
  }

  private async getCompanyData() {
    try {
      let res: CompanyDataResponse = await this.homeService.getcompanydata();
      if (res && res.res_code == 1) {
        this.companyData = res.data[0];
      }
    }
    catch (error) {
      this.toastrService.error(error, "Error")
    }
  }

  public addAddress() {
    this.isViewForm = true;
    this.selectedAddress = new AddAddress();
  }

  public bindEditData(addr: AddAddress) {
    this.selectedAddress = JSON.parse(JSON.stringify(addr));
    this.selectedCity = this.cities.find(c => c.City_Id == this.selectedAddress.City_Id);
    this.isViewForm = true;
  }

  public async saveAddress(form: NgForm) {
    try {
      if (form.valid) {
        this.spinnerService.show();
        this.selectedAddress.Cust_Id = this.cred.Cust_Id;
        this.selectedAddress.City_Id = this.selectedCity.City_Id;
        this.selectedAddress.City = this.selectedCity.City_Name;
        if (!this.selectedAddress.Delivery_Address_Id) {
          let res: addAddressResponse = await this.billsummaryService.addaddress(this.selectedAddress)
          if (res && res.res_code == 1 && res.data) {
            await this.getDeliverAddress();
            //            this.deliveryAddresses.push(res.data);
            this.isViewForm = false;
          }

        }
        else {
          let res: addAddressResponse = await this.billsummaryService.updateaddress(this.selectedAddress);
          if (res && res.res_code == 1 && res.data) {
            let index = this.deliveryAddresses.findIndex(c => c.Delivery_Address_Id == this.selectedAddress.Delivery_Address_Id);
            if (index >= 0) {
              this.deliveryAddresses[index].Address_Type = res.data.Address_Type;
              this.deliveryAddresses[index].Area_Colony_Sreet_Name = res.data.Area_Colony_Sreet_Name;
              this.deliveryAddresses[index].City = res.data.City;
              this.deliveryAddresses[index].Flat_House_No = res.data.Flat_House_No;
              this.deliveryAddresses[index].Full_Name = res.data.Full_Name;
              this.deliveryAddresses[index].Landmark = res.data.Landmark;
              this.deliveryAddresses[index].Mobile_No_Address = res.data.Mobile_No_Address;
              this.deliveryAddresses[index].Pincode = res.data.Pincode;
              this.deliveryAddresses[index].State = res.data.State;
              this.isViewForm = false;
            }

          }
        }
        this.spinnerService.hide();
      }
      else {
        Object.keys(form.controls).forEach(key => {
          form.controls[key].markAsTouched();
        });
      }
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }


  public async defaultAddress(address: AddAddress) {
    try {
      let res = await this.billsummaryService.setdefaultaddress(this.cred.Cust_Id, address.Delivery_Address_Id);
      if (res && res.res_code == 1) {
        let index = this.deliveryAddresses.findIndex(c => c.Delivery_Address_Id == address.Delivery_Address_Id);
        if (index >= 0) {
          this.deliveryAddresses.map(c => c.Is_Default = false);
          this.deliveryAddresses[index].Is_Default = true;
          this.selectedAddress = JSON.parse(JSON.stringify(this.deliveryAddresses[index]));
        }
      }
    }
    catch (error) {
      this.toastrService.error(error, "Error");
    }
  }

  public setAddress(address: AddAddress) {
    let index = this.deliveryAddresses.findIndex(c => c.Delivery_Address_Id == address.Delivery_Address_Id);
    if (index >= 0)
      this.selectedAddress = JSON.parse(JSON.stringify(this.deliveryAddresses[index]));
  }

  public async checkCouponCode() {
    try {
      //this.errMsg = "";
      if (this.couponCode) {
        this.walletCouponDiscAmt = 0;
        this.finalTotal = this.subTotal
        this.spinnerService.show();
        let res = await this.orderService.checkCouponCode(this.couponCode);
        if (res && res.res_code == 1) {
          this.couponResponse = res.data[0];
          if (this.couponResponse && this.couponResponse.Value_Type.toLowerCase() == "per") {
            if (this.subTotal > this.couponResponse.Minimum_Order_Value) {
              let disc = this.subTotal * this.couponResponse.Value / 100;
              if (disc <= this.subTotal) {
                if (disc < this.couponResponse.Upto_Discount_Amount)
                  this.walletCouponDiscAmt = disc;
                else
                  this.walletCouponDiscAmt = this.couponResponse.Upto_Discount_Amount;
              }
              else {
                this.walletCouponDiscAmt = this.subTotal
              }

              this.finalTotal = this.subTotal - this.walletCouponDiscAmt;
            }
            else {
              this.toastrService.warning("Your amount is not sufficient for apply discount");
              // this.errMsg = "Your amount is not sufficient for apply discount";
            }
          }
          else if (this.couponResponse && this.couponResponse.Value_Type.toLowerCase() == "amt") {
            if (this.subTotal > this.couponResponse.Minimum_Order_Value) {
              let disc = this.couponResponse.Value
              if (disc <= this.subTotal) {
                if (disc < this.couponResponse.Upto_Discount_Amount)
                  this.walletCouponDiscAmt = disc;
                else
                  this.walletCouponDiscAmt = this.couponResponse.Upto_Discount_Amount;
              }
              else {
                this.walletCouponDiscAmt = this.subTotal
              }

              this.finalTotal = this.subTotal - this.walletCouponDiscAmt;
            }
            else {
              this.toastrService.warning("Your amount is not sufficient for apply discount");
              //this.errMsg = "Your amount is not sufficient for apply discount";
            }
          }
          this.walletAmount = JSON.parse(JSON.stringify(this.cred.Wallet_Amount));
        }
        else if (res && res.res_code == 0) {
          this.toastrService.warning(res.msg);
        }
        this.isCouponDisc = true;
        this.walletAmount = this.cred.Wallet_Amount;
        this.isWalletDisc = false;
        this.spinnerService.hide();
      }
      else {
        this.toastrService.warning("Coupon Code required");
      }
    } catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public async removeCouponCode() {
    this.finalTotal = this.subTotal;
    this.walletCouponDiscAmt = 0;
    this.couponCode = null;
    this.isCouponDisc = false;
    //this.isWalletDisc = true;
  }

  public async applyWallet() {
    try {
      if (this.walletAmount > 0) {
        this.walletCouponDiscAmt = 0;
        this.finalTotal = this.subTotal;
        //this.errMsg = "";
        this.spinnerService.show();
        if (this.subTotal > 0) {
          if (this.companyData && this.companyData.Wallet_Usage_Percent > 0) {
            let disc = this.subTotal * this.companyData.Wallet_Usage_Percent / 100;
            if (disc < this.walletAmount) {
              this.walletCouponDiscAmt = disc;
            }
            else {
              this.walletCouponDiscAmt = this.walletAmount;
            }
            this.finalTotal = this.subTotal - this.walletCouponDiscAmt;
            this.walletAmount -= this.walletCouponDiscAmt;
            this.isWalletDisc = true;
          }
          else {
            this.toastrService.warning("Wallet usage percentage is not available");
            //this.errMsg = "Wallet usage percentage is not available";
          }
          this.couponCode = null;
          this.isCouponDisc = false;
        }
        this.spinnerService.hide();
      }
      else {
        this.toastrService.warning("Wallet empty")
      }
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public async removeWallet() {
    this.isWalletDisc = false;
    this.finalTotal = this.subTotal;
    this.walletCouponDiscAmt = 0;
    this.walletAmount = this.cred.Wallet_Amount;

  }

  public cancelAddress() {
    this.isViewForm = false;
  }

  public changePaymentType(payment: string) {
    this.paymentType = payment;
  }

  public async checkPayOrderType() {
    if (this.paymentType == "COD")
      this.placeOrder();
    else if (this.paymentType == "Online")
      this.razorpay();
  }

  private razorpay() {
    this.option = {
      key: 'rzp_live_HHS9Uwf4cXuPv5',//'rzp_test_xac0VPTP8XZjMi',//live key //'rzp_live_OPDqWSC5QzI2j4',
      name: 'Lab Door',
      description: 'Shopping',
      amount: (this.finalTotal * 100),
      prefill: {
        name: 'Lab Door',
        email: this.cred.Email_Id,
        contact: this.cred.Mobile_Number
      },
      notes: {},
      theme: {
        color: '#3880FF'
      },
      handler: ((res: any, err: any) => {
        if (res && res.razorpay_payment_id) {
          this.paymentTransactionID = res.razorpay_payment_id;
          this.placeOrder();
        }
      }),
      modal: {
        ondismiss: (() => {
          this.zone.run(() => {
            //window.location.reload();
            //add current page routing if payment fail
            // this.router.navigateByUrl('/shop/RefreshComponent', { skipLocationChange: true }).then(() => {
            //   this.router.navigate(['/shop/checkout']);
            // });
          })
        })
      }
    };

    this.rzp = new this.winRef.nativeWindow['Razorpay'](this.option);
    this.rzp.open();
  }

  private async placeOrder() {
    try {
      this.spinnerService.show();
      let parameter = new PlaceOrder();
      if (this.selectedAddress && this.selectedAddress.Delivery_Address_Id > 0) {
        parameter.Address_Type = this.selectedAddress.Address_Type;
        parameter.Area_Colony_Sreet_Name = this.selectedAddress.Area_Colony_Sreet_Name;
        parameter.Address_City = this.selectedAddress.City;
        parameter.Address_City_Id = this.selectedAddress.City_Id;
        parameter.Delivery_Address_Id = this.selectedAddress.Delivery_Address_Id;
        parameter.Delivery_LG = 0;
        parameter.Delivery_LT = 0;
        parameter.Flat_House_No = this.selectedAddress.Flat_House_No;
        parameter.Landmark = this.selectedAddress.Landmark;
        parameter.Mobile_No_Address = this.selectedAddress.Mobile_No_Address;
        parameter.Pincode = this.selectedAddress.Pincode;
        parameter.State = this.selectedAddress.State;

      }
      else {
        let address = this.deliveryAddresses.find(c => c.Is_Default = true);
        parameter.Address_Type = address.Address_Type;
        parameter.Area_Colony_Sreet_Name = address.Area_Colony_Sreet_Name;
        parameter.Address_City = address.City;
        parameter.Address_City_Id = address.City_Id;
        parameter.Delivery_Address_Id = address.Delivery_Address_Id;
        parameter.Delivery_LG = 0;
        parameter.Delivery_LT = 0;
        parameter.Flat_House_No = address.Flat_House_No;
        parameter.Landmark = address.Landmark;
        parameter.Mobile_No_Address = address.Mobile_No_Address;
        parameter.Pincode = address.Pincode;
        parameter.State = address.State;
      }
      parameter.App_Type = "Web";
      parameter.City_Id = this.arrayToString("City_Id");
      parameter.Coupon_Code_Amount = this.isCouponDisc ? (this.walletCouponDiscAmt) : (this.isWalletDisc ? this.walletCouponDiscAmt : 0);
      parameter.Coupon_Code_Name = this.isCouponDisc ? (this.couponCode) : (this.isWalletDisc ? "Wallet" : "NO");;
      parameter.Cust_Id = this.cred.Cust_Id;
      parameter.Cust_Name = this.cred.Cust_Name;
      parameter.Disc_Amt = this.arrayToString("Disc_Amt");
      parameter.Disc_Per = this.arrayToString("Disc_Per");
      parameter.Email_Id = this.cred.Email_Id;
      parameter.Full_Name = this.cred.Cust_Name;
      parameter.Lab_City_Id = this.arrayToString("Lab_City_Id");
      parameter.Lab_City_Test_Rate_Id = this.arrayToString("Lab_City_Test_Rate_Id");
      parameter.Lab_Id = this.arrayToString("Lab_Id");
      parameter.Member_Age = this.memberAge;
      parameter.Member_Gender = this.memberGender;
      parameter.Member_Name = this.memberName;
      parameter.Mobile_Number = this.cred.Mobile_Number;
      parameter.Payment_Mode = this.paymentType;
      parameter.Payment_Transaction_ID = this.paymentType == "COD" ? "0" : this.paymentTransactionID;
      parameter.Qty = this.arrayToString("Qty");
      parameter.Sample_Collection_Date = this.scDate;
      parameter.Selling_Amt = this.arrayToString("Selling_Amt");
      parameter.Test_Id = this.arrayToString("Test_Id");
      parameter.Test_MRP = this.arrayToString("Test_MRP");
      parameter.Time_Slot_Id = this.selectedtimeslot.Time_Slot_Id;
      parameter.Time_Slot_Name = this.selectedtimeslot.Time_Slot_Name;

      let res: ResponseWithoutData = await this.orderService.placeOrder(parameter);
      if (res && res.res_code == 1) {
        // // SettingsComponent.cartItem = [];
        // // sessionStorage.setItem("ShoppingCartProduct", JSON.stringify(SettingsComponent.cartItem));
        // // this.toastrService.success("Your Order Accept!");
        sessionStorage.setItem("MyOrderDone", "1");
        this.router.navigate(['/myorders']);
      }

      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
    }
  }

  private arrayToString(parameter: string): string {
    let data: string[] = [];
    this.myCart.forEach(element => {
      if (parameter == "Disc_Amt") {
        let totalmrp = (element.Disc_Amt * element.Qty);
        data.push(totalmrp.toFixed(2));
      }
      else if (parameter == "Test_MRP") {
        let totalmrp = (element.Test_MRP * element.Qty);
        data.push(totalmrp.toFixed(2));
      }
      else if (parameter == "Selling_Amt") {
        let totalmrp = (element.Selling_Amt * element.Qty);
        data.push(totalmrp.toFixed(2));
      }
      else
        data.push(element[parameter]);
    });
    return data.toString();
  }

}
