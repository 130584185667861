import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WalletDetail } from 'src/app/entities/customer/walletdetail';
import { LoginCredential } from 'src/app/entities/login/logincredential';
import { CustomerService } from 'src/app/service/customer.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  public wallet: WalletDetail[];
  public cred: LoginCredential
  public availWalletAmt: number;

  constructor(
    private spinnerService: NgxSpinnerService,
    private toasterServcie: ToastrService,
    private customerService: CustomerService
  ) { }

  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    await this.getWalletDetail();
  }
  private async getWalletDetail() {
    try {
      this.spinnerService.show();
      let res = await this.customerService.getwalletdetail(this.cred.Cust_Id);
      if (res && res.res_code == 1 && res.data) {
        this.wallet = res.data;
        this.availWalletAmt = parseFloat(this.wallet.reduce((a, b) => a + b.Amount, 0).toFixed(2));
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toasterServcie.error(error)
    }
  }
  copyclip(refferalcode) {
    try {
      refferalcode.select();
      document.execCommand('copy');
      refferalcode.setSelectionRange(0, 0);
      this.toasterServcie.success("copied to clipboard")
    }
    catch (error) {
      this.toasterServcie.error(error)
    }

  }
}

