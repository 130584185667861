<app-navbar></app-navbar>
<section class="partner-area3">
    <owl-carousel-o [options]="customSliderConfig" class="home-slider">
        <ng-container *ngFor="let slider of sliders">
            <ng-template carouselSlide>
                <div class="home" [ngStyle]="{'background-image': 'url('+slider.Slider_Image+')'}">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="slider-contain">
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <div *ngIf="!(sliders && sliders.length!=0)">
            <h3 class="text-center"
                style="height: 703px;padding: 15px;display: flex;align-items: center;justify-content: center;">
            </h3>
        </div>
    </owl-carousel-o>
</section>

<section class="partner-area3">
    <div class="container">
        <div class="row ml-0 mr-0 checkout-area">
            <div class="leftoffset col-lg-10 mt-5 cart-totals">
                <!-- <button style="cursor: pointer;"
                    class="default-btn  text-light float-right">Copy</button> -->

                <div class="card-body p-0 border-0" style="margin-bottom: 0; margin-top: 0px;">
                    <div class="p-0 input-group input-group-lg">
                        <input type="text" style="font-size: 1rem;" class="form-control" placeholder="Search Test (CBC, DS etc...)"
                            aria-label="Large" aria-describedby="inputGroup-sizing-sm" [(ngModel)]="searchTest">
                        <div class="input-group-append">
                            <button class="input-group-text text-white" id="inputGroup-sizing-lg"
                                style="background-color: #ef8017;font-size: 1rem;    border: 1px solid #ef8017;" (click)="searchByTestName()">Search</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-area2 pb-50">
    <div class="container">
        <div class="row pt-4 mt-3">
            <div class="col-lg-12 text-center">
                <div class="section-title text-center" style="margin-bottom: 10px;">
                    <h4 style="color:#079ca3;font-size:30px">Recommended Packages</h4>
                </div>
                <div class="shortborder2"></div>
               
            </div>
        </div>
        <div *ngIf="(recommendPackages && recommendPackages.length!=0)">
            <div class="partner-bg" style="padding-top: 0px;">
                <owl-carousel-o [options]="packageSliderConfig">
                    <ng-template carouselSlide style="padding-left: 5px;"
                        *ngFor="let recomPackage of recommendPackages">
                        <div class="packagecard card border-0 rightMargin"
                            style="box-shadow: rgb(0 0 0 / 30%) 0px 0px 10px 3px !important; padding-top:5px;margin: 20px !important;">
                            <div class="card-body" style="height: 330px;padding: 15px;">
                                <h6 class=" mb-2" style="height: 30px;">{{recomPackage.Test_Name}}</h6>
                                <div style="height: 225px;">
                                    <div style="height:130px">
                                        <p class="mb-0 pt-2"> Includes:<b>{{recomPackage.Parameter_Included}}
                                                Parameter</b></p>
                                        <ul class="highlight-test pt-2">
                                            <li *ngIf="recomPackage.Highlighted_Test_1_Web">
                                                <!-- <i class="bx bxs-pin d-inline" style="color:#ef8017"></i> -->
                                                {{recomPackage.Highlighted_Test_1_Web}}
                                            </li>
                                            <li *ngIf="recomPackage.Highlighted_Test_2_Web">
                                                <!-- <i class="bx bxs-pin d-inline" style="color:#ef8017"></i> -->
                                                {{recomPackage.Highlighted_Test_2_Web}}
                                            </li>
                                            <li *ngIf="recomPackage.Highlighted_Test_3_Web">
                                                <!-- <i class="bx bxs-pin d-inline" style="color:#ef8017"></i> -->
                                                {{recomPackage.Highlighted_Test_3_Web}}
                                            </li>
                                        </ul>
                                    </div>
                                    <p class="font-weight-bolder mb-0"
                                        style="text-align: center;font-size: 18px;border-bottom: 1px solid #919292;margin-top: 10px;line-height: 1.2;color: #079ca3;padding-bottom: 10px;">
                                        {{recomPackage.Lab_Name}}
                                    </p>
                                    <div class="mt-2" style="border-bottom: 1px solid #919292;padding-bottom: 4px;">
                                        <h4 style="font-size:20px;display: inline;">₹{{recomPackage.Selling_Amt}}
                                            <del *ngIf="recomPackage.Disc_Per!=0" style="color: #aaa;font-size: 16px;">
                                                ₹{{recomPackage.Test_MRP}}
                                            </del>
                                        </h4>
                                        <span *ngIf="recomPackage.Disc_Per>0;"
                                            style="color: #ef8017;border-radius: 5px 0 0 5px;float: right;">
                                            {{recomPackage.Disc_Per | number:'0.0-0'}}% OFF
                                        </span>
                                    </div>
                                </div>
                                <ul style="padding-top: 10px;">
                                    <!-- <li style="font-size: 18px;font-weight: 500;">
                                        
                                    </li> -->
                                    <li class="read">
                                        <a style="cursor: pointer;color:#ef8017"
                                            (click)="redirectPackageDetail(recomPackage.Lab_Id,recomPackage.Test_Id)">
                                            Know More <i class="flaticon-right-arrow"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
        <div class="row  mt-1">
            <div class="col-lg-8 col-md-6">
            </div>
            <div class=" col-lg-4 text-align-center">
                <div class="float-right banner-btn  wow fadeInUp" style="margin-right:14%;" data-wow-delay="1s">
                    <a (click)="redirectAllPackages()" class="default-btn viewbtn" href="javascript:void(0)">View All
                    </a>
                </div>
            </div>
        </div>
        <div class="text-center mt-2" *ngIf="!(recommendPackages && recommendPackages.length!=0)">
            <h3 class="text-center"
                style="height: 375px;padding: 15px;display: flex;align-items: center;justify-content: center;">
                Package not available
            </h3>
        </div>
    </div>
    <!--Backgroung Image-->
    <!-- <div class="partner-shape"><img src="assets/img/partner-shape.png" alt="Image"></div> -->
</div>

<div class="partner-area4 mb-2">
    <div class="container">
        <div class="row pt-4 mt-3">
            <div class="col-lg-12 ">
                <div class="section-title text-center" style="margin-bottom: 10px;">
                    <h2 style="color:#079ca3;font-size:30px">Popular Categories</h2>
                </div>  
                <div class="shortborder2"></div>
            </div>
        </div>
        <div *ngIf="testCategories && testCategories.length!=0;">
            <div class="partner-bg" style="padding: 0px 20px 0px 20px;">
                <owl-carousel-o [options]="packageSliderConfig">
                    <ng-template carouselSlide *ngFor="let testCat of testCategories">
                        
                        <div class="single-services"
                            (click)="redirectCategoryWiseTest(testCat.Category_Name,testCat.Test_Category_Id)"
                            style="box-shadow: rgb(0 0 0 / 30%) 0px 0px 10px 3px !important;background-color: #fff;cursor: pointer;margin: 20px !important;
                            padding: 10px;">
                            <div class="services-icon">
                                <img src="{{testCat.Category_Image}}" alt="Image" style="height:100px;width:100px">
                            </div>
                            <h3 style="height:70px;line-height: 1;"><a style="color:#ef8017;font-size:24px">{{testCat.Category_Name}}</a></h3>
                        </div>
                    </ng-template>

                </owl-carousel-o>
                <div class="text-center my-1">
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-lg-8 col-md-6"> 
            </div>
            <div class="col-lg-4">
                <div class=" float-right banner-btn wow fadeInUp" style="margin-right:14%;" data-wow-delay="1s">
                    <a (click)="redirectAllCategories()" class="default-btn viewbtn" href="javascript:void(0)">View All
                    </a>
                </div>
            </div>
        </div>
        <div *ngIf="!(testCategories && testCategories.length!=0)">
            <h3 class="text-center"
                style="height: 270px;padding: 15px;display: flex;align-items: center;justify-content: center;">
                Category not available
            </h3>
        </div>
    </div>

    <!--Backgroung Image-->
    <!-- <div class="partner-shape"><img src="assets/img/partner-shape.png" alt="Image"></div> -->
</div>

<div class="partner-area">
    <div class="container">
        <div class="row pt-4 mt-3">
            <div class="col-lg-12 col-md-6">
                <div class="section-title text-center" style="margin-bottom: 10px;">
                    <h2 style="color:#079ca3;font-size:30px">Featured Labs</h2>
                </div>
                <div class="shortborder2"></div>
            </div>
        </div>
        <div *ngIf="labs && labs.length!=0;">
            <div class="partner-bg" style="padding: 0px 20px 0px 20px;">
                <owl-carousel-o [options]="packageSliderConfig"
                    style="height: 300px; display: flex; align-items: center;">
                    <ng-template carouselSlide *ngFor="let lab of labs">
                        <div class="partner-item border-0"
                            style="box-shadow: rgb(0 0 0 / 30%) 0px 0px 10px 3px !important;background-color: #fff;margin: 20px !important;">
                            <a style="cursor: pointer;padding: 11px;" (click)=redirectLabDetail(lab.Lab_Id)>
                                <div style="background:#fff;height:250px;">
                                    <div style="height: 200px;align-items: center;display: flex;padding:25px">
                                        <img src="{{lab.Lab_Logo}}" alt="Image" class="img-fluid"
                                            style="object-fit: cover;max-height: 100%;">
                                    </div>
                                    <div style="height:70px">
                                        <h4 style="color:#ef8017;">{{lab.Lab_Name}}</h4>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
        <div *ngIf="!(labs && labs.length!=0)">
            <h3 class="text-center"
                style="height: 300px;padding: 15px;display: flex;align-items: center;justify-content: center;">
                LAB not available
            </h3>
        </div>
    </div>

    <!-- <div class="partner-shape"><img src="assets/img/partner-shape.png" alt="Image"></div> -->
</div>

<section class="partner-area1">
    <div class="container">
        <div class="row pt-4 mt-3">
            <div class="col-lg-12">
                <div class="section-title text-center" style="margin-bottom: 10px;">
                    <h4 style="color:#079ca3;font-size:30px">Popular Tests</h4>
                </div>
                <div class="shortborder2"></div>
            </div>
            
        </div>
        <div *ngIf="labTests && labTests.length!=0; else NoData">
            <div class="partner-bg" style="padding-top: 0px;">
                <owl-carousel-o [options]="packageSliderConfig">
                    <ng-template carouselSlide style="padding-left: 5px;" *ngFor="let labTest of labTests">
                        <div class="packagecard card border-0 rightMargin"
                            style="box-shadow: rgb(0 0 0 / 30%) 0px 0px 10px 3px !important; padding-top:5px;margin: 20px !important;">
                            <div class="card-body" style="height: 330px;padding: 15px;">
                                <h6 class=" mb-3" style="height: 30px;">{{labTest.Test_Name}}</h6>
                                <div style="height: 225px;">
                                    <div style="height:130px">
                                        <p class="mb-0 pt-2"> Includes:<b>{{labTest.Parameter_Included}}
                                                Parameter</b></p>
                                        <ul class="highlight-test pt-3">
                                            <li *ngIf="labTest.Highlighted_Test_1_Web">
                                                <!-- <i class="bx bxs-pin d-inline" style="color:#ef8017"></i> -->
                                                {{labTest.Highlighted_Test_1_Web}}
                                            </li>
                                            <li *ngIf="labTest.Highlighted_Test_2_Web">
                                                <!-- <i class="bx bxs-pin d-inline" style="color:#ef8017"></i> -->
                                                {{labTest.Highlighted_Test_2_Web}}
                                            </li>
                                            <li *ngIf="labTest.Highlighted_Test_3_Web">
                                                <!-- <i class="bx bxs-pin d-inline" style="color:#ef8017"></i> -->
                                                {{labTest.Highlighted_Test_3_Web}}
                                            </li>
                                        </ul>
                                    </div>
                                    <p class="font-weight-bolder mb-0"
                                        style="text-align: center;font-size: 18px;border-bottom: 1px solid #919292;margin-top: 5px;color: #079ca3;padding-bottom: 10px;">
                                        {{labTest.Lab_Name}}
                                    </p>
                                    <div class="mt-2" style="border-bottom: 1px solid #919292;padding-bottom: 4px;">
                                        <h4 style="font-size:20px;display: inline;">₹{{labTest.Selling_Amt}}
                                            <del *ngIf="labTest.Disc_Per!=0" style="color: #aaa;font-size: 16px;">
                                                ₹{{labTest.Test_MRP}}
                                            </del>

                                        </h4>
                                        <span *ngIf="labTest.Disc_Per>0;"
                                            style="color: #ef8017;border-radius: 5px 0 0 5px;float: right;">
                                            {{labTest.Disc_Per | number:'0.0-0'}}% OFF
                                        </span>
                                    </div>
                                </div>
                                <ul style="padding-top: 10px;">
                                    <li class="read">
                                        <a style="cursor: pointer;color:#ef8017"
                                            (click)="redirectPackageDetail(labTest.Lab_Id,labTest.Test_Id)">
                                            Know More <i class="flaticon-right-arrow"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-6">
                
            </div>
            <div class="col-lg-4 text-align-center">
                <div class="float-right banner-btn wow fadeInUp" style="margin-right:14%;" data-wow-delay="1s">
                    <a (click)="redirectAllTest()" class="default-btn viewbtn" href="javascript:void(0)">View All</a>
                </div>
            </div>
        </div>
        <ng-template #NoData>
            <h3 class="text-center"
                style="height: 375px;padding: 15px;display: flex;align-items: center;justify-content: center;">
                Test not available
            </h3>
        </ng-template>
    </div>
    <!-- <div class="partner-shape"><img src="assets/img/partner-shape.png" alt="Image"></div> -->
</section>


<div class="pt-100 container">
    <section class="services-area services-area-two pt-5 jarallax">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <img src="assets/img/DownloadApp.png" alt="DownloadApp"/>
                </div>
                <div class="col-lg-8">
                    <div class="section-title text-center mt-1">
                        <h3 style="color: #079ca3;
                    font-size: 40px;"> Download Our App Now </h3>
                        <p class="text-center">Tracking health status made easy with the app. Now available on both Google Play Store and
                            App
                            Store.
                            Book health tests and access your smart reports and health trackers anytime anywhere.</p>
                    </div>
                    <div class="mt-5 text-center">
                        <a style="cursor:pointer;margin-left:0px;">
                            <!-- href="https://play.google.com/store/apps/details?id=com.healthians.main.healthians&amp;hl=en_IN"> -->
                            <img class="lazy-loaded" alt=""
                                style="max-width: 215px;" src="assets/img/package/google_play.png">
                        </a>
                        <!-- <div class=" d-inline default-btn" style="font-size:20px;"><i class="bx bxl-android"></i>Google
                    Play</div> -->
                        <a style="cursor:pointer;margin-left:0px;" >
                            <img class="lazy-loaded" 
                                alt="img-appstore" style="max-width: 215px;"
                                src="assets/img/package/appstore.png"></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<section class="text-center mt-3 ">
    <div class="container">
        <div class="row pt-4 mt-3">
            <div class="col-lg-12">
                <div class="section-title text-center" style="margin-bottom: 10px;">
                    <h4 style="color:#079ca3;font-size:30px">Our Specialty                    </h4>
                </div>
                <div class="shortborder2"></div>
            </div>
            
        </div>
    <div class="ptb-50 transitionimg" style="box-shadow:0 0 10px 0 rgb(0 0 0 / 30%);"><img src="assets/img/auth.png" alt="Image"> &nbsp; 
    <img src="assets/img/quality.png" alt="Image"> &nbsp; 
    <img src="assets/img/reliable.png" alt="Image"> &nbsp; 
    <img src="assets/img/trans.png" alt="Image"> &nbsp; 
    <img src="assets/img/affor.png" alt="Image"></div>
    </div>
</section>
<!-- Counter -->
<section class="counter-area">
    <div class="container">
        <div class="counter-bg">
            <div class="row ptb-50">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-satisfy"></i>
                        <h2><span class="odometer" data-count="1688">00</span></h2>
                        <h3>Satisfied patients</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-doctor"></i>
                        <h2>
                            <span class="odometer" data-count="100">00</span>
                            <span class="target">+</span>
                        </h2>
                        <h3>Medical specialist</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-hospital-bed"></i>
                        <h2><span class="odometer" data-count="347">00</span> </h2>
                        <h3>Medical beds</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-experience"></i>
                        <h2>
                            <span class="odometer" data-count="25">00</span>
                            <span class="target">+</span>
                        </h2>
                        <h3>Years Of experience</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Counter -->

<!-- Testimonial -->
<section class="testimonial-area testimonial-area-two pt-70" style="border-bottom: 0px;">
    <div class="container">
        <div class="section-title">
            <!-- <span style="text-align: center !important;">Testimonial</span> -->
            <h2 style="text-align: center !important;">What do our patients say?</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="testimonial-content">
                <i class="flaticon-straight-quotes"></i>
                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                    accumsan lacus vel facilisis.”</p>
                <h3>Markus Twain</h3>
                <!-- <img src="assets/img/testimonial/signature.png" alt="Image"> -->
            </div>

            <div class="testimonial-content">
                <i class="flaticon-straight-quotes"></i>
                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                    accumsan lacus vel facilisis.”</p>
                <h3>Anna Deo</h3>
                <!-- <img src="assets/img/testimonial/signature.png" alt="Image"> -->
            </div>

            <div class="testimonial-content">
                <i class="flaticon-straight-quotes"></i>
                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
                    accumsan lacus vel facilisis.”</p>
                <h3>Kilvis Smith</h3>
                <!-- <img src="assets/img/testimonial/signature.png" alt="Image"> -->
            </div>
        </div>
    </div>
</section>
<!-- End Testimonial -->


<app-footer></app-footer>