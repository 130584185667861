import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginCredential } from 'src/app/entities/login/logincredential';
import { MyOrderResponse } from 'src/app/entities/order/myorderresponse';
import { MyReports } from 'src/app/entities/order/myreports';
import { MyReportsResponse } from 'src/app/entities/order/myreportsresponse';
import { OrderService } from 'src/app/service/order.service';

@Component({
  selector: 'app-myreports',
  templateUrl: './myreports.component.html',
  styleUrls: ['./myreports.component.scss']
})
export class MyreportsComponent implements OnInit {

  public cred: LoginCredential;
  public reports: MyReports[] = new Array<MyReports>();

  constructor(
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router,
    private orderService: OrderService
  ) { }

  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    if (this.cred && this.cred.Cust_Id) {
      await this.getMyReports();
    }
    else {
      this.router.navigate(["/"]);
    }
  }

  private async getMyReports() {
    try {
      this.spinnerService.show();
      let res: MyReportsResponse = await this.orderService.getMyReports(this.cred.Cust_Id);
      if (res && res.res_code == 1) {
        this.reports = res.data;
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }

}