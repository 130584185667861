import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Package } from 'src/app/entities/home/package';
import { PackageResponse } from 'src/app/entities/home/packageresponse';
import { TestSubTest } from 'src/app/entities/labpackage/testsubtest';
import { TestSubTestResponse } from 'src/app/entities/labpackage/testsubtestresponse';
import { LabPackageService } from 'src/app/service/labpackage.service';
import { LoginCredential } from 'src/app/entities/login/logincredential';
import { MyCartService } from '../../service/mycart.service';
import { AddCart } from '../../entities/cart/addcart';
import { NavbarComponent } from '../navbar/navbar.component';
@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.scss']
})
export class PackageDetailComponent implements OnInit {

  public testDetail: Package = new Package();
  public testSubTests: TestSubTest[];
  private cred: LoginCredential;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private labPackageService: LabPackageService,
    private myCartService: MyCartService
  ) { }

  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    let sub = this.route.params.subscribe(async (params) => {
      let labId = +params['labId']; // (+) converts string 'id' to a number
      let testId = +params['testId']; // (+) converts string 'id' to a number    
      this.getTestDetail(labId, testId);
    });
  }

  private async getTestDetail(labId: number, testId: number) {
    try {
      this.spinnerService.show();
      let cityId = 1;
      if (this.cred && this.cred.City_Id)
        cityId = this.cred.City_Id;
      let res: PackageResponse = await this.labPackageService.getTestDetail(cityId, labId, testId);
      if (res && res.res_code == 1) {
        this.testDetail = new Package();
        if (res.data && res.data.length > 0) {
          this.testDetail = res.data[0];
          await this.getTestSubTest(testId);
        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  private async getTestSubTest(testId: number) {
    let res: TestSubTestResponse = await this.labPackageService.getTestAndSubTest(testId);
    if (res && res.res_code == 1) {
      this.testSubTests = res.data;
      //call API for subtest
      let childCount = 0;
      for (let i = 0; i < this.testSubTests.length; i++) {
        let childRes: TestSubTestResponse = await this.labPackageService.getTestAndSubTest(this.testSubTests[i].Test_Id);
        if (childRes && childRes.res_code == 1) {
          this.testSubTests[childCount].ChildTest = childRes.data;
          childCount++;
        }
      }
    }
  }

  public async checkLogin() {
    if (!(this.cred && this.cred.Cust_Id)) {
      NavbarComponent.isLoginDialog = true;
    }
    else
      this.addTesttoCart();
  }

  private async addTesttoCart() {
    try {
      this.spinnerService.show();
      let parameter = new AddCart();
      parameter.City_Id = this.cred.City_Id;
      parameter.Cust_Id = this.cred.Cust_Id;
      parameter.Lab_City_Id = this.testDetail.Lab_City_Id;
      parameter.Lab_City_Test_Rate_Id = this.testDetail.Lab_City_Test_Rate_Id;
      parameter.Lab_Id = this.testDetail.Lab_Id;
      parameter.Test_Id = this.testDetail.Test_Id;
      parameter.Qty = 1;
      let res = await this.myCartService.addmycart(parameter);
      if (res && res.res_code == 1) {
        this.router.navigate(['mycart', this.cred.Cust_Id]);
      }
      if (res && res.msg == "Same Test Already Available In Cart")
        this.toastrService.warning("Same Test Already Available In Cart")
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

}