import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../src/environments/environment";
import { PackageResponse } from '../entities/home/packageresponse';
@Injectable({
    providedIn: 'root'
})
export class CategoryTestService {

    constructor(private http: HttpClient) { }

    async getCategoryWiseTest(cityId: number, testCategoryId: number,start: number, end: number): Promise<PackageResponse> {
        let parameter = JSON.parse(JSON.stringify({
            "City_Id": cityId, "Test_Category_Id": testCategoryId, "Start": start,
            "End": end
        }))
        return await this.http.post(environment.apiUrl + "lab_door/api/get_category_wise_test", parameter)
            .toPromise() as PackageResponse;
    }
}