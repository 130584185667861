export class AddCart {
    Cust_Id: number
    Lab_City_Test_Rate_Id: number
    Lab_City_Id: number
    Lab_Id: number
    City_Id: number
    Test_Id: number
    Qty: number

constructor() { }
}