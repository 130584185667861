import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoginService } from '../../service/login.service';
import * as firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoginCredential } from '../../entities/login/logincredential';
import { LoginCredentialResponse } from '../../entities/login/logincredentialresponse';
import { CustomerDetail } from '../../entities/login/customerdetail';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetCityResponse } from '../../entities/login/getcityresponse';
import { GetCity } from "../../entities/login/getcity";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CreateCustomer } from 'src/app/entities/createcustomer';
import { CustomerService } from '../../service/customer.service';
import { Prescription } from 'src/app/entities/prescription';
import { HomeService } from 'src/app/service/home.service';
import { CompanyData } from 'src/app/entities/home/companydata';
import { CompanyDataResponse } from 'src/app/entities/home/companydataresponse';
import { MyCartService } from 'src/app/service/mycart.service';
import { AddMember } from 'src/app/entities/cart/addmember';
import { NgForm } from '@angular/forms';
import { AddMemberResponse } from 'src/app/entities/cart/addmemberresponse';
import { MycartComponent } from '../mycart/mycart.component';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @ViewChild('FirstFocus') focusElement: ElementRef;
  //isSearch = false;
  public errormsg: string;
  public isLogin = false;
  //isUserLogin: boolean = false;
  public pre: string;
  public mobileNo: string;
  public OTP: string;
  public customer = new LoginCredential();
  static isUserLoggedIn: boolean = false;
  public classReference;
  static isLoginDialog: boolean = false;
  public isRegistration: boolean;
  public isVerifyOtp: boolean = false;
  //user: any;
  private recaptchaVerifier: any;
  public confirmationResult: any;
  public isCustomerDetail: boolean = false;
  public GetCity: GetCity[];
  public selectedCity: GetCity;
  private CustomerDetail: LoginCredential[];
  public cred: LoginCredential;
  public loggedInCust_Id: number;
  public cartCount: number = 0;
  public walletAmount: number;
  private editMemberId: number;
  public memberName: string;
  public memberGender: string;
  public memberAge: number;

  public otp: string;
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };

  public uploadpres: Prescription = new Prescription();
  public cityselection: boolean = false;
  public ismemeberlist: boolean = false;
  public isaddmemberform: boolean = false;
  public uploadprescription: boolean = false;
  public prescription: string;
  public companyData: CompanyData;
  public title: string = "Upload Prescription";
  public familymember: AddMember[] = new Array<AddMember>();
  public selectedMember: AddMember = new AddMember();
  public iscall: boolean;
  public isfamily: boolean = false;
  //isuploaded: boolean = false;
  public searchString: string;

  public cityBeforeLogin: { City_Id: number, City_Name: string } = { City_Id: 1, City_Name: "Nashik" };
  private memberId: number;

  public isDelete: boolean = false;
  private deleteMemberId: number;
  private deletedMember: number;
  public mainTitle: string;
  public deleteMsg: string;
  public deletefamilymem: AddMember = new AddMember();

  constructor(
    private homeService: HomeService,
    private loginService: LoginService,
    private afAuth: AngularFireAuth,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router,
    private customerService: CustomerService,
    private mycartservice: MyCartService
  ) { }

  async ngOnInit() {
    this.classReference = NavbarComponent;
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    let data = JSON.parse(sessionStorage.getItem("CityBeforeLogin")) as any;
    if (data)
      this.cityBeforeLogin = JSON.parse(sessionStorage.getItem("CityBeforeLogin")) as any;

    if (this.cred) {
      NavbarComponent.isUserLoggedIn = true;
      this.loggedInCust_Id = this.cred.Cust_Id;
      await this.getmember();
    }
    await this.getcompanydata()
    await this.getCity();
    await this.getCartCount();
    await this.getwalletamount();
    this.spinnerService.hide();
    // this.getwallet();
  }

  private async getcompanydata() {
    try {
      let res: CompanyDataResponse = await this.homeService.getcompanydata();
      if (res && res.data) {
        this.companyData = res.data[0];
      }
    }
    catch (error) {
      this.toastrService.error(error, "Error")
    }
  }

  private async getCity() {
    try {
      this.spinnerService.show();
      let res: GetCityResponse = await this.loginService.getCity();
      if (res && res.res_code == 1) {

        if (res.data && res.data.length > 0) {
          this.GetCity = res.data;

        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  private async getCartCount() {
    try {
      this.spinnerService.show();
      if (this.cred && this.cred.Cust_Id) {
        let res = await this.customerService.getmycartcount(this.cred.Cust_Id);
        if (res && res.res_code == 1) {
          this.cartCount = res.data[0].Cart_Count;
        }
      }
      this.spinnerService.hide();
    } catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");

    }
  }

  private async getwalletamount() {
    try {
      this.spinnerService.show();
      if (this.classReference?.isUserLoggedIn) {
        let res = await this.customerService.getwalletamount(this.cred.Cust_Id);
        if (res && res.res_code == 1) {
          this.walletAmount = res.data[0].wallet_amount;
        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }

  private async getmember() {
    try {
      this.spinnerService.show();
      //if (this.editMemberId == )
      this.editMemberId = this.cred.Cust_Id;
      this.memberName = this.cred.Cust_Name;
      this.memberAge = this.cred.Cust_Age;
      this.memberGender = this.cred.Gender;
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }

  public async deleteDialog(member: AddMember) {
    this.mainTitle = "Delete Member";
    this.deleteMsg = "Are You Sure You want to Delete Memeber?"
    this.isDelete = true;
    this.deletefamilymem = JSON.parse(JSON.stringify(member));
  }
  public async deletemember(CustFamilyMemberId) {
    try {
      this.spinnerService.show();
      // this.isDelete = true;     
      let res = await this.mycartservice.removemember(CustFamilyMemberId)
      if (res && res.res_code == 1) {
        let index = this.familymember.findIndex(c => c.Cust_Family_Member_Id == CustFamilyMemberId);
        if (index >= 0) {
          this.familymember.splice(index, 1);
        }
        if (this.deletedMember == CustFamilyMemberId) {
          this.memberGender = this.cred.Gender;
          this.memberName = this.cred.Cust_Name;
          this.memberAge = this.cred.Cust_Age;
        }
        this.toastrService.success("Member Deleted Successfully");
        this.isDelete = false;
      }
      this.spinnerService.hide();
    }

    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error);
    }
  }


  public closeDeleteMemeberDialog() {
    this.isDelete = false;
    this.deletefamilymem = new AddMember();
  }

  public bindEditData(member: AddMember) {
    this.selectedMember = JSON.parse(JSON.stringify(member));
    this.title = "Update Member"
  }

  public selectMemeberForTest(user: AddMember) {
    this.title = "Upload Prescription";
    this.selectedMember = JSON.parse(JSON.stringify(user));
    this.editMemberId = user.Cust_Id;
    this.deletedMember = user.Cust_Family_Member_Id;

    this.memberName = user.Member_Name;
    this.memberAge = user.Member_Age;
    this.memberGender = user.Member_Gender;
    this.ismemeberlist = true;
    this.isfamily = false;
  }

  public addNewMember() {
    this.isaddmemberform = !this.isaddmemberform;
    this.title = "Add Member";
    this.selectedMember = new AddMember();
  }

  public async addMember(form: NgForm) {
    try {
      this.spinnerService.show();
      if (form.valid) {
        this.selectedMember.Cust_Id = this.cred.Cust_Id;
        if (!this.selectedMember.Cust_Family_Member_Id) {
          let res: AddMemberResponse = await this.mycartservice.addmemeber(this.selectedMember)
          if (res && res.res_code == 1 && res.data) {
            this.familymember.push(res.data);
            this.isaddmemberform = false;
            this.isfamily = true;
            this.title = "Select Member";
            await this.getmember()
          }
        }
        else {
          let res: AddMemberResponse = await this.mycartservice.updatefamilymember(this.selectedMember);
          if (res && res.res_code == 1 && res.data) {
            let index = this.familymember.findIndex(c => c.Cust_Family_Member_Id == this.selectedMember.Cust_Family_Member_Id);

            if (index >= 0) {
              //this.editMemberId = this.selectedMember.Cust_Id;
              this.isaddmemberform = false;
              this.isfamily = true;
              this.title = "Select Member";
              this.familymember[index].Member_Name = res.data.Member_Name;
              this.familymember[index].Member_Gender = res.data.Member_Gender;
              this.familymember[index].Member_Age = res.data.Member_Age;
              this.familymember[index].Member_Relation = res.data.Member_Relation;

              if (this.editMemberId == this.selectedMember.Cust_Id) {
                this.memberName = this.selectedMember.Member_Name;
                this.memberAge = this.selectedMember.Member_Age;
                this.memberGender = this.selectedMember.Member_Gender;
              }
            }

          }
        }
      }
      else {
        Object.keys(form.controls).forEach(key => {
          form.controls[key].markAsTouched();
        });
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public async selectedImage(e: any) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (file.size <= (1024 * 1024)) {
      if (!file.type.match(pattern)) {
        alert('invalid format');
        return;
      }
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
      //this.buyer.profile = src.substring(src.indexOf(",") + 1);
    }
    else {
      this.toastrService.warning("Image size must be <1MB")
    }
  }

  _handleReaderLoaded(e: any) {
    let reader = e.target;
    this.prescription = reader.result;
    this.pre = this.prescription.split(",")[1];
  }

  public async addPrescription() {
    try {
      if (NavbarComponent.isUserLoggedIn == false) {
        this.spinnerService.hide();
        this.openLogin();
      }
      else {
        if (!this.prescription) {
          this.toastrService.warning("Please upalod prescription");
        }
        else {
          this.spinnerService.show();
          let parameter: Prescription = new Prescription();

          parameter.Cust_Id = this.cred.Cust_Id;
          parameter.Prescription_Photo = this.prescription.split(",")[1];
          parameter.Member_Name = this.selectedMember.Member_Name;
          parameter.Member_Gender = this.selectedMember.Member_Gender;
          parameter.Member_Age = this.selectedMember.Member_Age;
          parameter.City_Id = this.cred.City_Id;
          let res = await this.customerService.uploadPrescription(parameter);
          if (res && res.res_code == 1) {
            this.toastrService.success("Prescription Added Successfully!");
            this.prescription = null;
            this.closeUploadPrescription();
            if (this.router.url == "/myprescriptionorders") {
              let rout = this.router.url;
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate([rout]);
              });
            }
          }
          this.spinnerService.hide();
        }
      }
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }


  public async callRequest() {
    try {
      if (NavbarComponent.isUserLoggedIn == false) {
        this.openLogin();
      }
      else {
        this.spinnerService.show();
        let res = await this.customerService.callrequest(this.cred.Cust_Id)
        if (res && res.res_code) {
          this.iscall = true
        }
        this.spinnerService.hide();
      }
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }

  }
  public async userverify1() {
    try {
      this.uploadprescription = false;
      if (NavbarComponent.isUserLoggedIn == false) {
        this.openLogin();
      }
      else {
        this.ismemeberlist = true;
        this.isfamily = false;
        this.isaddmemberform = false;
        this.uploadprescription = true;
        this.selectedMember.Member_Age = this.cred.Cust_Age;
        this.selectedMember.Member_Gender = this.cred.Gender;
        this.selectedMember.Member_Name = this.cred.Cust_Name;
      }
    }
    catch (error) {
      this.toastrService.show(error);
    }
  }
  public async userverify2() {
    try {
      if (NavbarComponent.isUserLoggedIn == false) {
        this.cityselection = true;

      }
      else {
        this.cityselection = true;
      }
    }
    catch (error) {
      this.toastrService.show(error);
    }
  }
  public async userVerifyFOrWallet() {
    try {
      if (NavbarComponent.isUserLoggedIn == false) {
        this.openLogin();

      }
      else {
        this.router.navigate(['wallet']);
      }
    }
    catch (error) {
      this.toastrService.show(error);
    }
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  public openLogin() {
    this.mobileNo = null;
    this.otp = null;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.focusElement.nativeElement.focus();
    }, 0);
    NavbarComponent.isLoginDialog = true;
    this.isVerifyOtp = false;
  }

  public async login() {
    try {
      if (this.mobileNo.length == 10) {
        // this.isVerifyOtp = true;
        this.spinnerService.show();
        this.isRegistration = false;
        let res = await this.loginService.getCredential(this.mobileNo);
        if (res && res.res_code == 1 && res.data.length > 0) {
          NavbarComponent.isUserLoggedIn = true;
          sessionStorage.setItem("Credential", JSON.stringify(res.data[0]));
          NavbarComponent.isLoginDialog = false;
          this.isLogin = !this.isLogin;
          this.loggedInCust_Id = res.data[0].Cust_Id;
          this.cred = JSON.parse(JSON.stringify(res.data[0]));
          await this.getmember();
          this.refresh();
        }
        else if (res && res.res_code == 0 && res.msg == "Login Fail Not Registered") {
          var _this = this;
          this.isVerifyOtp = true;
          this.recaptchaVerifier = new firebase.default.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': function (response) {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
            }


          });

          this.recaptchaVerifier = this.afAuth.signInWithPhoneNumber("+91" + this.mobileNo, this.recaptchaVerifier)
            .then(function (confirmationResult) {
              _this.confirmationResult = confirmationResult;
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
            }).catch(function (error) {



              // Error; SMS not sent
              // ...
            });
          this.isRegistration = true;
        }
        this.spinnerService.hide();
      }
      else {
        this.toastrService.warning("Mobile number must be 10 digit")
      }
    }

    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");

    }

  }

  public async verifyOTP() {
    try {
      this.confirmationResult.confirm(this.otp).then(async (user) => {
        //        console.log(user)
        this.isCustomerDetail = true;
        this.isRegistration = true;
        this.isVerifyOtp = false;
        // Open Dialog For add Customer Detail
      });

    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");

    }
  }

  public async registerCustomer() {
    try {
      this.spinnerService.show();

      let parameter = new CreateCustomer();
      parameter.City_Id = this.selectedCity ? this.selectedCity.City_Id : 0;
      parameter.Cust_Age = this.customer.Cust_Age;
      parameter.App_Type = "WEB";
      parameter.Cust_Name = this.customer.Cust_Name;
      parameter.Email_Id = this.customer.Email_Id;
      parameter.Gender = this.customer.Gender;
      parameter.Mobile_Number = this.mobileNo;
      parameter.Refer_By_Referral_Code = this.customer.Refer_By_Referral_Code;
      parameter.Token = "XYZ";
      let res: LoginCredentialResponse = await this.loginService.createCustomer(parameter);
      if (res && res.res_code == 1 && res.data.length > 0) {
        this.CustomerDetail = res.data;
        sessionStorage.setItem("Credential", JSON.stringify(this.CustomerDetail[0]));
        NavbarComponent.isLoginDialog = false;
        this.isLogin = !this.isLogin;
      }
      this.refresh();



      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public closeLogin() {
    NavbarComponent.isLoginDialog = false;
    this.isRegistration = false;
    this.isLogin = !this.isLogin;
    this.isVerifyOtp = !this.isVerifyOtp;
    this.refresh();

    // this.isCustomerDetail = !this.isCustomerDetail;
  }

  public async deleteAccount() {
    try {
      this.spinnerService.show();
      let isConfirm = confirm("You Want To Delete Account??")
      if (isConfirm) {
        let res = await this.customerService.deleteAccount(this.loggedInCust_Id)
        if (res && res.res_code == 1) {
          sessionStorage.clear();
          this.isLogin = !this.isLogin;
          NavbarComponent.isUserLoggedIn = false;
          this.refresh();
        }
        this.spinnerService.hide();
      }
      else {
        this.isLogin = !this.isLogin;
        this.spinnerService.hide();
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error);
    }

  }

  public loggedOut() {
    try {
      this.spinnerService.show();
      sessionStorage.removeItem("Credential");
      this.loggedInCust_Id = 0;
      this.isLogin = !this.isLogin;
      NavbarComponent.isUserLoggedIn = false;

      this.router.navigate(["/"]);
      if (this.router.url == "/") {
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(["/"]);
        });
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error);
    }
  }


  public async userVerify() {
    try {
      // this.spinnerService.show();
      if (NavbarComponent.isUserLoggedIn == false) {
        //this.spinnerService.hide();
        this.openLogin();
      }
      else {
        this.router.navigate(['mycart', this.cred.Cust_Id]);
      }
    }
    catch (error) {
      this.toastrService.show(error);
    }
  }

  private async getwallet() {
    try {
      this.spinnerService.show();
      let res = await this.customerService.getwalletamount(this.cred.Cust_Id);
      if (res && res.res_code == 1) {
        this.walletAmount = res.data[0].wallet_amount;
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }

  public async changeCity(cityname: string, cityId: number) {
    try {
      this.spinnerService.show();
      if (this.cred && this.cred.Cust_Id) {
        let res = await this.customerService.changecity(this.cred.Cust_Id, cityId);
        if (res && res.res_code == 1) {
          this.cred.City_Name = cityname;
          this.cred.City_Id = cityId;
          this.cityselection = false;
          this.cityBeforeLogin.City_Id = cityId;
          this.cityBeforeLogin.City_Name = cityname;
          sessionStorage.setItem("CityBeforeLogin", JSON.stringify(this.cityBeforeLogin));
          sessionStorage.setItem("Credential", JSON.stringify(this.cred))
          let res = await this.mycartservice.clearCart(this.cred.Cust_Id);
          if (res && res.res_code == 1) {
            //this.refresh();
            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(["/"]);
            });
          }
          // console.log(res);      
        }
      }
      else {

        this.cityBeforeLogin.City_Id = cityId;
        this.cityBeforeLogin.City_Name = cityname;
        sessionStorage.setItem("CityBeforeLogin", JSON.stringify(this.cityBeforeLogin));
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(["/"]);
        });
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.toastrService.error(error, "Error")
    }
  }


  public async changeMember() {
    try {
      this.spinnerService.show();
      let res = await this.mycartservice.getmyfamilymember(this.loggedInCust_Id)
      if (res && res.res_code == 1) {
        if (res.data && res.data) {
          this.title = "Select Member"
          this.familymember = res.data;
          // this.ismemeberlist = true;
        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }

  public async closefamily() {
    this.isfamily = false;
    this.ismemeberlist = true;
    this.title = "Upload Prescription"

  }

  public async closeaddmember() {
    this.isaddmemberform = false;
    this.isfamily = true;
    this.title = "Select Member";
  }

  private async refresh() {
    if (this.router.url == "/") {
      let rout = this.router.url;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([rout]);
      });
    }
    else if (this.router.url == "/packages") {
      let rout = this.router.url;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([rout]);
      });
    }
    else if (this.router.url == "/categories") {
      let rout = this.router.url;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([rout]);
      });
    }
    else if (this.router.url == "/populartest") {
      let rout = this.router.url;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([rout]);
      });
    }
    else if (this.router.url == "/aboutus") {
      let rout = this.router.url;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([rout]);
      });
    }
    else if (this.router.url == "/contactus") {
      let rout = this.router.url;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([rout]);
      });
    }
    else if (this.router.url == "/mycart") {
      let rout = this.router.url;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([rout]);
      });
    }
    else if (this.router.url == "/billsummary") {
      let rout = this.router.url;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([rout]);
      });
    }
    else {
      this.router.navigate(["/"]);
    }
  }

  public redirectviewprofile() {
    this.router.navigate(['viewprofile', this.cred.Cust_Id]);
  }

  public redirectToMyOrder() {
    this.router.navigate(['/myorders']);
  }
  public redirectToMyPrescriptionOrder() {
    this.router.navigate(['/myprescriptionorders']);
  }
  public redirectToMyReports() {
    this.router.navigate(['/myreports']);
  }

  public closeCitySelection() {
    this.cityselection = false;
  }

  public closeUploadPrescription() {
    this.uploadprescription = false;
    this.prescription = null;
  }

  public searchTestByName() {
    sessionStorage.setItem("SearchTest", this.searchString);
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(["/packages"]);
    });
  }

}