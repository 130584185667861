import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginCredential } from 'src/app/entities/login/logincredential';
import { MycartResponse } from 'src/app/entities/cart/mycartresponse';
import { MyCartService } from '../../service/mycart.service';
import { MyCart } from 'src/app/entities/cart/mycart';
import { NgForm } from '@angular/forms';
import { AddMember } from '../../entities/cart/addmember';
import { AddMemberResponse } from 'src/app/entities/cart/addmemberresponse';
// import { FamilyMember } from '../../entities/cart/familymember';

@Component({
  selector: 'app-mycart',
  templateUrl: './mycart.component.html',
  styleUrls: ['./mycart.component.scss']
})
export class MycartComponent implements OnInit {
  
  public isaddmemberform: boolean = false;
  public ismemeberlist: boolean = false;
  public cred: LoginCredential;
  public myCart: MyCart[] = new Array<MyCart>();
  public member = new AddMember();
  public isAddMember: boolean = false;
  public familymember: AddMember[] = new Array<AddMember>();
  public selectedMember: AddMember = new AddMember();
  public deletefamilymem: AddMember = new AddMember();
  public memberName: string;
  public memberGender: string;
  public memberAge: number;
  public isOpenMemberDialog: boolean;
  public title: string;
  static getmycarttest: any;
  public isDelete: boolean = false;
  public mainTitle: string;
  public deleteMsg: string;
  public isDeleteMemeber: boolean = false;
  private deletedMember: number;
  private editMemberId: number;
  public isremoveAll:boolean = false;
  public delcart: MyCart = new MyCart();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private mycartservice: MyCartService,
  ) { }

  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    this.memberName = this.cred.Cust_Name;
    this.memberAge = this.cred.Cust_Age;
    this.memberGender = this.cred.Gender;

    let sub = this.route.params.subscribe(async (params) => {
      let custId = +params['custId'];
      await this.getmycarttest(custId);
      await this.getmember(custId);
    });
  }

  public selectMemeberForTest(user: AddMember) {
    this.editMemberId = user.Cust_Id;
    this.memberName = user.Member_Name;
    this.memberAge = user.Member_Age;
    this.memberGender = user.Member_Gender;
    this.deletedMember = user.Cust_Family_Member_Id;
    this.closeAddMemberDialog();
  }

  private async getmember(custId) {
    try {
      this.spinnerService.show();
      this.editMemberId = this.cred.Cust_Id;
      let res = await this.mycartservice.getmyfamilymember(custId)
      if (res && res.res_code == 1) {
        if (res.data && res.data) {
          this.familymember = res.data;
        }

      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }

  private async getmycarttest(custId) {
    try {
      this.spinnerService.show();
      let res: MycartResponse = await this.mycartservice.getmycarttest(custId)
      if (res && res.res_code == 1) {
        if (res.data && res.data.length > 0) {
          this.myCart = res.data;
        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }
  public async deletecartdialog(cart : MyCart) {
    this.isDelete = true;
    this.mainTitle = "Delete Package";
    this.deleteMsg = "Are You Sure You want to Delete Package From Cart?";    
    this.delcart = JSON.parse(JSON.stringify(cart));
  }

  public async deleteallcartdialog() {
    this.isDelete = true;
    this.mainTitle = "Delete Package";
    this.deleteMsg = "Are You Sure You want to Delete Package From Cart?";    
    
  }

  public async deletecart(cartId) {
    try {
      this.spinnerService.show();      
      let res = await this.mycartservice.removecart(cartId)
      if (res && res.res_code == 1) {       
        let index = this.myCart.findIndex(c => c.My_Cart_Id == cartId);
        if (index >= 0) {
          this.myCart.splice(index, 1);
          this.isDelete = false;
        }
        //this.refresh();
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error);
    }
  }

  public async closedelcart() {
    this.isDelete = false;
    this.delcart = new MyCart();
  }
  public async removeAll() {
    try {
      this.spinnerService.show();
      let res = await this.mycartservice.clearCart(this.cred.Cust_Id);
      if (res && res.res_code == 1) {
        this.myCart = [];
        this.isDelete = false;
      }
      this.spinnerService.hide();
    }

    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error);
    }
  }

  public async deleteDialog(member: AddMember) {
    this.mainTitle = "Delete Member";
    this.deleteMsg = "Are You Sure You want to Delete Memeber?"
    this.isDeleteMemeber = true;
    this.deletefamilymem = JSON.parse(JSON.stringify(member));
  }
  
  public async deletemember(CustFamilyMemberId) {
    try {
      this.spinnerService.show();
      let res = await this.mycartservice.removemember(CustFamilyMemberId)
      if (res && res.res_code == 1) {
        let index = this.familymember.findIndex(c => c.Cust_Family_Member_Id == CustFamilyMemberId);
        if (index >= 0) {
          this.familymember.splice(index, 1);
        }
        if (this.deletedMember == CustFamilyMemberId) {
          this.memberGender = this.cred.Gender;
          this.memberName = this.cred.Cust_Name;
          this.memberAge = this.cred.Cust_Age;
        }
        this.toastrService.success("Member Deleted Successfully");
        this.isDeleteMemeber = false;

      }
      this.spinnerService.hide();

    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error);
    }
  }

  public closeDeleteMemeberDialog() {
    this.isDeleteMemeber = false;
    this.deletefamilymem = new AddMember();
  }

  public async addMember(form: NgForm) {
    try {
      this.spinnerService.show();
      if (form.valid) {
        this.selectedMember.Cust_Id = this.cred.Cust_Id;
        if (!this.selectedMember.Cust_Family_Member_Id) {
          let res: AddMemberResponse = await this.mycartservice.addmemeber(this.selectedMember)
          if (res && res.res_code == 1 && res.data) {
            this.familymember.push(res.data);
            this.isaddmemberform = false;
            this.ismemeberlist = true;
            await this.getmember(this.cred.Cust_Id)
          }
        }
        else {
          let res: AddMemberResponse = await this.mycartservice.updatefamilymember(this.selectedMember);
          if (res && res.res_code == 1 && res.data) {
            let index = this.familymember.findIndex(c => c.Cust_Family_Member_Id == this.selectedMember.Cust_Family_Member_Id);
            if (index >= 0) {
              this.isaddmemberform = false;
              this.ismemeberlist = true;
              this.familymember[index].Member_Name = res.data.Member_Name;
              this.familymember[index].Member_Gender = res.data.Member_Gender;
              this.familymember[index].Member_Age = res.data.Member_Age;
              this.familymember[index].Member_Relation = res.data.Member_Relation;
              if(this.editMemberId == this.selectedMember.Cust_Id){
                this.memberName = this.selectedMember.Member_Name;
                this.memberAge = this.selectedMember.Member_Age;
                this.memberGender = this.selectedMember.Member_Gender;
              }
            }

          }
        }
      }
      else{
        Object.keys(form.controls).forEach(key => {
          form.controls[key].markAsTouched();
        });
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public bindEditData(member: AddMember) {
    this.title = "Edit Member";
    this.selectedMember = JSON.parse(JSON.stringify(member));
  }

  public navigateToSummary() {
    let obj = { memberName: this.memberName, memberAge: this.memberAge, memberGender: this.memberGender };
    sessionStorage.setItem("TestMemberObj", JSON.stringify(obj));
    this.router.navigate(["/billsummary"]);
  }

  public addNewMember() {
    this.isaddmemberform = !this.isaddmemberform;
    this.ismemeberlist = !this.ismemeberlist;

    this.isOpenMemberDialog = true;
    this.title = "Add Member";
    this.selectedMember = new AddMember();
  }

  public async openAddMemberDialog() {
    this.isOpenMemberDialog = true;
    this.title = "Select Member";
    await this.getmember(this.cred.Cust_Id)
  }

  public closeAddMemberDialog() {
    this.isOpenMemberDialog = false;
  }

  private async refresh() {
    if (this.router.url == "/") {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/"]);
      });
    }
    if (this.router.url == "/packages") {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/packages"]);
      });
    }
    if (this.router.url == "/categories") {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/categories"]);
      });
    }
    if (this.router.url == "/populartest") {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/populartest"]);
      });
    }

    if (this.router.url == "/aboutus") {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/aboutus"]);
      });
    }
    if (this.router.url == "/contactus") {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/contactus"]);
      });
    }

    if (this.router.url == "/mycart") {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/mycart"]);
      });
    }
    if (this.router.url == "/billsummary") {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/billsummary"]);
      });
    }
    else {
      this.router.navigate(["/"]);
    }
  }
}
