export class CouponCode {
    Coupon_Id: number
    Coupon_Code: string
    Value_Type: string
    Value: number
    Upto_Discount_Amount: number
    Minimum_Order_Value: number

    constructor() { }

}