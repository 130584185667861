export let customOptions: any = {
    loop: true,
    dots: false,
    nav: true,
    autoplay: true,
    slideSpeed: 200,
    navContainerClass: 'owl-nav',
    navClass: ['owl-prev', 'owl-next'],
    navText: ['<i class="bx bx-chevron-left"></i>', '<i class="bx bx-chevron-right"></i>'],
    responsive: {
        0: {
            items: 1
        },
        400: {
            items: 1
        },
        740: {
            items: 1
        },
        940: {
            items: 1
        }
    },

};

// export let HomeSliderConfig: any = {
//     loop: true,
//     nav: true,
//     dots: false,
//     autoplay: true,
//     slideSpeed: 200,
//     navContainerClass: 'owl-nav',
//     navClass: ['owl-prev', 'owl-next'],
//     navText: ['<i class="bx bx-chevron-left"></i>', '<i class="bx bx-chevron-right"></i>'],
//     responsive: {
//         0: {
//             items: 1
//         },
//         400: {
//             items: 1
//         },
//         740: {
//             items: 1
//         },
//         940: {
//             items: 1
//         }
//     },
// };

export let PackageSliderConfig: any = {
    SlideSpeed: 200,
    loop: true,
    nav: true,
    autoplay: false,
    dots: false,
    navSpeed: 300,
    navContainerClass: 'owl-nav',
    navClass: ['owl-prev', 'owl-next'],
    navText: ['<i class="bx bx-chevron-left"></i>', '<i class="bx bx-chevron-right"></i>'],
    responsive: {
        1200: {
            items: 5
        },
        991: {
            items: 4
        },
        767: {
            items: 3
        },
        420: {
            items: 2
        },
        0: {
            items: 1
        }
    }
}

// export let PackageSliderConfig1: any = {
//     SlideSpeed: 200,
//     loop: true,
//     nav: true,
//     autoplay: true,
//     dots: false,
//     navSpeed: 300,
//     navContainerClass: 'owl-nav',
//     navClass: ['owl-prev', 'owl-next'],
//     navText: ['<i class="bx bx-chevron-left"></i>', '<i class="bx bx-chevron-right"></i>'],
//     responsive: {
//         1200: {
//             items: 5
//         },
//         991: {
//             items: 4
//         },
//         767: {
//             items: 3
//         },
//         420: {
//             items: 2
//         },
//         0: {
//             items: 1
//         }
//     }
// }