export class MyPrescriptionOrder {
    Prescription_Order_Id: number
    Cust_Id: number
    Order_Date_Time: Date
    Order_status: string
    Order_Cancelation_Reason: string
    Status_Update_Date_Time?: Date
    Member_Name: string
    Member_Gender: string
    Member_Age: string
    Prescription_Photo: string

    //Extendex Property
    OrderStatusColorCode: string

    constructor() { }

}