<!-- Navbar -->
<app-navbar></app-navbar>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;"> Categories</span>
            </div>
        </div>
        
    </div>
</div>
<!-- End Page Title -->
<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 style="color:#079ca3;">All Categories</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<!-- Services -->

<div class="partner-area4 mb-2 p-0" >
    <div class="container">
        <div class=" partner-bg p-0" *ngIf="allCategory && allCategory.length!=0">
            <div class="row" style="display: flex;">
                <div class="col-lg-3 col-sm-6" style="flex-wrap: wrap;" *ngFor="let allcategory of allCategory">
                    <div class="single-services"
                        (click)="redirectCategoryWiseTest(allcategory.Category_Name,allcategory.Test_Category_Id)"
                        style="box-shadow: rgba(0, 0, 0, 0.30) 0px 0px 10px 3px;background-color: #fff;cursor: pointer;margin: 10px 0px !important;">
                        <div class="services-icon">
                            <img src="{{allcategory.Category_Image}}" alt="Image" style="height:100px;width:100px">
                        </div>
                        <h3 style="height:60px;line-height: 1;"><a style="color:#ef8017;font-size:24px">{{allcategory.Category_Name}}</a></h3>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- End Services -->

<!-- Footer -->
<app-footer></app-footer>
<!-- End Footer -->