import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Package } from 'src/app/entities/home/package';
import { PackageResponse } from 'src/app/entities/home/packageresponse';
import { LoginCredential } from 'src/app/entities/login/logincredential';
import { Page } from 'src/app/entities/page';
import { LabPackageService } from 'src/app/service/labpackage.service';

@Component({
  selector: 'app-popular-test',
  templateUrl: './popular-test.component.html',
  styleUrls: ['./popular-test.component.scss']
})
export class PopularTestComponent implements OnInit {

  public page = new Page();
  private skip: number = 0;
  private take: number = 6;
  private cred: LoginCredential;
  public totalProduct: number = 0;
  public paginate: any = {};
  public pageNo: number = 1;
  private loadproduct: number = 6;
  public searchTest: string;
  public cityBeforeLogin: { City_Id: number, City_Name: string } = { City_Id: 1, City_Name: "Nashik" };
  public labPackages: Package[];

  constructor(
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private labPackageService: LabPackageService
  ) { }

  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    this.searchTest = sessionStorage.getItem("SearchTest") as string;
    let data = JSON.parse(sessionStorage.getItem("CityBeforeLogin")) as any;
    if (data)
      this.cityBeforeLogin = JSON.parse(sessionStorage.getItem("CityBeforeLogin")) as any;
    await this.getAllTest();
  }

  public async setPage(page: number) {
    this.pageNo = page;
    this.skip = (page - 1) * this.loadproduct;
    this.take = this.loadproduct;
    await this.getAllTest();
  }

  private async getAllTest() {
    try {
      this.spinnerService.show();
      let cityId = 1;
      if (this.cred && this.cred.City_Id)
        cityId = this.cred.City_Id;
      else if (this.cityBeforeLogin.City_Id)
        cityId = this.cityBeforeLogin.City_Id;
      let res: PackageResponse = await this.labPackageService.getAllTest(cityId, this.skip, this.take);
      if (res && res.res_code == 1) {
        this.labPackages = res.data;
        this.totalProduct = res.Total_Test_Count;
        this.paginate = this.labPackageService.getPager(res.Total_Test_Count, +this.pageNo, this.loadproduct);

      }

      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public redirectPackageDetail(labId: number, testId: number): void {
    this.router.navigate(['packagedetail', labId, testId]);
  }

}
