import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refresh-component',
  templateUrl: './refresh-component.component.html',
  styleUrls: ['./refresh-component.component.scss']
})
export class RefreshComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
