import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../src/environments/environment";
import { addAddressResponse } from '../entities/billsummary/addaddressresponse';
import { AddAddress } from '../entities/billsummary/addaddress';
import { CustomerDetail } from '../entities/login/customerdetail';
import { ResponseWithoutData } from '../entities/responsewithoutdata';

@Injectable({
  providedIn: 'root'
})
export class BillSummaryService {

  constructor(private http: HttpClient) { }

  async addaddress(addaddress: AddAddress): Promise<addAddressResponse> {

    return await this.http.post(environment.apiUrl + "lab_door/api/add_new_delivery_address", addaddress)
      .toPromise() as addAddressResponse;
  }

  async getaddress(custId: number): Promise<any> {
    let parameter = JSON.parse(JSON.stringify({ "Cust_Id": custId }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_cutomer_delivery_adress", parameter)
      .toPromise() as any;
  }

  async updateaddress(addaddress: AddAddress): Promise<addAddressResponse> {
    return await this.http.post(environment.apiUrl + "lab_door/api/update_cutomer_adress", addaddress)
      .toPromise() as addAddressResponse;
  }
  async getcollectiontimeslot(): Promise<any> {
    return await this.http.get(environment.apiUrl + "lab_door/api/get_collection_time_slot")
      .toPromise() as any;
  }

  async setdefaultaddress(custId: number, deliveryaddressId: number): Promise<ResponseWithoutData> {
    let parameter = JSON.parse(JSON.stringify({ "Cust_Id": custId, "Delivery_Address_Id": deliveryaddressId }))
    return await this.http.post(environment.apiUrl + "lab_door/api/set_default_address", parameter)
      .toPromise() as ResponseWithoutData;
  }
}