<app-navbar></app-navbar>
 <!-- Page Title -->
<div class="page-title-area  register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;">Privacy Policy</span>
            </div>
        </div>
       
    </div>
</div>
<!-- End Page Title -->
<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
        <h2 class="headingcolor">Privacy Policy</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<section class="privacy">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <p >It is mandate for any user to comply with our privacy policy that entails the standards of our products and services. Any user is bound to agree with all the terms described herein and all those incorporated by reference. We request you to immediately switch from our website if by any chance you do not agree with our privacy policy and terms.</p>
                <h3  class="headingcolor">Content & Purpose</h3>
                <p>This ‘Privacy Policy’ purely applies to the use of our domain, www.arlabdoors.com. ARLabDoors is a web based digital portal that also runs a mobile application with its name, being exclusively owned and operated by ARLabDoors Private Limited, a company solely listed under the provisions of the Companies Act, 2013 (hereinafter, referred to as ARLabDoors or “We” or “Our” or “Us” or “Company”). Our domain name and mobile application are collectively referred to as ‘Website’.
                </p>
                <p>Our Website is a purely digital platform that facilitates the purchase of health diagnostic services from elite diagnostic labs (hereinafter referred to as “Services”, with the relevant “labs” to as “Sellers”). The Seller and ARLabDoors arrangement is governed solely in accordance with this Privacy Policy and Terms of Use. The Services can be availed by the buyer (human/living body) who agrees to purchase through Website after fulfilling registration formalities on portal in accordance with the parameters and procedures determined by ARLabDoors from time to time. We offer Services in various different modes, facilitating the purchase process for our buyers/customers. They may include issuing of discount coupons, vouchers or waivers that can be availed for limited goods/services.
                </p>
                <p>ARLabDoors has incorporated fair security procedures corresponding to the information asset protection and with the way our business operates. Although we comply with the highest security standards and match industry standards, we cannot warrant the complete security of any information that is being transferred over the digital nodes, due to internet vulnerabilities. Hence, ARLabDoors remains detached of any claims and legal boundation pertaining to any misuse or misrepresentation of information by any third party. 
                </p>
                <p>To provide Services and for rest of the purposes being identified in thie Privacy Policy, ARLabDoors may require to collect and swarm certain information and data of the Users. ARLabDoors is highly committed towards protecting Personal Information of its Users and take all cautions as per legal standards to maintain confidentiality of Personal Information of its Users.</p>
                <p>This Privacy Policy intends to make available following information and help you understand its major highlights as follows:
                </p>
                <ul>
                    <li>The type of Personal Information including but not limited to sensitive personal information or data that ARLabDoors might collect from its Users.
                    </li>
                    <li>Motive of data collection, its relevance and mode of usage by ARLabDoors.</li>
                        <li>Disclosure of your Personal Information, if required, to whom and how it shall be done to ensure your security.</li>
                        <li>Protection of your Personal Information and Data by ARLabDoors.</li>
                        <li>Freedom to Users for accessing and/or modifying their Personal Information as per need. 
                        </li>
                </ul>
                <p>This Privacy Policy applies to the use of Website (Www.ARLabDoors.Com) by all Users/Sellers/Service Providers/Mediators. Any User’s acceptance to use the Services being sold by ARLabDoors and accessing Website makes them automatically bounded to accept Our Terms of Use and Privacy Policy. It is mandate for any User to read and understand the provisional requirements of this Privacy Policy before sharing any data or Personal Information with ARLabDoors.
                </p>
                <p>This Privacy Policy, being published by ARLabDoors is purely in compliance with the (Indian) Information Technology Act, 2000 and so, it abides by all the rules and regulations along with the guidelines hereunder this Act and religiously follows the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011.
                </p>
                <h3 class="headingcolor">What is the personal information?
                </h3>
                <p>Personal Information refers to any information relating to a natural person or a human being, which, either directly or indirectly or in combination with other relevant information is capable of identifying the concerned person as one entity.
                </p>
                <p>Any Personal Information of a natural person or human being that relates to security passwords being used for financial transactions such as credit card or debit card information or bank details or any payment related authority details, sexual orientation including but not limited to medical records and history, mental and physiological health condition, biometric information or any such detail relating to above being provided to ARLabDoors for any further processing or storage comes under Sensitive Personal Data or Information. Any data that is freely available under the Right to Information Act, 2005 or any other law should not be misunderstood or related otherwise as Sensitive Personal Data or Information.
                </p>
                <h3  class="headingcolor">Types of personal information ARLabDoors Collect:
                </h3>
                <p>Without registering to Our Website, a user may not have full privilege to access all the features and services being provided by ARLabDoors. He may have limited access resulting in inability to utilize our service benefits, discounts, coupons or vouchers. To avail full features of our Website, user if required to create and account on our Domain which qualifies for a legit Registration Process. It is only due to Registration purpose, ARLabDoors may ask for following Personal Information from its Users:
                </p>
                <ul>
                    <li>Name</li>
                    <li>User ID</li>
                    <li>Email address</li>
                    <li>Address (including country and ZIP/postal code)</li>
                    <li>Gender</li>
                    <li>Age</li>
                    <li>Phone Number</li>
                    <li>Password as per User’s choice</li>
                    <li>Authentic financial account information</li>
                    <li>Other details as per User’s discretion</li>
                        
                </ul>
                <p>For a User to avail Our Services, it is mandate for the Users to submit copies of their prescription via document upload option on the Website or share same with us via Email or any other means to ensure the correctness of the investigation. Any uploaded copies shall remain with ARLabDoors as per the Terms of Use and the same shall remain disclosed only as per our Privacy Policy and not otherwise. 
                </p>
                <p>At ARLabDoors, we keep records of any phone calls made to our customer services department for any inquires, complains or order information purposes or otherwise. We reserve this right to record and save such calls for our internal training and evaluation purpose.
                </p>
                <p><strong>Internet usage:</strong> ARLabDoors keeps the User’s browsing history as well the prior visits before landing to Our webpage in count. Along with reserving User’s Internet Protocol (IP) address, we may also track as to which Internet Browser is being used by our Users and the name of User’s ISP. Our Website uses cookies to store data which in neither personal or sensitive in nature but helps ARLabDoors to improvise technical administration, research and development of our own domain.
                </p>
                <p>The Website may allow Users to interact with each other or share their viewpoints/information/details via common platforms, whereupon other Users may collect, misuse or misrepresent such data. ARLabDoors hereby expressly disclaims any liability for any such act caused due to ignorance by the Users. Sharing your data or information with anyone publically can is neither suggested nor acceptable.  
                </p>
                <p>ARLabDoors strictly avoids collecting any Personal Data or Information related to children.
                </p>
                <p>Any Personal Information or Data being provided to ARLabDoors in relation to any contests including but not limited to Quiz/Contest/Survey alone shall be treated same as the remaining Personal Information mentioned hereunder this Privacy Policy.
                </p>
                <h3 class="headingcolor">Purposes for which ARLabDoors may use your information:
                </h3>
                <p>ARLabDoors shall retain User Information up to an extent that fulfills the delivery of Services for the Users. The information collected by Us shall be utilized only for procedural or regulatory purposes included but not limited to the following:
                </p>
                <ul>
                    <li>Registering User on the Website.</li>
                    <li>Processing User’s orders / requests.</li>
                    <li>Completion of Transaction cycle with the Users. </li>
                    <li>Technical administration of the Website including timely customization for better user experience.</li>
                    <li>Website content management procedures.</li>
                    <li>Rightful execution of personalized information and target advertisement.</li>
                    <li>User Interface enhancement of the Website.</li>
                    <li>Effective Research and development to understand User needs and requirements.  </li>
                    <li>Non-personally identifiable information such as number of visitors, transactional details and usage statics for Website, business intelligence data pertaining to improvement and enhancement of unique customer experiences.</li>
                    <li>That which relate to or directly associated with order requests, complains, inquiries, disputes, arbitration and any other customer service/care related activities/details.</li>
                    <li>Any contests being organized by ARLabDoors wherein User shares his/her personal information or sensitive data for fulfillment of participation procedure.</li>
                    <li>Communication of any changes in our Services or Privacy Policy or the Terms of Use to its Users.</li>
                    <li>User Identity verification for preventing frauds.</li>
                    <li>Investigation, enforcement and resolution of disputes and application of Privacy Policy and Terms of Use, either on our won or via any third party service provider(s).
                        </li>
                </ul>
                <h3 class="headingcolor">Disclosure and transfer of your personal information by ARLabDoors:
                </h3>
                <p>ARLabDoors may require smooth disclosure or transfer of User’s Personal Information to the third parties as listed below in line with the purposes mentioned in this Privacy Policy and the Terms of Use.
                </p>
                    <ul>
                        <li>To Sellers and other service providers hired or appointed by ARLabDoors for the purpose of delivering services on behalf of ARLabDoors under a binding legal contract. In general, there providers do not have a legal right to disclose any personal information of the User, however, few of those who provide online communication service on the Website, may, represent the Personal Information along with the provisions of these services in accordance with their own privacy policies.</li>
                            <li>To the affiliates of ARLabDoors who resides in India or otherwise for the same purpose as us.</li>
                                <li>To any governmental institutions and authorities to the extent that fulfills the legal or judiciary regulations that further extends to defend the rights or property of ARLabDoors and validated credit risks.</li>
                                    <li>Personal Information captured in accordance with any contests organized by ARLabDoors. This shall be disclosed or transferred to third parties to fulfill prize distribution or any such parametric requirement.</li>
                    </ul>
                <p>ARLabDoors’ employees and data processors have access to User Information on a need-to-know basis. All our employees and data processors abide by confidentiality and responsibility of holding User’s data or information.
                </p>
                <p>Non-personally identifiable information such as User’s internet use, IP address, browsing history, daily/monthly/average visitors on website etc. to be disclosed with our strategic partners, sponsors or investors to support our growth and expansion.
                </p>
                <p>In case of any selling, reselling or reorganizing our business, ARLabDoors reserve right to disclose or transfer User Information to a third party to which ARLabDoors sells its assets to.
                </p>
                <h3 class="headingcolor">Retention of the information:
                </h3>
                <p>Each information collected under this Privacy Policy is maintained by ARLabDoors solely in electronic form on its server and the respective systems of its employees and data processors. This information may be converted to physical form from time to time for internal purpose only. ARLabDoors ensures confidentiality of User Information irrespective of the mode of storage to its server or in any physical format as specified under this Privacy Policy.
                </p>
                <p>A small portion of the Website allows Sellers to use, maintain and organize such information of Users to align their selling processes and purchase of appropriate services. Hence, ARLabDoors may record and submit such information to the rightful authorities upon legit requests and permissions.
                </p>
                <p>To ensure protection of User information, ARLabDoors ensure any personal data or information is not retained for a longer period than its actual relevance and requirement. 
                </p>
                <h3 class="headingcolor">Third-party advertisements:
                </h3>
                <p>Our Website may exhibit links to third-party advertisements or websites or any other third-party electronic communication service (herein referred to as “Third Party Links”). Such links are operated by third parties and ARLabDoors has no actual relevance to or associate with them, unless expressly specified or otherwise.
                </p>
                <p>Accessing any such Third Party Links are solely done at the User’s discretion while ARLabDoors disclaims any damage caused due to same. ARLabDoors cannot be held responsible for the provisions, practices and policies of any Third Party Links which are neither authorized, published or advertized by ARLabDoors explicitly.
                </p>
                <h3 class="headingcolor">Procedural Security practices:
                </h3>
                <p>ARLabDoors operates in a secure technical, managerial, physical and operational environment which ensured security of all the Personal Information being shared by the Users. Any misuse or misrepresentation of User data is strictly prohibited and discouraged at ARLabDoors. </p>
                    <p>We take proactive steps to ensure seamless User experience by further ensuring to protect undue disclosure of Personal Information to any third party. We do not pass User Personal information without validating the authenticity of requirement, be it for administrative or legal purpose. </p>
                        <p>Your Personal Information shared over Internet Protocol, remains susceptible to data theft, for which, ARLabDoors cannot be held responsible. </p>
                <h3 class="headingcolor">User's rights to their personal information collected by ARLabDoors:
                </h3>
                <p>Any information shared with ARLabDoors, including the Sensitive Personal Information remains voluntary in nature. At any time, ARLabDoors allows freedom to the User to withdraw his/her consent.  </p>
                <p>ARLabDoors also under its freedom regulation allows users to access modify or delete the shared information with Us. In accordance with this privacy Policy. However, originally shared information may still remain as archived details to our servers. If at any time, user wants to update his/her Personal Information, he/she may utilize our emailing facility and touch base with our customer care executives directly for any such assistance. </p>
                <p>If a User has landed to our domain unintentionally or do not want us to keep his/her invisible information such as IP address, browsing history or usage etc. should immediately quit this browser application and clear all temporary cookies installed by ARLabDoors. All visitors, however, are encouraged to clear cookies in their browsers after each browsing session to maintain privacy and ensuring their own security. </p>
                <p>If a User realizes that he has inadvertently submitted any kind of Personal or Sensitive information to ARLabDoors prior understanding this Privacy Policy, and he/she does not agrees to our norms as stated hereunder should contact us via email request to destroy any information relating to them.</p>
                <p>If in case a User disagrees to provide his/her consent for further utilization of Personal Information by ARLabDoors or withdraws his/her consent, We reserves the right to discontinue his services with immediate effect.
                    </p>
                <h3 class="headingcolor">Addtional notes for the users of this Website/App:
                </h3>
                <p>ARLabDoors has no control over the internet search results or any third party links from within its Services. Be careful about clicking on any third party link or accessing any other website apart from ARLabDoors. Any cookies installation or storing of Personal Information of any type shall remain detached from ARLabDoors privacy policy norms laid down for Personal/Sensitive Information being collected by us.</p>
                <p>ARLabDoors shall not be liable for any misrepresentation of misuse of Personal Information or Sensitive Personal Data being supplied on any other servers than ours. Authenticity of your information if found to be untrue or inaccurate shall lead to legal investigation and procedural hearings. This shall also lead to permanent deactivation or barring of such Users.</p>
                <p>Any breach of security or any misrepresentation of information by third parties that receive Users Personal Information shall not be held liable to ARLabDoors unless expressed otherwise. These include but not limited to governmental involvement, hacking, unauthorized access and encryption malfunctions, etc.</p>
                <p>A User on his own is responsible for maintaining confidentiality of his account information and login details. This shall include the sole responsibility of judicially accessing his/her account and disclosure of this information to anyone. Any suspected or unauthorized use of the User’s account shall be reported to us with immediate effect. </p>
                <p>ARLabDoors shall communicate with its Users timely via email and web announcements on this Website/app or through other means of communication, emailing and web announcements remains on top. For the two say communication, Users are encouraged to contact us through email. 
                    </p>
                <h3 class="headingcolor">Changes in the privacy policy:
                </h3>
                <p>With or without any advance notice, ARLabDoors reserves the right to make any changes/updates to this Privacy Policy that shall remain applicable with immediate effect. We shall communicate these changes to all our Users, still we require each User to keep visiting this page from time to time to ensure they agree with all norms as laid under this Privacy Policy. Any User who continues to use our Services after the privacy policy has updated, remains bound to agree with our latest terms stated hereunder this policy.
                </p>
                <h3 class="headingcolor">Complaints and grievance:
                </h3>
                <p>To ensure our Users have a seamless experience and their queries/complaints are answered, ARLabDoors has designated a grievance redressal cell. We encourage our users to send their complaints using our official email Id <b>info@arlabdoor.com</b>  or by writing to our physical address at <b>Ground floor, Geetnarayn enclave, Dalubhai Patel colony, Chatrapati Sambhaji Chowk, Nashik</b></p>
                <h3 class="headingcolor">Contact us
                </h3>
                <p style="margin-bottom: 20px;">If you have any questions, related to this Privacy Policy, please contact us by submitting your request at our ‘Contact Page’.
                </p>
            </div>
        </div>
    </div>
    

</section>
<app-footer></app-footer>
