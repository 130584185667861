export class MyOrder {
    Order_Id: number
    Order_Date_Time: Date
    Payment_Mode: string
    Payment_Transaction_ID: number
    Cust_Id: number
    Cust_Name: string
    Mobile_Number: string
    Email_Id: string
    App_Type: string
    Delivery_Address_Id: number
    Full_Name: string
    Mobile_No_Address: string
    Flat_House_No: string
    Area_Colony_Sreet_Name: string
    Landmark: string
    Pincode: string
    City_Id: number
    City: string
    State: string
    Address_Type: string
    Member_Name: string
    Member_Gender: string
    Member_Age: number
    Sample_Collection_Date: Date
    Time_Slot_Id: 4
    Time_Slot_Name: string
    Coupon_Code_Name: string
    Coupon_Code_Amount: number
    Order_Detail_Id: number
    Lab_City_Test_Rate_Id: number
    Lab_City_Id: number
    Lab_Id: number
    Test_Id: number
    Test_MRP: number
    Disc_Per: number
    Disc_Amt: number
    Selling_Amt: number
    Complete_Date_Time?: Date
    Test_Status: string
    Phlebo_Id: number
    Phlebo_Name: string
    Phlebo_Contact_No: string
    Test_Name: string
    Parameter_Included: number
    Fasting_Time_In_Hours: number
    Reporting_Time_In_Hours: string
    Lab_Name: string

    //extended Property
    OrderStatusColorCode:string

    constructor() { }

}