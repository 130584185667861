<!-- Footer -->
<section class="footer-area pt-70 pb-70">
    <div class="container">
        <div class="row" *ngFor="let data of companyData">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">

                    <a routerLink="/" class="logo"><img src="assets/img/logo2.png" alt="Logo" style="width: 65px;
                            object-fit: cover;"></a>
                    <a routerLink="/" class="logo"><img src="assets/img/logo1.png" alt="Logo" style="width: 200px;
                                                object-fit: cover;"></a>
                    <p class="address" style="padding-left: 25px;"><span><i class="bx bx-sm bxs-map"></i></span>
                       Flat Noo 2, Gitanarayan Enclave, Dalubhai Patel Colony, C. Sambhaji Chowk, Nashik</p>
                       <p class="address" style="padding-left: 25px;"><span><i class="bx bx-sm bxs-map"></i></span>
                        Bhairavanath Complex, Near SP Infocity, Hadpsar, Saswad Road, Pune</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Our Departments</h3>

                    <ul>
                        <li><a routerLink="/">Pediatric clinic</a></li>
                        <li><a routerLink="/">Oral medicine</a></li>
                        <li><a routerLink="/">Neurology</a></li>
                        <li><a routerLink="/">Disability</a></li>
                        <li><a routerLink="/">Cardiology</a></li>
                        <li><a routerLink="/">Pediatrician</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Popular Links</h3>

                    <ul>
                        <li><a routerLink="/aboutus">About us</a></li>
                        <li><a routerLink="/">Emergency department</a></li>
                        <li><a routerLink="/contactus">Contact us</a></li>
                        <li><a routerLink="/">Terms and conditions</a></li>
                        <li><a routerLink="/privacypolicy">Privacy policy</a></li>
                        <li><a routerLink="/">Find a doctor</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact Us</h3>
                    <p>For 24/7 emergency please call us. If there is no emergency, contact at time.</p>
                    <ul>
                        <li>
                            <span><i class="bx bxs-phone-call"></i></span>
                            <a style="padding-left: 10px;"
                                href="tel:{{data?.Company_Contact_No1}}">+91 7058332727</a>
                        </li>
                        <li>
                            <span><i class="bx bx-mail-send"></i></span>
                            <a style="padding-left: 10px;"
                                href="mailto:{{data?.Company_Email}}">{{data?.Company_Email}}</a>
                        </li>
                        <li>
                            <span><i class="bx bx-link"></i></span>
                            <a style="padding-left: 10px;"
                                href="{{data?.Company_Website}}">{{data?.Company_Website}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Footer -->


<!-- Go Top -->
<div class="go-top">
    <i class="bx bx-chevrons-up"></i>
    <i class="bx bx-chevrons-up"></i>
</div>
<!-- End Go Top -->