import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreateCustomer } from '../entities/createcustomer';
import { CustomerProfileImage } from '../entities/customerprofile';
import { CustomerProfileResponse } from '../entities/login/customerprofileresponse';
import { GetCityResponse } from '../entities/login/getcityresponse';
import { LoginCredentialResponse } from '../entities/login/logincredentialresponse';
import { Profile } from '../entities/login/profile';

@Injectable({
    providedIn: 'root'
})

export class LoginService {

    constructor(private http: HttpClient) { }

    async getCredential(mobileNo: string): Promise<LoginCredentialResponse> {
        let parameter = JSON.parse(JSON.stringify({ "Mobile_Number": mobileNo, "Token": "ABC" }))
        return await this.http.post(environment.apiUrl + "lab_door/api/login_customer", parameter)
            .toPromise() as LoginCredentialResponse;
    }

    async createCustomer(customer: CreateCustomer): Promise<LoginCredentialResponse> {
        return await this.http.post(environment.apiUrl +  "lab_door/api/create_customer" , customer)
            .toPromise() as LoginCredentialResponse;
    }
    async getCity(): Promise<GetCityResponse> {
        return await this.http.get(environment.apiUrl + "lab_door/api/get_city")
            .toPromise() as GetCityResponse;
    }

    async updateProfileImage(profile: CustomerProfileImage): Promise<any> {
        return await this.http.post(environment.apiUrl + "lab_door/api/update_profile_image", profile)
            .toPromise() as any;
    }
    async getProfile(custId: number): Promise<CustomerProfileResponse> {
        let parameter = JSON.parse(JSON.stringify({ "Cust_Id": custId }));
        return await this.http.post(environment.apiUrl + "lab_door/api/get_customer_detail_by_id", parameter)
            .toPromise() as CustomerProfileResponse;
    }

    async updateProfile(profile:Profile): Promise<CustomerProfileResponse> {
        return await this.http.post(environment.apiUrl + "lab_door/api/update_cutomer_profile" , profile)
        .toPromise() as CustomerProfileResponse;
}
}