export class CreateCustomer {
    City_Id: number
    Cust_Name: string
    Mobile_Number: string
    Email_Id: string
    Gender: string
    Cust_Age: number
    Token: string
    App_Type: string
    Refer_By_Referral_Code: string
    

    constructor() { }

}