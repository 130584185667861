export class LoginCredential {
    Cust_Id :number
    My_Referral_Code : number
    City_Id : number
    Cust_Name : string
    Reg_Date_Time: Date
    Mobile_Number : string
    Email_Id: string
    Gender: string
    Cust_Age: number
    Profile_Image: string
    Token: string
    App_Type: string
    Refer_By_Referral_Code: string
    Status: string
    City_Name: string
    Wallet_Amount: number
    Cart_Count: number
    //Delivery_charges: number
}