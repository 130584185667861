export class AddAddress {
    Delivery_Address_Id?: number
    Cust_Id?: number
    Full_Name: string
    Mobile_No_Address: number
    Flat_House_No: string
    Area_Colony_Sreet_Name: string
    Landmark: string
    Pincode: number
    City_Id: number
    City:string
    State: string
    Address_Type: string
    Is_Default?: boolean    

    constructor() { }
}
