import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { PackageResponse } from '../entities/home/packageresponse';
import { TestSubTestResponse } from '../entities/labpackage/testsubtestresponse';

@Injectable({
  providedIn: 'root'
})

export class LabPackageService {

  constructor(private http: HttpClient) { }

  async getAllPackages(cityId: number, start: number, end: number): Promise<PackageResponse> {
    let parameter = JSON.parse(JSON.stringify({ "City_Id": cityId, "Start": start, "End": end }));
    return await this.http.post(environment.apiUrl + "lab_door/api/get_all_packages", parameter)
      .toPromise() as PackageResponse;
  }

  async getSearchPackages(searchTest: string, cityId: number, start: number, end: number): Promise<PackageResponse> {
    let parameter = JSON.parse(JSON.stringify({ "Keyword": searchTest, "City_Id": cityId, "Start": start, "End": end }));
    return await this.http.post(environment.apiUrl + "lab_door/api/search_test", parameter)
      .toPromise() as PackageResponse;
  }

  async getAllTest(cityId: number, start: number, end: number): Promise<PackageResponse> {
    let parameter = JSON.parse(JSON.stringify({ "City_Id": cityId, "Start": start, "End": end }));
    return await this.http.post(environment.apiUrl + "lab_door/api/get_all_test", parameter)
      .toPromise() as PackageResponse;
  }

  async getTestDetail(cityId: number, labId: number, testId: number): Promise<PackageResponse> {
    let parameter = JSON.parse(JSON.stringify({ "City_Id": cityId, "Lab_Id": labId, "Test_Id": testId }));
    return await this.http.post(environment.apiUrl + "lab_door/api/get_test_detail_by_ids", parameter)
      .toPromise() as PackageResponse;
  }

  async getTestAndSubTest(testId: number): Promise<TestSubTestResponse> {
    let parameter = JSON.parse(JSON.stringify({ "Test_Id": testId }));
    return await this.http.post(environment.apiUrl + "lab_door/api/get_test_and_sub_test", parameter)
      .toPromise() as TestSubTestResponse;
  }

  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}