<app-navbar></app-navbar>
<div class="page-title-area register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;">My Reports</span>
            </div>
        </div>
        
    </div>
</div>
<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 class="headingcolor">My Reports</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<section class="checkout-area">
    <div class="container">
        <div class="row">
            <div class="col-md-10 leftoffset">
            <ng-container *ngIf="reports && reports.length>0">
                <div class="packagecard card-body border-0"
                    style="border-radius:20px;box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.30); margin-top: 5px;"
                    *ngFor="let order of reports">
                    <div class="my-order-title" style="position: relative;">
                        <h3>{{order.Test_Name}}</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-12" style="font-size: 20px;color: #079ca3;">
                            {{order.Lab_Name}}
                        </div>
                        <div class="col-lg-12">
                            <span>Name : </span><span style="color:#000;font-weight: 600;">{{order.Member_Name}}</span>
                            &nbsp;&nbsp;
                            <span style="color:#000;font-weight: 600;">{{order.Member_Gender}}</span>&nbsp;&nbsp;
                            <span style="color:#000;font-weight: 600;">{{order.Member_Age}} Years</span>
                        </div>
                        <div class="col-lg-12">
                            <span>Complete Date: </span>
                            <span style="font-weight: 600;">{{order.Complete_Date_Time | date:'dd-MMM-yyyy'}}</span>
                        </div>
                        <div class="col-lg-12">
                            <p class="text-right" style="justify-content: center; align-items: center;display: flex;">
                               
                                <a href="{{order.Report_File}}" class="viewbtn"style="width:190px;padding: 10px;padding-right:20px;" target="_blank">  <i class="bx bx-sm bxs-cloud-download" style="cursor: pointer;"></i> Download Report</a>
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="!(reports && reports.length>0)" class="card-body border-0"
                style="height:395px;box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05); margin-top: 5px;display: flex;width: 100%;align-items: center;justify-content: center;">
                <h5>You will see your reports here.</h5>
            </div>
        </div>
        </div>
    </div>
</section>

<app-footer></app-footer>