export class Prescription {
    Cust_Id: number
    Member_Name: string
    Member_Gender: string
    Member_Age: number
    City_Id: number
    Prescription_Photo: string

    constructor() { }

}