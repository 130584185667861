<!-- personal deatail section start -->
<app-navbar></app-navbar>
<div class="page-title-area register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;">Profile</span>
            </div>
        </div>
        
    </div>
</div>
<!--Breadcrumb-->
<section class="contact-page  my-3">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 class="headingcolor">Profile</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<section class="contact-page register-page" style="min-height: 420px;">
    <div class="container">
        <div class="row ml-0 mr-0 checkout-area">
            <div class="cart-totals leftoffset col-lg-10" style="box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.30);">
                <h3 class="headingcolor">Personal Detail</h3>
                <form class="theme-form" style="padding: 10px;" #updateProfile="ngForm">
                    <div class="form-row">
                        <div class="col-md-3 col-sm-12 col-lg-3 col-xs-12">
                            <img class="profilepic" *ngIf="customerdetail?.Profile_Image;else base64Image"
                                [src]="customerdetail?.Profile_Image">
                            <ng-template #base64Image>
                                <img class="profilepic" *ngIf="profileBase;else defaultProfileImage"
                                    src="{{profileBase}}" />
                                <ng-template #defaultProfileImage>
                                    <img class="profilepic" src="../../../assets/img/userprofile.png" />
                                </ng-template>
                            </ng-template>
                            <input type="file" (change)="selectedImage($event)" accept="image/*" name="Image"
                                id="myfile" class="uploadprofilepic">
                            <input type="hidden" name="imageType" />
                        </div>
                        <div class="col-md-9 col-sm-12 col-lg-9 col-xs-12">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label for="name" style="margin-bottom: 0;">Name</label>
                                    <input type="text" class="form-control mb-2" id="name" name="name"
                                        autocomplete="off" [(ngModel)]="customerdetail.Cust_Name">

                                </div>
                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label for="review" style="margin-bottom: 0;">Phone number</label>
                                    <input type="text" class="form-control mb-2" id="review" name="mobileNo"
                                        [(ngModel)]="customerdetail.Mobile_Number" disabled>

                                </div>
                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label for="email" style="margin-bottom: 0;">Email</label>
                                    <input type="text" class="form-control mb-2" id="email" name="email"
                                        [(ngModel)]="customerdetail.Email_Id"
                                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required
                                        #Email="ngModel">
                                    <div class="text text-danger" *ngIf="Email.touched && Email.hasError('pattern')">
                                        Enter valid email address!
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label style="margin-bottom: 0;margin-top: 10px;">Gender</label>
                                    <div class="" >
                                        <input style="margin-bottom: 10px;margin-top: 10px;" [checked]="customerdetail.Gender==='Male'"
                                            type="radio" name="gender" id="one" (change)="changeGender('Male')">
                                        <label for="one" style="margin-right: 10px;">&nbsp;&nbsp;Male</label>
                                        <input type="radio" name="gender" id="two"
                                            [checked]="customerdetail.Gender==='Female'"
                                            (change)="changeGender('Female')">
                                        <label for="two">&nbsp;&nbsp;Female</label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <label for="ageGroup" style="margin-bottom: 0;">Age</label>
                                    <input type="text" class="form-control mb-2" id="review" name="Age"
                                        [(ngModel)]="customerdetail.Cust_Age">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12" style="text-align: center;">
                            <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
                                <button type="submit" [disabled]="!(updateProfile.valid)"
                                    (click)="profileupdate(updateProfile)" class="default-btn viewbtn">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</section>


<section class="" style="max-height: calc(100vh - 310px);overflow: auto;">
    <div class="container">
        <div class="row ml-0 mr-0 checkout-area">
            <div class="mt-3 cart-totals leftoffset col-lg-10" style="box-shadow: 0 0 10px 3px rgb(0, 0, 0, 0.30);">
                <h3 class="d-inline">Family Member</h3>
                <div class="float-right d-inline banner-btn wow fadeInUp" data-wow-delay="1s">
                    <button type="submit" (click)="addNewMember()" style="width: 200px;" class="default-btn viewbtn">
                        Add New Member
                    </button>
                </div>
                <ng-container *ngIf="familyMember && familyMember.length>0;else NoData">
                    <div *ngFor="let family of familyMember" class="packagecard card-body border-0"
                        style="box-shadow:0 0 10px 3px rgba(0, 0, 0, 0.30);margin-bottom: 0;margin-top: 30px;">

                        <h5 class="d-inline" style="color: #079ca3;">{{family.Member_Name | titlecase}}</h5>


                        <button (click)="deleteDialog(family)" class="default-btn viewbtn deletebtn  mr-2"
                            style="width:40px;cursor: pointer;float: right;padding: 9px 10px;">
                            <i class="bx bx-trash">
                            </i>
                        </button>

                        <button (click)="isaddmemberform = true;bindEditData(family)" class="default-btn viewbtn mr-2"
                            style="width:40px;cursor: pointer;float: right;padding: 9px 10px;">
                            <i class="bx bx-edit">
                            </i>
                        </button>
                        <h6>{{family.Member_Gender | titlecase}} | {{family.Member_Age}} years</h6>
                    </div>
                </ng-container>
                <ng-template #NoData>
                    <div style="height: 125px;display: flex;width: 100%;align-items: center;justify-content: center;">
                        <h5>Add family member here.</h5>
                    </div>
                </ng-template>
            </div>
        </div>

    </div>
</section>
<ng-container *ngIf="isOpenMemberDialog">
    <div
        style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
        <div class="modal-media" style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;padding: 20px;"
            role="dialog">
            <div class="inner-border modal-content m-0">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="closeAddMemberDialog()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="max-height: calc(100vh - 240px);overflow: auto;overflow-x: hidden;">
                    <div class=" mt-3 row">
                        <div class="col-lg-12">
                            <div *ngIf="isaddmemberform" class="container">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                <form class="theme-form" #AddMemberForm="ngForm" style="padding: 10px;">
                                    <label for="Name">Name</label>
                                    <div class=" form-group">
                                        <input type="text" class=" form-control" name="name" #name="ngModel" required
                                            pattern="[a-zA-Z ]*" placeholder="Enter Name"
                                            [(ngModel)]="selectedMember.Member_Name">
                                        <span class="text-danger"
                                            *ngIf="(name.touched || AddMemberForm.submitted) && name.errors?.required">
                                            Name is required!!
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(name.touched || AddMemberForm.submitted) && name.errors?.pattern">
                                            Name only allow a-z A-Z ,-,_
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <label>Gender</label>
                                        <div class="form-control">
                                            <label class="radio-inline mr-3">
                                                <input type="radio" name="gender" value="male"
                                                    [(ngModel)]="selectedMember.Member_Gender" required
                                                    #gender="ngModel">
                                                Male
                                            </label>
                                            <label class="radio-inline">
                                                <input type="radio" name="gender" value="female"
                                                    [(ngModel)]="selectedMember.Member_Gender" required>
                                                Female
                                            </label>
                                            <span class="text-danger"
                                                *ngIf="(gender.touched || AddMemberForm.submitted) && gender.errors?.required">
                                                Gender is required!!
                                            </span>
                                        </div>
                                    </div>
                                    <label for="Age">Age</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" [(ngModel)]="selectedMember.Member_Age"
                                            id="Age" name="age" placeholder="Enter Age" required #Age="ngModel">
                                        <span class="text text-danger"
                                            *ngIf="Age.invalid && (Age.dirty || Age.touched)">
                                            Age is required!!
                                        </span>
                                    </div>
                                    <label for="Relation">Relation</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" required #Relation="ngModel"
                                            [(ngModel)]="selectedMember.Member_Relation" name="relation"
                                            placeholder="Enter Relation" />
                                        <span class="text text-danger"
                                            *ngIf="Relation.invalid && (Relation.dirty || Relation.touched)">
                                            Relation is required!!
                                        </span>
                                    </div>
                                    <div class="text-center mt-5">
                                        <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
                                            <button type="submit" [disabled]="!(AddMemberForm.valid)"
                                                (click)="addMember(AddMemberForm)" class="default-btn viewbtn">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isDeleteMemeber">
    <div
        style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
        <div style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
            role="dialog">
            <div class=" modal-content resmodal">
                <div class="modal-body modal13" style="max-height: calc(100vh - 240px);overflow: auto;overflow-x: hidden;">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="modal-bg checkout-page">
                                    <div class="container" style="padding: 16px;text-align: center;">
                                        <div class="row">
                                            <div class="col-md-6">
                                        <h2 class="headingcolor">{{mainTitle}}</h2>
                                        <p>{{deleteMsg}}</p>

                                        <div class="mt-3 clearfix">
                                            <button type="button" class="default-btn viewbtn" style="
                                            background-color: darkgray;
                                            color: #fff;
                                                border-color: #079ca3;
                                            margin-right: 10px;" (click)="closeDeleteMemeberDialog()">No</button>
                                            <button type="button"
                                                (click)="deletemember(deletefamilymem.Cust_Family_Member_Id)"
                                                class="default-btn viewbtn">Yes</button>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>
<app-footer></app-footer>
<!-- Section ends -->