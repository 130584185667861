import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MycartResponse } from '../entities/cart/mycartresponse';
import { AddCart } from '../entities/cart/addcart';
import { AddMember } from '../entities/cart/addmember';
import { AddMemberResponse } from '../entities/cart/addmemberresponse';
import { FamilyMemberResponse } from '../entities/cart/familymemberresponse';
import { ResponseWithoutData } from '../entities/responsewithoutdata';
@Injectable({  
    providedIn: 'root'
})

export class MyCartService {
    constructor(private http: HttpClient) { }

    async getmycarttest(custId: number): Promise<MycartResponse> {
        let parameter = JSON.parse(JSON.stringify({"Cust_Id":custId }))
        return await this.http.post(environment.apiUrl + "lab_door/api/get_my_cart_test",parameter)
            .toPromise() as MycartResponse;
    }

    async addmycart( addcart: AddCart ): Promise<ResponseWithoutData> {          
        return await this.http.post(environment.apiUrl + "lab_door/api/add_test_to_cart", addcart)
          .toPromise() as ResponseWithoutData;
      }

      async removecart(My_Cart_Id: number): Promise<ResponseWithoutData> {      
        let parameter = JSON.parse(JSON.stringify({ "My_Cart_Id": My_Cart_Id }))
        return await this.http.post(environment.apiUrl + "lab_door/api/remove_test_from_my_cart", parameter)
          .toPromise() as ResponseWithoutData;
      }

      async clearCart(custId: number): Promise<ResponseWithoutData> {      
        let parameter = JSON.parse(JSON.stringify({ "Cust_Id": custId }))
        return await this.http.post(environment.apiUrl + "lab_door/api/clear_my_cart", parameter)
          .toPromise() as ResponseWithoutData;
      }

      async addmemeber( addmemeber: AddMember ):Promise<AddMemberResponse> {
          return await  this.http.post(environment.apiUrl + "lab_door/api/add_my_family_member",addmemeber)
          .toPromise() as AddMemberResponse;
      }
      async removemember( custfamilymemberId: number ):Promise<ResponseWithoutData> {
        let parameter = JSON.parse(JSON.stringify({ "Cust_Family_Member_Id": custfamilymemberId  }))
        return await  this.http.post(environment.apiUrl + "lab_door/api/delete_my_family_member",parameter)
        .toPromise() as ResponseWithoutData;
    }


      async getmyfamilymember( custId: number ): Promise<any> {
        let parameter = JSON.parse(JSON.stringify({ "Cust_Id": custId }))
        return await this.http.post(environment.apiUrl + "lab_door/api/get_my_family_member", parameter)
        .toPromise() as any;
    }

    async updatefamilymember( addmemeber: AddMember ): Promise<FamilyMemberResponse> {       
        return await this.http.post(environment.apiUrl + "lab_door/api/update_my_family_member", addmemeber)
        .toPromise() as FamilyMemberResponse;
    }
    // async clearcart(custId: number): Promise<any> {      
    //     let parameter = JSON.parse(JSON.stringify({ "My_Cart_Id": custId }))
    //     return await this.http.post(environment.apiUrl + "lab_door/api/remove_test_from_my_cart", parameter)
    //       .toPromise() as any;
    //   }
}