import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../src/environments/environment";
import { SliderResponse } from '../entities/home/sliderresponse';
import { PackageResponse } from '../entities/home/packageresponse';
import { TestCategoryResponse } from '../entities/home/testcategoryresponse';
import { LabHomeResponse } from '../entities/home/labhomeresponse';
import { CompanyDataResponse } from '../entities/home/companydataresponse';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  async getHomeSlider(): Promise<SliderResponse> {
    let parameter = JSON.parse(JSON.stringify({ "Use_In": "Web" }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_slider_images", parameter)
      .toPromise() as SliderResponse;
  }

  async getRecommendPackageForHome(cityId: number, noOfTest: number): Promise<PackageResponse> {
    let parameter = JSON.parse(JSON.stringify({ "City_Id": cityId, "No_Of_Test": noOfTest }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_recommended_packages_for_home_page", parameter)
      .toPromise() as PackageResponse;
  }

  async getTestCategoriesForHome(): Promise<TestCategoryResponse> {
    let parameter = JSON.parse(JSON.stringify({ "Type": "Home" }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_test_categories_for_home_page", parameter)
      .toPromise() as TestCategoryResponse;
  }

  async getLabsForHome(cityId: number): Promise<LabHomeResponse> {
    let parameter = JSON.parse(JSON.stringify({ "City_Id": cityId }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_labs_for_home_page", parameter)
      .toPromise() as LabHomeResponse;
  }

  async getPopularTestForHome(cityId: number, noOfTest: number): Promise<PackageResponse> {
    let parameter = JSON.parse(JSON.stringify({ "City_Id": cityId, "No_Of_Test": noOfTest }))
    return await this.http.post(environment.apiUrl + "lab_door/api/get_populer_test_for_home_page", parameter)
      .toPromise() as PackageResponse;
  }

  async getcompanydata(): Promise<CompanyDataResponse> {
    return await this.http.get(environment.apiUrl + "lab_door/api/get_company_data")
      .toPromise() as CompanyDataResponse;
  }

}
