export class MyCart {
    Test_Id: number
    Test_Name: string
    Short_Description: string
    About_Test: string
    Parameter_Included: number
    Rating: number
    Free_Sample_Collection: number
    Free_Doctor_Consultation: number
    Reporting_Time_In_Hours: number
    Fasting_Time_In_Hours: number
    Recommended_For_Age: string
    Test_Recommended_For: string
    Highlighted_Test_1_Web: string
    Highlighted_Test_2_Web: string
    Highlighted_Test_3_Web: string
    Highlighted_Test_1_Mobile: string
    Highlighted_Test_2_Mobile: string
    Highlighted_Test_3_Mobile: string
    Lab_City_Test_Rate_Id: number
    Lab_City_Id: number
    City_Id: number
    Test_MRP: number
    Disc_Per: number
    Disc_Amt: number
    Selling_Amt: number
    Lab_Id: number
    Lab_Name: string
    My_Cart_Id: number
    Qty: number
}