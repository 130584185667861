<app-navbar></app-navbar>
<div class="page-title-area register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;">Checkout</span>
            </div>
        </div>
        
    </div>
</div>
<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 class="headingcolor">Checkout</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<section class="checkout-area" style="padding-bottom: 10x;">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12 pt-0">
                <div class=" mb-3 row">
                    <div class="col-lg-12">
                        <ng-container *ngIf="isViewForm">
                            <div class="billing-details" style="box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.20);border-radius: 10px;">
                                <h3 class="title headingcolor">Sample Collection Details</h3>
                                <form name="AddAddressForm" #AddAddressForm="ngForm">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <input type="text" [(ngModel)]="selectedAddress.Full_Name" name="Name"
                                                    class="form-control" #name="ngModel" required
                                                    pattern="[a-zA-Z ]*" />
                                                <span class="text-danger"
                                                    *ngIf="(name.touched || AddAddressForm.submitted) && name.errors?.required">
                                                    Name is required!!
                                                </span>
                                                <span class="text-danger"
                                                    *ngIf="(name.touched || AddAddressForm.submitted) && name.errors?.pattern">
                                                    Name only allow a-z A-Z ,-,_
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Mobile Number</label>
                                                <input type="text" appMobilevalidation="true" class="form-control" name="Mobile" #Mobile="ngModel"
                                                    required [(ngModel)]="selectedAddress.Mobile_No_Address" />
                                                <span class="text-danger"
                                                    *ngIf="(Mobile.touched || AddAddressForm.submitted) && Mobile.errors?.required">
                                                    MobileNumber is required!!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>House No.</label>
                                                <input type="text" class="form-control" name="houseno" required
                                                    [(ngModel)]="selectedAddress.Flat_House_No" #houseno="ngModel" />
                                                <span class="text-danger"
                                                    *ngIf="(houseno.touched || AddAddressForm.submitted) && houseno.errors?.required">
                                                    HouseNo is required!!
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Area</label>
                                                <input type="text" class="form-control" required name="area"
                                                    [(ngModel)]="selectedAddress.Area_Colony_Sreet_Name"
                                                    #Area="ngModel" />
                                                <span class="text-danger"
                                                    *ngIf="(Area.touched || AddAddressForm.submitted) && Area.errors?.required">
                                                    Area is required!!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Landmark</label>
                                                <input type="text" class="form-control" name="landmark" required
                                                    [(ngModel)]="selectedAddress.Landmark" #LandMark="ngModel" />
                                                <span class="text-danger"
                                                    *ngIf="(LandMark.touched || AddAddressForm.submitted) && LandMark.errors?.required">
                                                    LandMark is required!!
                                                </span>
                                            </div>

                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Pincode</label>
                                                <input type="text" class="form-control" name="pincode"
                                                    #Pincode="ngModel" [(ngModel)]="selectedAddress.Pincode" required />
                                                <span class="text-danger"
                                                    *ngIf="(Pincode.touched || AddAddressForm.submitted) && Pincode.errors?.required">
                                                    Pincode is required!!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>City</label>
                                                <select [(ngModel)]="selectedCity" class="form-control select-text"
                                                    name="addresscity" required #City="ngModel">
                                                    <option class="dropdown-item" disabled value="undefined">Select City
                                                    </option>
                                                    <option class="dropdown-item" *ngFor="let ct of cities"
                                                        [ngValue]="ct">
                                                        {{ct.City_Name}}</option>
                                                </select>
                                                <span class="text-danger"
                                                    *ngIf="(City.touched || AddAddressForm.submitted) && City.errors?.required">
                                                    City is required!!
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>State</label>
                                                <input type="text" class="form-control" name="state" #State="ngModel"
                                                    [(ngModel)]="selectedAddress.State" required />
                                                <span class="text-danger"
                                                    *ngIf="(State.touched || AddAddressForm.submitted) && State.errors?.required">
                                                    State is required!!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <div class="form-group">
                                            <div class="form-control">
                                                <label class="radio-inline mr-3 d-inline">
                                                    <input type="radio" name="addresstype" value="home"
                                                        [(ngModel)]="selectedAddress.Address_Type" checked>
                                                    Home
                                                </label>
                                                <label class="radio-inline d-inline">
                                                    <input type="radio" name="addresstype" value="Hospital"
                                                        [(ngModel)]="selectedAddress.Address_Type">
                                                    Hospital
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3 text-center form-group">
                                        <div class="col-lg-12 mt-5 text-center banner-btn wow fadeInUp d-inline"
                                            data-wow-delay="1s">
                                            <button (click)="saveAddress(AddAddressForm)"
                                                [disabled]="!(AddAddressForm.valid && selectedAddress.Address_Type)"
                                                class="default-btn viewbtn" style="cursor:pointer">Save</button>
                                        </div>
                                        <div class="col-lg-12 mt-5 text-center banner-btn wow fadeInUp d-inline"
                                            data-wow-delay="1s">
                                            <button (click)="cancelAddress()" class="default-btn viewbtn"
                                                style="cursor:pointer">Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!isViewForm">
                            <div class="row mx-0 rounded py-2" style="background-color:#e0dfdd;border
                            :1px solid #c1c0be; color: #414040;">
                                <div class="col-lg-10" style="margin-top: 4px;">
                                    <h5 class="d-inline p-2" style="font-size: 15px;">Sample Collect From</h5>
                                </div>
                                <div class="col-lg-2 text-right">
                                    <h3 class=""
                                        style="font-size: larger;cursor: pointer;margin-top: 4px;margin-bottom: 0px;"
                                        (click)="addAddress()">+</h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div *ngFor="let addr of deliveryAddresses">
                                        <div class="card mt-3" style="box-shadow: 0 0 10px 3px rgb(0,0,0,0.30%);"
                                            [ngClass]="{'selectAdd':addr.Is_Default,'remove-border':!addr.Is_Default}">
                                            <div class="card-body">
                                                <!-- <i class="d-inline mt-1 float-right bx bx-edit"
                                                    style="font-size: larger;cursor: pointer;"
                                                    (click)="bindEditData(addr)" data-toggle="modal"
                                                    data-target="#exampleModal"></i> -->
                                                <i class="d-inline mt-1 float-right bx bx-check"
                                                    *ngIf="addr.Delivery_Address_Id == selectedAddress.Delivery_Address_Id">
                                                    Selected
                                                </i>

                                                <h6 class="mt-2">{{addr.Full_Name}}</h6>
                                                <h6 class="mt-2"><b>Contact: </b>{{addr.Mobile_No_Address}}</h6>
                                                <h6 class="mt-2" *ngIf="addr.Address_Type">{{addr.Address_Type | titlecase}}
                                                    Address: </h6>
                                                <h6 class="text-muted">
                                                    {{addr.Flat_House_No}}, {{addr.Area_Colony_Sreet_Name}}, {{addr.Landmark}}, {{addr.City}}, {{addr.State}}-{{addr.Pincode}}
                                                </h6>
                                                <button (click)="bindEditData(addr)" class=" p-2 default-btn viewbtn"
                                                    style="cursor:pointer;float:right;">
                                                    Edit
                                                </button>
                                                <!-- <button (click)="setAddress(addr)" class=" p-2 default-btn mr-1"
                                                    style="cursor:pointer;float:right;">
                                                    Deliver Here
                                                </button> -->
                                                <button *ngIf="!(addr.Is_Default)" (click)="defaultAddress(addr)"
                                                    class="p-2 default-btn mr-1" style="cursor:pointer;float:right;">
                                                    Collect Sample From This Address
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="order-details">
                    <div class="cart-totals">
                        <h3>Checkout Summary</h3>
                        <h5 class="headingcolor">{{memberName | titlecase}}</h5>
                        <h6>{{memberGender | titlecase}} | {{memberAge}} years</h6>
                        <ul class="notype">
                            <li style="padding-bottom: 10px;margin-bottom: 10px;">Value of Tests<span>{{totalMrp |
                                    currency:'INR':'symbol'}}</span></li>
                            <li style="padding-bottom: 10px;margin-bottom: 10px;color:#e68d1d;">
                                Discount(-)<span>{{totalDiscount |
                                    currency:'INR':'symbol'}}</span></li>
                                    <li style="padding-bottom: 10px;margin-bottom: 10px;">Sub Total<span>{{subTotal |
                                    currency:'INR':'symbol'}}</span></li>
                            <li style="padding-bottom: 10px;margin-bottom: 10px;color:#5ccc11;"
                                *ngIf="walletCouponDiscAmt>0">
                                Wallet/Coupon(-)
                                <span>{{walletCouponDiscAmt | currency:'INR':'symbol'}}</span>
                            </li>
                            
                            <li style="padding-bottom: 10px;margin-bottom: 10px;">Final Amount<span>{{finalTotal |
                                    currency:'INR':'symbol'}}</span></li>
                            <hr>
                            <li style="padding-bottom: 10px;margin-bottom: 10px;">Coupon
                                <span class="count" style="width: 65% !important;">
                                    <input class="form-control"
                                        style="height: 25px; width:75%!important;padding:0 2px;text-transform: uppercase;font-size:16px;display: inline;"
                                        type="text" name="couponcode" placeholder="" autocomplete="off"
                                        [(ngModel)]="couponCode" [disabled]="isCouponDisc">
                                    <a href="javascript:void(0)" (click)="checkCouponCode()" *ngIf="!(isCouponDisc)"
                                        style="margin-left:5px;margin-right: 5px;color:#5ccc11;">Apply</a>
                                    <a href="javascript:void(0)" (click)="removeCouponCode()" *ngIf="isCouponDisc"
                                        style="color:#f74747;margin-left:5px;margin-right: 5px;">Remove</a>
                                </span>
                            </li>
                            <li style="padding-bottom: 10px;margin-bottom: 10px;" *ngIf="walletAmount>0">
                                <i class="bx bx-wallet"></i> &nbsp;{{walletAmount | currency:'INR':'symbol'}}
                                <span>
                                    <a href="javascript:void(0)" (click)="applyWallet()" *ngIf="!(isWalletDisc)"
                                        style="margin-left:5px;margin-right: 5px;color:#5ccc11;">Redeem</a>
                                    <a href="javascript:void(0)" (click)="removeWallet()" *ngIf="isWalletDisc"
                                        style="color:#f74747; padding-left: 5px;">Remove</a>
                                </span>
                            </li>
                        </ul>
                        <div class="mt-3">
                            <h3 class="text-left mb-3">Sample Collection time slot</h3>
                            <form>
                                <div class="row">
                                    <div class="col">
                                        <input type="date" class="form-control border-bottom" id="datemax"
                                            name="datemax" min="{{minDate}}" [(ngModel)]="scDate">
                                    </div>
                                    <div class="col">
                                        <select [(ngModel)]="selectedtimeslot" class="form-control select-text"
                                            name="timeslot" required #Time="ngModel" style="color:black">
                                            <option class="dropdown-item" disabled value="undefined">Select Time Slot
                                            </option>
                                            <option class="dropdown-item" *ngFor="let time of timeslot"
                                                [ngValue]="time">
                                                {{time.Time_Slot_Name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </form>

                            <h3 class="text-left mb-3 mt-3">Payments</h3>
                            <input style="margin-bottom: 10px;" type="radio" name="gender" id="one"
                                [checked]="paymentType==='COD'" (change)="changePaymentType('COD')">
                            <label for="one">&nbsp;&nbsp; COD(Cash On Delivery)</label>
                            <br>
                            <input style="margin-bottom: 10px;" type="radio" name="gender" id="two"
                                [checked]="paymentType==='Online'" (change)="changePaymentType('Online')">
                            <label for="two">&nbsp;&nbsp;Razor Pay</label>
                        </div>

                        <div class="mt-2" style="color: #ff0000;font-size: 13px;"
                            *ngIf="(deliveryAddresses && deliveryAddresses.length>0 && !(cred.City_Id == selectedAddress.City_Id))">
                            Your city is different than your selected address city!!
                        </div>
                        <div class="mt-2" style="color: #ff0000;font-size: 13px;" *ngIf="!(selectedtimeslot && scDate)">
                            Please select Date and Time slot for sample collection!!
                        </div>
                        <div class="mt-2">
                            <button
                                [disabled]="!(selectedAddress && selectedAddress.Delivery_Address_Id && scDate && selectedtimeslot && selectedtimeslot.Time_Slot_Name && memberName && memberAge && memberGender && cred.City_Id == selectedAddress.City_Id)"
                                (click)="checkPayOrderType()"  type="submit" class="default-btn viewbtn" style="width:130px;">
                                Place Order
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>