<app-navbar></app-navbar>
<!-- Page Title -->
<div class="page-title-area register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <a routerLink="/packages" style="display:inline-block;">Packages</a>
                / <span style="color: #ef8017;"> {{testDetail.Test_Name}}</span>
            </div>
        </div>
        
    </div>
</div>
<!-- End Page Title -->
<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 class="headingcolor">Package Detail</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<div class="col-lg-12 container">
    <div class="card border-0" >
        <div class="card-body text-muted" style="box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.30);margin: 15px;padding: 0;">
            <div class="card-header" style="background-color: #d47a25;border-bottom:none;">
                <div class="row package-detail-title" style="position: relative;color: #fff;padding: 10px;">
                    <div class="col-lg-7">
                        <h4 style="color: #fff;">{{testDetail.Test_Name}}</h4>
                        <h3 style="color: #fff;">*{{testDetail.Lab_Name}}</h3>
                    </div>
                    <div class="col-lg-5 text-right">
                        <span style="font-weight: 600;font-size: 20px;">
                            {{testDetail.Selling_Amt | currency:'INR':'symbol'}}
                        </span>&nbsp;
                        <span *ngIf="testDetail.Disc_Per>0" style="color: #aaa;font-size: 20px;">
                            <del>{{testDetail.Test_MRP | currency:'INR':'symbol'}}</del>
                        </span>&nbsp;
                        <div>
                            <span *ngIf="testDetail.Disc_Per>0" style="font-size: 20px;">
                                {{testDetail.Disc_Per | number:'0.0-0'}}% OFF
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="padding: 20px;">
                <div class="leftoffset col-lg-10">

                    <div *ngIf="testDetail.Why_get_tested" class="text-center">
                        <h5 class="mt-2" style="color:#079ca3;">Why Get Tested?</h5>
                        <p class="">{{testDetail.Why_get_tested}}</p>
                    </div>
                    <div *ngIf="testDetail.When_get_tested" class="text-center">
                        <h5 class="mt-3" style="color:#079ca3;">When To Get Tested?</h5>
                        <p class="">{{testDetail.When_get_tested}}</p>
                    </div>
                </div>
            </div>
            <div class="row" style="padding: 20px;">
                <div class="col-lg-3">
                    <div class="card p-2 border-0" style="box-shadow:0 0 10px 3px rgba(0, 0, 0, 0.30);">
                        <div class="card-body text-center p-1">
                            <h4 class="text-muted">Report Time</h4>
                            <h6 *ngIf="testDetail.Reporting_Time_In_Hours;else defaultReportTime">
                                {{testDetail.Reporting_Time_In_Hours}} Hours
                            </h6>
                            <ng-template #defaultReportTime>
                                <h6>Not Applicable</h6>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card p-2 border-0" style="box-shadow:0 0 10px 3px rgba(0, 0, 0, 0.30);">
                        <div class="card-body text-center p-1">
                            <h4 class="text-muted">Recommended For</h4>
                            <h6 *ngIf="testDetail.Test_Recommended_For">
                                {{testDetail.Test_Recommended_For}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card p-2 border-0" style="box-shadow:0 0 10px 3px rgba(0, 0, 0, 0.30);height:87px;">
                        <div class="card-body  text-center p-1">
                            <h4 class="text-muted">Recommended Age</h4>
                            <h6 *ngIf="testDetail.Recommended_For_Age">{{testDetail.Recommended_For_Age}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card p-2  border-0" style="box-shadow:0 0 10px 3px rgba(0, 0, 0, 0.30);">
                        <div class="card-body text-center p-1">
                            <h4 class="text-muted">Fasting Time</h4>
                            <h6 *ngIf="testDetail.Fasting_Time_In_Hours;else defaultFastTime">
                                {{testDetail.Fasting_Time_In_Hours}} Hours</h6>
                            <ng-template #defaultFastTime>
                                <h6>Not Applicable</h6>
                            </ng-template>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-12 mt-3 mb-3 text-center banner-btn wow fadeInUp" data-wow-delay="1s">
                <a (click)="checkLogin()" class="default-btn viewbtn" style="cursor:pointer">Book Now</a>
            </div>
        </div>
        <div class="col-lg-12 mb-5">
            <div class="card " style="margin: 0 4px;border: 0px;box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.30);">
                <h3 class="pb-3 p-3 headingcolor"><b>Parameter Included : {{testDetail.Parameter_Included}}</b></h3>
                <div class="card-body" style="padding:0;">
                    <div class="row" style="margin: 0px;background:linear-gradient(to left, #14d497 0%, #2b9f96 50%, #31c8be 100%);"
                        *ngIf="testSubTests && testSubTests.length>0">
                        <div class="col-lg-5">
                            <h5 class="text-light p-1" style="margin-top:8px">Profile/Parameter</h5>
                        </div>
                        <div class="col-lg-7">
                            <h5 class="p-1 text-light" style="margin-top:8px">No. of Parameters</h5>
                        </div>
                    </div>
                   
                    <div class="mb-4" *ngIf="testSubTests && testSubTests.length>0">
                        <div *ngFor="let subTest of testSubTests">
                            <div class="headingtop p-1 row mx-1 mt-3" style="background-color: #ebebeb;color:rgb(49, 49, 49); margin-left: 50px !important;
                                margin-right:50px !important;"
                                (click)="collapse.toggle();subTest.isCollapsed=!subTest.isCollapsed"
                                [attr.aria-expanded]="subTest.isCollapsed">
                                <div class="col-lg-6" style="margin:auto">
                                    <b>{{subTest.Test_Name}}</b>
                                </div>

                                <div class="col-lg-5" style="margin:auto">
                                    <p class="d-inline">{{subTest.Parameter_Included}}</p>
                                </div>
                                <div class="col-lg-1">
                                    <h2 class="d-inline">
                                        <i *ngIf="!subTest.isCollapsed"
                                            class="float-right bx bx-chevron-down"
                                            aria-controls="collapseExample"></i>
                                        <i *ngIf="subTest.isCollapsed" class="float-right bx bx-chevron-up"
                                            aria-controls="collapseExample"></i>
                                    </h2>
                                </div>
                            </div>
                            <div #collapse="ngbCollapse" [(ngbCollapse)]="!subTest.isCollapsed">
                                <div class="card card1"
                                    style="border:1px solid #e4e5e7;box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 20px 3px;background-color: #fff;cursor: pointer;margin-left:50px !important; margin-right:50px !important;">
                                    <div class="card-body p-0 row highlight-test" style="padding: 10px !important;">
                                        <p class="mb-0 col-lg-3 col-md-4 col-sm-2 col-xs-1"
                                            *ngFor="let childTest of subTest.ChildTest">
                                            {{childTest.Test_Name}}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>