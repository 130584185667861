import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../../service/home.service';
import { customOptions, PackageSliderConfig } from 'src/app/shared/slider';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SliderResponse } from 'src/app/entities/home/sliderresponse';
import { Slider } from 'src/app/entities/home/slider';
import { PackageResponse } from 'src/app/entities/home/packageresponse';
import { Package } from 'src/app/entities/home/package';
import { TestCategory } from 'src/app/entities/home/testcategory';
import { TestCategoryResponse } from 'src/app/entities/home/testcategoryresponse';
import { LabHome } from 'src/app/entities/home/labhome';
import { LabHomeResponse } from 'src/app/entities/home/labhomeresponse';
import { LoginCredential } from 'src/app/entities/login/logincredential';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  public customSliderConfig: any = customOptions;
  public packageSliderConfig: any = PackageSliderConfig;

  public sliders: Slider[];
  public recommendPackages: Package[];
  public testCategories: TestCategory[];
  public labs: LabHome[];
  public labTests: Package[];

  private cred: LoginCredential;
  public cityBeforeLogin: { City_Id: number, City_Name: string } = { City_Id: 1, City_Name: "Nashik" };

  public searchTest: string;

  constructor(
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private homeService: HomeService
  ) { }

  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    let data = JSON.parse(sessionStorage.getItem("CityBeforeLogin")) as any;
    if (data)
      this.cityBeforeLogin = JSON.parse(sessionStorage.getItem("CityBeforeLogin")) as any;

    this.spinnerService.show();
    await this.getHomeSliders();
    await this.getRecommendPackage();
    await this.getTestCategory();
    await this.getLabs();
    await this.getPopularTest();
    this.spinnerService.hide();
  }

  private async getHomeSliders() {
    try {
      //this.spinnerService.show();
      this.sliders = new Array<Slider>();
      let res: SliderResponse = await this.homeService.getHomeSlider();
      if (res && res.res_code == 1)
        this.sliders = res.data;
      //this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  private async getRecommendPackage() {
    try {
      //this.spinnerService.show();
      let cityId = 1;
      if (this.cred && this.cred.City_Id)
        cityId = this.cred.City_Id;
      else if (this.cityBeforeLogin.City_Id)
        cityId = this.cityBeforeLogin.City_Id;

      let res: PackageResponse = await this.homeService.getRecommendPackageForHome(cityId, 10);
      if (res && res.res_code == 1)
        this.recommendPackages = res.data;
      //this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  private async getTestCategory() {
    try {
      //this.spinnerService.show();
      let res: TestCategoryResponse = await this.homeService.getTestCategoriesForHome();
      if (res && res.res_code == 1)
        this.testCategories = res.data;
      //this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  private async getLabs() {
    try {
      //this.spinnerService.show();
      let cityId = 1;
      if (this.cred && this.cred.City_Id)
        cityId = this.cred.City_Id;
      else if (this.cityBeforeLogin.City_Id)
        cityId = this.cityBeforeLogin.City_Id;
      let res: LabHomeResponse = await this.homeService.getLabsForHome(cityId);
      if (res && res.res_code == 1)
        this.labs = res.data;

      //this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  private async getPopularTest() {
    try {
      this.spinnerService.show();
      let cityId = 1;
      if (this.cred && this.cred.City_Id)
        cityId = this.cred.City_Id;
      else if (this.cityBeforeLogin.City_Id)
        cityId = this.cityBeforeLogin.City_Id;
      let res: PackageResponse = await this.homeService.getPopularTestForHome(cityId, 10);
      if (res && res.res_code == 1)
        this.labTests = res.data;
      //this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }
  public redirectLabDetail(labId: number) {
    let cityId = 1;
    if (this.cred && this.cred.City_Id)
      cityId = this.cred.City_Id;
    else if (this.cityBeforeLogin.City_Id)
      cityId = this.cityBeforeLogin.City_Id;
    this.router.navigate(['labdetail', cityId, labId])
  }
  public redirectPackageDetail(labId: number, testId: number) {
    this.router.navigate(['packagedetail', labId, testId]);
  }

  public async redirectCategoryWiseTest(testCategoryName: string, testCategoryId: number) {
    let cityId = 1;
    if (this.cred && this.cred.City_Id)
      cityId = this.cred.City_Id;
    else if (this.cityBeforeLogin.City_Id)
      cityId = this.cityBeforeLogin.City_Id;
    this.router.navigate(['categorywisetest', testCategoryName, testCategoryId, cityId]);
  }

  public redirectAllPackages() {
    this.router.navigate(['packages']);
  }

  public redirectAllCategories() {
    this.router.navigate(['categories']);
  }

  public redirectAllTest() {
    this.router.navigate(['populartest']);
  }

  public searchByTestName(){
    sessionStorage.setItem("SearchTest", this.searchTest);
    this.router.navigate(["search",this.searchTest])
  }

}

