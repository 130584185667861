<!-- Navbar -->
<app-navbar></app-navbar>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area  register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;">About Us</span>
            </div>
        </div>
       
    </div>
</div>

<!-- End Page Title -->
<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
        <h2 class="headingcolor">About Us</h2>
            <div class="shortborder2"></div>
        </div>
        
    </div>
</section>
<!--Breadcrumb-->
<!-- Choose Us -->
<section class="choose-us-area pb-100">
    <div class="container">
        <div class="col-md-12 infoarea" style="box-shadow: 0 0 10px 3px rgb(0 0 0 / 30%);">
            <!-- <span style="color:#ef8017;font-size:20px;">Why choose us</span> -->
            <!-- <h3 class="text-center">A hospital is an institution where patients are treated. Our doctors are always engaged in this work.
            </h3> -->
            <p style="text-align: center;">Lab Door is a rapidly growing online platform to offer affordable lab test solutions. Lab Door allows its members to search from the wide variety of pathology labs, compare among the top labs and choose from the most affordable tests in few simple steps. 
            </p>
            <p style="text-indent: 20px;">We aim to bring pathology at your door for smoother operations and hassle-free testing experience. Lab Door offers seamless home collection of samples for more than 200 pathology tests.
            </p>
            <img class ="img-responsive editedimg" src="assets/img/package/services-bg.jpg" width="400px" style= "float:right;height: 250px !important;"/>
            <p>We are a NABL Accredited lab that employs highly quipped and well-trained phlebotomist(s) for sample collection. <b>Your safety is our guarantee.</b> 
            </p>
           
            <p><b>Don’t want to go to the lab for report collection? No worries! We have got it covered too!
            </b></p>
            <p>Get your reports delivered directly to your Email Inbox and WhatsApp for ease of access and quick consultation. 
            </p>
            <p><b>Getting a test done was neither quick nor easy</b>.<br> We have made it possible by collaborating with reputed Path Labs which offers high weightage to Lab Door members.
            </p>
            <p><b>Are you looking forward to get some tests done?
            </b></p>
            <p>Don’t hesitate! Book a Home Collection NOW or call us at +91- 7058 555 470. &nbsp; We make sure to send a qualified phlebotomist at your door within an hour of your call. 
            </p>
            
            <p>Lab Door is a rapidly growing group and we are making continuous changes to facilitate our members even better. 
            </p>
            <p><b>We live by performing best for our people! Let us deliver you the best health diagnosis experience ever.
            </b></p>
            <p>Keep a keen eye at the latest happenings at Lab Door, stay connected with us through our portal www.arlabdoors.com 
            </p>
            <p>Have some suggestion or a robust idea, feedback or complaint? We are all ears! Let us hear you talk. Contact us
            </p>
            <p style="text-align: center;"><strong><i>We are Lab Doors - your personalized health lab!
            </i></strong></p>
            <div class="panel-group" id="accordion">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">
                        <a data-toggle="collapse" class="headingcolor" href="#collapse1">What made us launch Lab Door?</a>
                        </h4>
                    </div>
                    <div id="collapse1" class="panel-collapse collapse in">
                        <div class="panel-body">
                            <p>Looking at the way we operate in health care industry, we realized severe urgency to launch a platform that can be easily accessible, acceptable and understandable to common man regarding the advanced path labs and home collections. 
                            </p>
                            <p>Many a time we have seen people struggling to reach one best path lab due to lack of information. Many often found them getting forged by unauthorized and unauthentic individuals or entities. Most of the times, elderly people, rural population and young children become victims of such acts.
                            </p>
                            <p>With the idea of Lab Door, we wanted to curb this gap and this is where our journey towards building a transparent, reliable and authentic platform emerged. 
                            </p>
                            <p>Today, we are proud to have partnered with reputed pathology labs offering the most authentic testing services. This gives a greater sense of pride and humbleness to have given the society a much-needed healthcare solution. </p>
                            <p><strong><i>We are proud to be Lab Doors! We are proud to deliver you the most authentic testing solutions!
                            </i></strong></p>
                        </div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse" class="headingcolor"  data-parent="#accordion" href="#collapse2">
                            Our Offerings</a>
                      </h4>
                    </div>
                    <div id="collapse2" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="aboutul">
                            <li>We provide the most up-to-date, authentic and trustworthy test offerings by licensed and listed pathology labs. </li>
                            <li>Fast and hassle-free home collection of samples by qualified phlebotomist(s).</li>
                            <li>Quick delivery of reports in electronic format via Email and WhatsApp.</li>
                            <li>Free guidance and support by Lab Door service staff.</li>
                            <li>Comparison of testing services through our portal or app and much more value adds. 
                                </li>
                        </ul>
                      </div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse"class="headingcolor" data-parent="#accordion" href="#collapse3">
                            Life at Lab Doors</a>
                      </h4>
                    </div>
                    <div id="collapse3" class="panel-collapse collapse">
                      <div class="panel-body">
                        <p>Lab Doors believe in creating a supportive work culture that not only strengthens one’s ability to perform but also create future leaders. We strive to bring career progression to every individual’s life.
                            Our culture represents us as the best performing health care professionals who are dedicated towards bringing positive changes in society.
                            </p>
                            <p><b>We are One
                            </b></p>
                            <p>At Lab Doors we are one team rather than boss or employee. This helps us bring a greater sense of responsibility in our workforce and as a result we keep progressing every day better than the last.
                            </p>
                            <p><b>Accountability and Responsibility
                            </b></p>
                            <p>Each team member at Lab Doors understands accountability and exhibits higher responsibility towards any given task that makes us stand apart from our counterparts.
                            </p>
                            <p><b>Ethical Behavior
                            </b></p>
                            <p>Since we consider ourselves as One Team, we are a group of highly ethical individuals who respects each other’s work style irrespective of any cultural, religious or demographical and racial boundaries.
                            </p>
                            <p><strong><i> We are one, we are Lab Doors!</i></strong></p>
                            
                      </div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse"class="headingcolor" data-parent="#accordion" href="#collapse4">
                            Contact Us</a>
                      </h4>
                    </div>
                    <div id="collapse4" class="panel-collapse collapse">
                      <div class="panel-body">
                        
                        
                                    <p style="text-align: center;">We love to hear from our customers. Please feel free to contact us for any feedback, query or suggestions. We are here and we are all ears! 
                                    </p>
                               
                                <!-- <div class="col-md-6 col-sm-12" >
                                    <p><i class="flaticon-telephone-1"> </i> 
                                        <span> Call : </span>
                                        <a  href="tel:+91 7058 555 470">+91 7058 555 470</a></p>
                                     <p><i class="flaticon-arroba"> </i> 
                                    <span> Email : </span>
                                    <a  href="mailto:labdoor@gmail.com">labdoor@gmail.com</a></p>
                                    <p><i class="flaticon-address-1"> </i> 
                                        <span>Address : </span>
                                        Flat No 2,Gitanarayan Enclave, Dalubhai Patel Colony, C. Sambhaji Chowk, Nashik</p>
                                </div>
                                 -->
                            
                      </div>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse" class="headingcolor"  data-parent="#accordion" href="#collapse5">
                            Our Specialty</a>
                      </h4>
                    </div>
                    <div id="collapse5" class="panel-collapse collapse">
                      <div class="panel-body">
                        <p class="text-center"><b>Authenticity. Quality. Reliability. Transparency. Affordability.
                        </b></p>
                        <p><b>Authenticity</b></p>
                        <p>You get only authentic test labs listed under Lab Doors valued partners. Never do we partner with unauthorized entities or individuals.</p>

                        <p><b>Quality</b></p>
                        <p>Your safety is our responsibility. Hence, offering quality services is our first and foremost concern.</p>

                        <p><b>Reliability</b></p>
                        <p> Along with our diagnosis partners, we strive to offer the most reliable services for a seamless diagnosis experience.</p>

                        <p><b>Transparency</b></p>
                        <p> We are highly transparent with our members and we release timely updates regarding any changes or happenings taking place at Lab Doors.</p>

                        <p><b>Affordability</b></p>
                        <p>Lab Doors is your sole partner in delivering the most affordable diagnosis options. We provide you the best comparison and listing of the most affordable tests under one roof.</p>

                      </div>
                    </div>
                </div>
            </div>
            <section class="text-center mt-3 mb-2">
                <img src="assets/img/auth.png" alt="Image"> &nbsp; 
                <img src="assets/img/quality.png" alt="Image"> &nbsp; 
                <img src="assets/img/reliable.png" alt="Image"> &nbsp; 
                <img src="assets/img/trans.png" alt="Image"> &nbsp; 
                <img src="assets/img/affor.png" alt="Image">
            </section>

        </div>

        <!-- <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-us-img">
                    <img src="assets/img/choose-us-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-us-content">
                    <h3>In remote & the around places who lives there, medical science is changing their medical
                        care,education & research impact</h3>
                    <p>Our most experienced doctors,emergency doctors,nurses,therapist & researchers give Their Best in
                        this hospital.they also provide advanced medicine facilities,campuses,clinics & classrooms.we
                        give patients best innovative treatment and best service to improve the health and overall
                        wellbeing of our community.</p>
                </div>
            </div>
        </div> -->
    </div>

    <!-- <div class="choose-us-shape"><img src="assets/img/choose-us-shape.png" alt="Image"></div> -->
</section>
<!-- End Choose Us -->

<!-- Appointment -->
<!-- <section class="appointment-area">
    <div class="container">
        <div class="appointment-here-form">
            <form>
                <div class="row align-items-center">
                    <div class="col-lg-12 col-sm-6">
                        <h3 style="color: #ef8017;text-align: center;">Book an Appointment</h3>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6" style="margin-left: 100px;">
                        <div class="form-group">
                            <select class="form-control" style="border-right: 1px solid black;">
                                <option class="form-control" value="">Doctor Name</option>
                                <option class="form-control" value="">Dr.Juhon Dew</option>
                                <option class="form-control" value="">Dr.Jon Smith Care</option>
                                <option class="form-control" value="">Dr.Ana</option>
                                <option class="form-control" value="">Dr.Kilva</option>
                                <option class="form-control" value="">Dr.Zilka Smith</option>
                                <option class="form-control" value="">Dr.Jilva Dew</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <div class="input-group date" id="datetimepicker" >
                                <input type="text" class="form-control" style="border-right: 1px solid black;" placeholder="07/09/2020">
                                <span class="input-group-addon"></span>
                            </div>
                            <i class="bx bx-calendar"></i>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <button type="submit" class="default-btn viewbtn">Book Now</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section> -->
<!-- End Appointment -->

<!-- Doctors -->
<!-- <section class="doctors-area pt-50">
    <div class="container">
        
        <div class="row pt-4 mt-3">
            <div class="col-lg-12 text-center">
                <div class="section-title text-center" style="margin-bottom: 10px;">
                    <h4 style="color:#079ca3;font-size:30px">Our specialists</h4>
                </div>
                <div class="shortborder2"></div>
               
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-1.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Cosmetic Surgeon</span>
                        <h3>Bernarda N. Bacon</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-2.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Surgery Surgeon</span>
                        <h3>Brian Christensen</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-3.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Dental surgeon</span>
                        <h3>Aimee C. Mayfield</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-4.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Cardiothoracic Surgeon</span>
                        <h3>Gabriel Henderson</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Doctors -->

<!-- Online Treatment -->
<!-- <div class="container">
    <div class="row pt-4 mt-3">
        <div class="col-lg-12 text-center">
            <div class="section-title text-center" style="margin-bottom: 10px;">
                <h4 style="color:#079ca3;font-size:30px">See a doctor online</h4>
            </div>
            <div class="shortborder2"></div>
           
        </div>
    </div>
</div> -->
<!-- <section class="online-treatment-area ptb-50">
    <div class="container">
       
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="online-treatment-img">
                    <img src="assets/img/online-treatment-img.jpg" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="online-treatment-content">
                    <h3>There is an online doctor to provide medical services to patients 24 hours a day</h3>
                    <ul>
                        <li><i class="bx bx-check"></i> Doctors appointments normally</li>
                        <li><i class="bx bx-check"></i> Registered doctors</li>
                        <li><i class="bx bx-check"></i> Unlimited 24/7 video chat</li>
                        <li><i class="bx bx-check"></i> Prescription collection</li>
                        <li><i class="bx bx-check"></i> Message with your provider</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Online Treatment -->

<!-- Testimonial -->
<!-- <section class="testimonial-area ptb-50">
    <div class="container">
        <div class="row pt-4 mt-3">
            <div class="col-lg-12 text-center">
                <div class="section-title text-center" style="margin-bottom: 10px;">
                    <h4 style="color:#079ca3;font-size:30px">Testimonials</h4>
                </div>
                <div class="shortborder2"></div>
            </div>
        </div>
        <div class="section-title text-align-left">
            
            <h2>What do our patients say?</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-1.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                            viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Markus Twain</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-2.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                            viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Anna Deo</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-3.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                            viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Kilvis Smith</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Testimonial -->

<!-- Start Partner Area -->
<!-- <div class="partner-area5 ptb-100">
    <div class="container">
        <div class="partner-slider partner-bg owl-theme owl-carousel">
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-1.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-2.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-3.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-4.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-5.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-3.png" alt="Image">
                </a>
            </div>
        </div>
    </div>

    <div class="partner-shape"><img src="assets/img/partner/partner-shape.png" alt="Image"></div>
</div> -->
<!-- End Partner Area -->

<!-- Footer -->
<app-footer></app-footer>
<!-- End Footer -->