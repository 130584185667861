import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginCredential } from 'src/app/entities/login/logincredential';
import { MyOrder } from 'src/app/entities/order/myorder';
import { MyOrderGroup } from 'src/app/entities/order/myordergroup';
import { MyOrderResponse } from 'src/app/entities/order/myorderresponse';
import { MyPrescriptionOrder } from 'src/app/entities/order/myprescriptionorder';
import { ResponseWithoutData } from 'src/app/entities/responsewithoutdata';
import { OrderService } from 'src/app/service/order.service';

@Component({
  selector: 'app-myorders',
  templateUrl: './myorders.component.html',
  styleUrls: ['./myorders.component.scss']
})
export class MyordersComponent implements OnInit {

  public isMyOrder: number = 0;
  public cred: LoginCredential;
  public myOrders: MyOrder[] = new Array<MyOrder>();
  public userOrderGroup: MyOrderGroup[] = new Array<MyOrderGroup>();
  public orderColor: string[] = ['#ffbc00', '#00ff00', '#ff0000', '#0095ff', '#00ffb8', '#efff00', '#ff0099'];

  public selectedOrder: MyOrder = new MyOrder();
  public isCancelPrescriptionOrderDialog: boolean;
  public reasonDesc: string;

  public valueOfTest: number = 0;
  public discount: number = 0;
  public subTotal: number = 0;
  public walletCoupon: number = 0;
  public finalTotal: number = 0;
  public payMode: string = "";
  public isPaymentInfoDialog: boolean;

  constructor(private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router,
    private orderService: OrderService) { }

  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    if (this.cred && this.cred.Cust_Id) {
      if (sessionStorage.getItem("MyOrderDone")) {
        this.isMyOrder = parseInt(sessionStorage.getItem("MyOrderDone"));
        sessionStorage.removeItem("MyOrderDone")
      }
      await this.getMyOrders();
    }
    else {
      this.router.navigate(["/"]);
    }
  }

  public removeMessage() {
    this.isMyOrder = 0;
  }

  private async getMyOrders() {
    try {
      this.spinnerService.show();
      let res: MyOrderResponse = await this.orderService.getMyOrders(this.cred.Cust_Id);
      if (res && res.res_code == 1) {
        this.myOrders = res.data;
        this.userOrderGroup = [];
        this.myOrders.forEach(element => {
          let orderGroup = new MyOrderGroup();
          if (element.Test_Status.toLowerCase() == "pending")
            element.OrderStatusColorCode = this.orderColor[0];
          if (element.Test_Status.toLowerCase() == "complete")
            element.OrderStatusColorCode = this.orderColor[1];
          if (element.Test_Status.toLowerCase() == "Cancel")
            element.OrderStatusColorCode = this.orderColor[2];
          if (element.Test_Status.toLowerCase() == "assign")
            element.OrderStatusColorCode = this.orderColor[3];
          if (element.Test_Status.toLowerCase() == "picked up")
            element.OrderStatusColorCode = this.orderColor[4];
          if (element.Test_Status.toLowerCase() == "received ld")
            element.OrderStatusColorCode = this.orderColor[5];
          if (element.Test_Status.toLowerCase() == "lab sent")
            element.OrderStatusColorCode = this.orderColor[6];
          // if (element.Test_Status == "Delivered")
          //   element.OrderStatusColorCode = this.orderColor[3];
          // if (element.Test_Status == "Cancelled")
          //   element.OrderStatusColorCode = this.orderColor[4];
          // if (element.Test_Status == "Returned")
          //   element.OrderStatusColorCode = this.orderColor[5];

          if (this.userOrderGroup.length == 0) {
            orderGroup.Payment_Mode = element.Payment_Mode;
            orderGroup.Order_Id = element.Order_Id;
            orderGroup.TotalAmount = element.Selling_Amt - element.Coupon_Code_Amount;// + element.Shipping_Charges + element.CGST_Amount + element.SGST_Amount + element.IGST_Amount;
            orderGroup.Orders.push(JSON.parse(JSON.stringify(element)));
            this.userOrderGroup.push(orderGroup);
          }
          else {
            let index = this.userOrderGroup.findIndex(c => c.Order_Id == element.Order_Id);
            if (index >= 0) {
              this.userOrderGroup[index].TotalAmount += element.Selling_Amt;// + element.Shipping_Charges + element.CGST_Amount + element.SGST_Amount + element.IGST_Amount;
              this.userOrderGroup[index].Orders.push(JSON.parse(JSON.stringify(element)));
            }
            else {
              orderGroup.Payment_Mode = element.Payment_Mode;
              orderGroup.Order_Id = element.Order_Id;
              orderGroup.TotalAmount = element.Selling_Amt - element.Coupon_Code_Amount;// + element.Shipping_Charges + element.CGST_Amount + element.SGST_Amount + element.IGST_Amount;
              orderGroup.Orders.push(JSON.parse(JSON.stringify(element)));
              this.userOrderGroup.push(orderGroup);
            }
          }

        });
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public openPaymentInfo(userOrder: MyOrderGroup) {
    if (userOrder.Orders && userOrder.Orders.length > 0) {
      userOrder.Orders.forEach(element => {
        this.valueOfTest += element.Test_MRP;
        this.discount += element.Disc_Amt;
        this.subTotal += element.Selling_Amt;
        this.walletCoupon = element.Coupon_Code_Amount;
        this.finalTotal += element.Selling_Amt;
        this.payMode = element.Payment_Mode == "COD" ? element.Payment_Mode : "Paid";
      });
      this.finalTotal -= this.walletCoupon;
      this.isPaymentInfoDialog = true;
    }
  }

  public closePaymentInfoDialog() {
    this.isPaymentInfoDialog = false;
    this.valueOfTest = 0;
    this.discount = 0;
    this.subTotal = 0;
    this.walletCoupon = 0;
    this.finalTotal = 0;
    this.payMode = null;
  }

  public openCancelOrderDialog(order: MyOrder) {
    this.isCancelPrescriptionOrderDialog = true;
    this.selectedOrder = JSON.parse(JSON.stringify(order));
    this.reasonDesc = "";
  }

  public closeCancelOrderDialog() {
    this.isCancelPrescriptionOrderDialog = false;
  }

  public async cancelOrder(form: NgForm) {
    try {
      if (form.valid) {
        this.spinnerService.show();
        let res: ResponseWithoutData = await this.orderService.cancelMyPrescriptionOrders(this.selectedOrder.Order_Detail_Id, this.reasonDesc);
        if (res && res.res_code == 1) {
          this.toastrService.success("Your Test is Cancelled");
          this.isCancelPrescriptionOrderDialog = false;
          let index = this.myOrders.findIndex(c => c.Order_Detail_Id == this.selectedOrder.Order_Detail_Id);
          if (index >= 0)
            this.myOrders[index].Test_Status = "Cancel";
          this.userOrderGroup = [];
          this.myOrders.forEach(element => {
            let orderGroup = new MyOrderGroup();
            if (element.Test_Status.toLowerCase() == "pending")
              element.OrderStatusColorCode = this.orderColor[0];
            if (element.Test_Status.toLowerCase() == "complete")
              element.OrderStatusColorCode = this.orderColor[1];
            if (element.Test_Status.toLowerCase() == "Cancel")
              element.OrderStatusColorCode = this.orderColor[2];
            if (element.Test_Status.toLowerCase() == "assign")
              element.OrderStatusColorCode = this.orderColor[3];
            if (element.Test_Status.toLowerCase() == "picked up")
              element.OrderStatusColorCode = this.orderColor[4];
            if (element.Test_Status.toLowerCase() == "received ld")
              element.OrderStatusColorCode = this.orderColor[5];
            if (element.Test_Status.toLowerCase() == "lab sent")
              element.OrderStatusColorCode = this.orderColor[6];

            if (this.userOrderGroup.length == 0) {
              orderGroup.Payment_Mode = element.Payment_Mode;
              orderGroup.Order_Id = element.Order_Id;
              orderGroup.TotalAmount = element.Selling_Amt - element.Coupon_Code_Amount;// + element.Shipping_Charges + element.CGST_Amount + element.SGST_Amount + element.IGST_Amount;
              orderGroup.Orders.push(JSON.parse(JSON.stringify(element)));
              this.userOrderGroup.push(orderGroup);
            }
            else {
              let index = this.userOrderGroup.findIndex(c => c.Order_Id == element.Order_Id);
              if (index >= 0) {
                this.userOrderGroup[index].TotalAmount += element.Selling_Amt;// + element.Shipping_Charges + element.CGST_Amount + element.SGST_Amount + element.IGST_Amount;
                this.userOrderGroup[index].Orders.push(JSON.parse(JSON.stringify(element)));
              }
              else {
                orderGroup.Payment_Mode = element.Payment_Mode;
                orderGroup.Order_Id = element.Order_Id;
                orderGroup.TotalAmount = element.Selling_Amt - element.Coupon_Code_Amount;// + element.Shipping_Charges + element.CGST_Amount + element.SGST_Amount + element.IGST_Amount;
                orderGroup.Orders.push(JSON.parse(JSON.stringify(element)));
                this.userOrderGroup.push(orderGroup);
              }
            }

          });
        }
        else {
          this.toastrService.warning(res.msg);
        }
        this.spinnerService.hide();
      }
      else {
        Object.keys(form.controls).forEach(key => {
          form.controls[key].markAsTouched();
        });
      }
    } catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

}
