<app-navbar></app-navbar>
<!-- Page Title -->
<div class="page-title-area register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;"> Contact Us</span>
            </div>
        </div>
        
    </div>
</div>
<!-- End Page Title -->

<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 class="headingcolor">Contact Us</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->

<!-- Start Contact Area -->
<section class="contact-area pb-100">
    <div class="container">
        <div class="row ">
            <div class="col-lg-8">
                <div class="contact-form">
                    <div class="contact-title">
                        <h2 class="headingcolor">Drop us message for any query</h2>
                    </div>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" name="name" id="name" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="email" name="email" id="email" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Subject</label>
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Phone</label>
                                    <input type="text" name="phone_number" id="phone_number" required
                                        class="form-control">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Message</label>
                                    <textarea name="message" class="form-control" id="message" cols="30"
                                        rows="3"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn btn-two viewbtn">
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-4 ">
                <div class="address-and-week add">
                    <p>For 24/7 emergency please call us. If is no emergency, contact at normal time.</p>

                    <div class="address-week">
                        <ul class="address notype">
                            <li>
                                <i class="flaticon-telephone-1"></i>
                                <span>Call:</span>
                                <a style="color: black;" href="tel:+1-(514)-312-5678">+91 7058 555 470</a>
                            </li>
                            <li>
                                <i class="flaticon-arroba"></i>
                                <span>Email:</span>
                                <a  style="color: black;" href="mailto:support@scora.com">labdoor@gmail.com</a>
                            </li>
                            <li style="color: black;" >
                                <i class="flaticon-address-1"></i>
                                <span>Address:</span>
                                Flat No 2,Gitanarayan Enclave, Dalubhai Patel Colony, C. Sambhaji Chowk, Nashik
                            </li>
                        </ul>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<div class="map-area">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96573.31103674119!2d-73.91097366523668!3d40.85176866829554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c28b553a697cb1%3A0x556e43a78ff15c77!2sThe%20Bronx%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1599153418461!5m2!1sen!2sbd"></iframe>
</div>
<!-- End Map Area -->
<app-footer></app-footer>