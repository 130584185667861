import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './component/aboutus/aboutus.component';
import { BillSummaryComponent } from './component/bill-summary/bill-summary.component';
import { CategorywisetestComponent } from './component/categorywisetest/categorywisetest.component';
import { ContactusComponent } from './component/contactus/contactus.component';
import { HomePageComponent } from './component/home-page/home-page.component';
import { LabDetailComponent } from './component/lab-detail/lab-detail.component';
import { MycartComponent } from './component/mycart/mycart.component';
import { MyordersComponent } from './component/myorders/myorders.component';
import { MyprescriptionorderComponent } from './component/myprescriptionorder/myprescriptionorder.component';
import { MyreportsComponent } from './component/myreports/myreports.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { PackageDetailComponent } from './component/package-detail/package-detail.component';

import { PopularTestComponent } from './component/popular-test/popular-test.component';
import { RefreshComponentComponent } from './component/refresh-component/refresh-component.component';
import { SearchpackagetestComponent } from './component/searchpackagetest/searchpackagetest.component';
import { ViewAllCategoriesComponent } from './component/view-all-categories/view-all-categories.component';
import { ViewAllPackagesComponent } from './component/view-all-packages/view-all-packages.component';
import { ViewprofileComponent } from './component/viewprofile/viewprofile.component';
import { WalletComponent } from './component/wallet/wallet.component';
import {PrivacypolicyComponent } from './component/privacypolicy/privacypolicy.component'
const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'RefreshComponent', component: RefreshComponentComponent },
  { path: 'nav', component: NavbarComponent },
  { path: 'packages', component: ViewAllPackagesComponent },
  { path: 'categories', component: ViewAllCategoriesComponent },
  { path: 'populartest', component: PopularTestComponent },
  { path: 'packagedetail/:labId/:testId', component: PackageDetailComponent },
  { path: 'labdetail/:cityId/:labId', component: LabDetailComponent },
  { path: 'mycart/:custId', component: MycartComponent },
  { path: 'billsummary', component: BillSummaryComponent },
  { path: 'categorywisetest/:testCategoryName/:testCategoryId/:cityId', component: CategorywisetestComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'viewprofile/:custId', component: ViewprofileComponent },
  { path: 'wallet', component: WalletComponent },
  { path: 'myorders', component: MyordersComponent },
  { path: 'myprescriptionorders', component: MyprescriptionorderComponent },
  { path: 'myreports', component: MyreportsComponent },
  { path: 'search/:searchText', component: SearchpackagetestComponent },
  { path :'privacypolicy', component: PrivacypolicyComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
