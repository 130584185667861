<app-navbar></app-navbar>
<div class="page-title-area register-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;"> My Prescription Order</span>
            </div>
        </div>
        
    </div>
</div>
<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 class="headingcolor">My Prescription Orders</h2>
            <div class="shortborder2"></div>
        </div>
    </div>
</section>
<!--Breadcrumb-->
<section class="checkout-area">
    <div class="container">
        <div class="row">
            <div class="col-md-10 leftoffset">
            <ng-container *ngIf="(myOrders && myOrders.length>0)">
                <div class="packagecard card-body border-0"
                    style="box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.30);border-radius: 20px; margin-top: 5px;"
                    *ngFor="let order of myOrders">
                    <div class="my-order-title " style="position: relative;">
                        <div class="grid-title-container">
                            <div class="grid-item1">
                                <span style="font-size: 18px;font-weight: 600;">Date: </span>
                                <span style="font-weight: 600;">{{order.Order_Date_Time | date:'dd-MMM-yyyy'}}</span>
                            </div>
                            <div class="grid-item1" style="text-align: right;">
                                <span style="font-size: 18px;font-weight: 600;">Status: </span>
                                <span [style.color]="order.OrderStatusColorCode"
                                    style="font-weight: 600;">{{order.Order_status}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="grid-title-container pt-3">
                        <div class="grid-item1">Name:
                            <span style="color:#000;font-weight: 600;">{{order.Member_Name}}</span>
                            &nbsp;&nbsp;
                            <span style="color:#000;font-weight: 600;">{{order.Member_Gender}}</span>&nbsp;&nbsp;
                            <span style="color:#000;font-weight: 600;">{{order.Member_Age}} Years</span>
                        </div>
                        <div class="grid-item1" style="text-align: right;">
                            <i title="View Prescription" style="cursor:pointer;"
                                (click)="viewPrescriptionImage(order.Prescription_Photo)"
                                class="bx bx-sm bxs-image-alt"></i>
                        </div>
                        <div class="grid-item1">
                            Status: <span> {{order.Order_Cancelation_Reason}}</span>
                        </div>
                        <div class="grid-item1" style="text-align: right;font-weight: 600;">
                            <!-- <a *ngIf="order.Order_status=='Pending'" (click)="openCancelOrderDialog(order)"
                            style="cursor: pointer;color:#0000ff">Cancel</a> -->
                            <a style="cursor: pointer;color:#fa2424" *ngIf="order.Order_status=='Pending'"
                                (click)="openCancelOrderDialog(order)">
                                Cancel <i class="flaticon-right-arrow"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="!(myOrders && myOrders.length>0)" class="card-body border-0"
                style="height:395px;box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05); margin-top: 5px;display: flex;width: 100%;align-items: center;justify-content: center;">

                <h5>You will see your prescription order here.</h5>

            </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>

<ng-container *ngIf="isCancelPrescriptionOrderDialog">
    <div
        style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
        <div style="position: fixed;top: 10%;left: 25%;width: 50%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
            role="dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Cancel Prescription Order</h5>
                    <p class="text-right"><i class="flaticon-close" style="cursor: pointer;"
                            (click)="closeCancelOrderDialog()"></i></p>
                </div>
                <div class="modal-body modal13" style="max-height: calc(100vh - 240px);overflow: auto;">
                    <div class="container-fluid p-0">
                        <div class="row">

                            <div class="col-12">
                                <div class="modal-bg checkout-page">
                                    <div class="age-content checkout-form">
                                        <form id="CancelForm" #CancelForm="ngForm" class="theme-form">
                                            <div class="form-row">
                                                <!-- <div class="col-md-12">
                                                    <input type="text" class="form-control" id="review" name="review"
                                                        placeholder="Reason for {{title}}"
                                                        [(ngModel)]="productCancel.Order_Cancelation_Reason"
                                                        #ReviewTitle="ngModel" required>
                                                    <div class="text text-danger"
                                                        *ngIf="ReviewTitle.invalid && (ReviewTitle.dirty || ReviewTitle.touched)">
                                                        Reason is required!
                                                    </div>
                                                </div> -->
                                                <div class="col-md-12">
                                                    <textarea class="form-control" name="desc" style="height: 130px"
                                                        placeholder="Cancellation Reason"
                                                        id="exampleFormControlTextarea1" rows="6"
                                                        [(ngModel)]="reasonDesc" #ReviewTitle="ngModel"
                                                        required></textarea>
                                                    <div class="text text-danger"
                                                        *ngIf="ReviewTitle.invalid && (ReviewTitle.dirty || ReviewTitle.touched)">
                                                        Reason is required!
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="default-btn viewbtn" type="submit"
                        (click)="cancelOrder(CancelForm)">Continue</button>
                    <button type="submit" (click)="closeCancelOrderDialog()" class="default-btn viewbtn">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isPrescriptionImageDialog">
    <div
        style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
        <div style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
            role="dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Prescription Image</h5>
                    <p class="text-right"><i class="flaticon-close" style="cursor: pointer;"
                            (click)="closePrescriptionImageDialog()"></i></p>
                </div>
                <div class="modal-body modal13" style="max-height: calc(100vh - 240px);overflow: auto;">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="modal-bg checkout-page">
                                    <div class="age-content checkout-form" style="text-align: center;">
                                        <img src="{{prescriptionImg}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>