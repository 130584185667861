import { Component, OnInit } from '@angular/core';
import { CategoryTestService } from '../../service/categorytest.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PackageResponse } from '../../entities/home/packageresponse';
import { Package } from 'src/app/entities/home/package';
import { ActivatedRoute, Router } from '@angular/router'
import { Page } from 'src/app/entities/page';
import { LabPackageService } from 'src/app/service/labpackage.service';

@Component({
  selector: 'app-categorywisetest',
  templateUrl: './categorywisetest.component.html',
  styleUrls: ['./categorywisetest.component.scss']
})
export class CategorywisetestComponent implements OnInit {
  public page = new Page();
  private skip: number = 0;
  private take: number = 6;
  public categoryTest: Package[];
  public testCategoryName: string;
  private totalProduct: number = 0;
  public paginate: any = {};
  private pageNo: number = 1;
  private loadproduct: number = 6;
  private cityId: number = 1;
  private testCategoryId: number = 1;

  constructor(
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router,
    private categoryTestService: CategoryTestService,
    private route: ActivatedRoute,
    private labPackageService: LabPackageService
  ) { }

  async ngOnInit() {
    let sub = this.route.params.subscribe(async (params) => {
      this.testCategoryId = +params['testCategoryId']; // (+) converts string 'id' to a number  
      this.cityId = +params['cityId'];
      this.testCategoryName = params["testCategoryName"];
      this.getCategoryWiseTest(this.cityId, this.testCategoryId)
    });
  }

  public async setPage(page: number) {
    this.pageNo = page;
    this.skip = (page - 1) * this.loadproduct;
    this.take = this.loadproduct;
    await this.getCategoryWiseTest(this.cityId, this.testCategoryId);
  }

  private async getCategoryWiseTest(cityId: number, testCategoryId: number) {
    try {
      this.spinnerService.show();
      let res: PackageResponse = await this.categoryTestService.getCategoryWiseTest(cityId, testCategoryId, this.skip, this.take);
      if (res && res.res_code == 1) {
        this.categoryTest = res.data;
        this.totalProduct = res.Total_Test_Count;
        this.paginate = this.labPackageService.getPager(res.Total_Test_Count, +this.pageNo, this.loadproduct);
      }
      else
        this.toastrService.error(res.msg);

      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public redirectTestDetail(labId: number, testId: number): void {
    this.router.navigate(['packagedetail', labId, testId]);
  }

}