<header class="header-area header-area-two">

    <!-- Top Header -->
    <div class="top-header top-header-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-sm-4 textcenter">
                    <ul class="header-left-content">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>

                <div class="col-lg-9 col-sm-8">
                    <ul class="header-right-content">
                        <li *ngIf="!classReference?.isUserLoggedIn">
                            <a style="cursor: pointer;" (click)="openLogin()">Login/Sign Up</a><i
                                class="pl-2 bx bx-user"></i>
                        </li>
                        <li *ngIf="classReference?.isUserLoggedIn" class="auth-hover">
                            <a>Welcome {{cred.Cust_Name}}</a><i style="cursor: pointer;"
                                class="pl-2 bx bx-caret-down"></i>
                            <div class="other-option">
                                <div class="option-item">

                                    <div class="profile-overlay profile-popup">
                                        <p (click)="redirectviewprofile()"
                                            style="cursor: pointer;padding: 5px;margin-bottom: 5px;"
                                            class="mouse-hover-active">
                                            Profile</p>
                                        <p (click)="redirectToMyOrder()"
                                            style="cursor: pointer;padding: 5px;margin-bottom: 5px;"
                                            class="mouse-hover-active">
                                            My Orders</p>
                                        <p (click)="redirectToMyPrescriptionOrder()"
                                            style="cursor: pointer;padding: 5px;margin-bottom: 5px;"
                                            class="mouse-hover-active">
                                            My Prescription Orders</p>
                                        <p (click)="redirectToMyReports()"
                                            style="cursor: pointer;padding: 5px;margin-bottom: 5px;"
                                            class="mouse-hover-active">
                                            My Reports</p>
                                        <p (click)="loggedOut()"
                                            style="cursor: pointer;padding: 5px;margin-bottom: 5px;"
                                            class="mouse-hover-active">Log Out</p>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li *ngIf="cred?.City_Name;else defaultCity">
                            <!-- <i class="bx  bxs-map"></i> -->
                            <a style="cursor: pointer;" (click)="userverify2()">{{cred.City_Name}}</a><i
                                class="pl-2 bx  bx-map"></i>
                        </li>
                        <li>
                            <a (click)="callRequest()" style="cursor: pointer;">{{companyData?.Company_Contact_No1}}</a>
                            <i class="pl-2 bx bxs-phone"></i>
                        </li>
                        <ng-template #defaultCity>
                            <li>
                                <a style="cursor: pointer;" (click)="userverify2()">{{cityBeforeLogin.City_Name}}</a>
                                <i class="pl-2 bx  bxs-map"></i>

                            </li>
                        </ng-template>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area">
        <div class="mobile-nav">
            <div class="container">
                <a routerLink="/" class="logo"><img src="assets/img/logo2.png" alt="Logo" style="width: 65px;
                    object-fit: cover;"></a>
                <a routerLink="/" class="logo"><img src="assets/img/logo1.png" alt="Logo" style="width: 200px;
                                        object-fit: cover;"></a>
            </div>
        </div>

        <div class="main-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md">
                    <a routerLink="/" class="logo"><img src="assets/img/logo2.png" alt="Logo" style="width: 65px;
                        object-fit: cover;"></a>
                    <a routerLink="/" class="logo"><img src="assets/img/logo1.png" alt="Logo" style="width: 200px;
                                            object-fit: cover;"></a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav m-auto">
                            <li class="nav-item">
                                <a [routerLink]="[('/')]" class="nav-link">Home</a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/packages']" class="nav-link">Packages </a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/categories']" class="nav-link">Categories</a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="['/populartest']" class="nav-link">Popular Tests</a>
                            </li>


                            <li class="nav-item">
                                <a [routerLink]="['/aboutus']" class="nav-link">About Us </a>


                            </li>

                            <li class="nav-item"><a [routerLink]="['/contactus']" class="nav-link">Contact</a></li>
                        </ul>

                        <div class="others-option">

                            <div class="cart-icon" style="top:-6px">
                                <button class="p-2 default-btn default-btn1" (click)="userverify1()"
                                    style="cursor: pointer;font-size: 13px;">
                                    <i class="pr-2 bx bx-upload" style="line-height: 0px;font-size: 14px;"></i>Upload
                                    Prescription</button>
                            </div>

                            <div class="cart-icon">
                                <a (click)="userVerify()" style="cursor: pointer;">
                                    <i class="bx bx-cart"></i>
                                    <span *ngIf="!classReference?.isUserLoggedIn else cartcount">0</span></a>
                                <ng-template #cartcount>
                                    <span>{{cartCount}}</span>
                                </ng-template>
                            </div>
                            <div class="cart-icon">
                                <a (click)="userVerifyFOrWallet()"><i class="bx bx-wallet" style="cursor: pointer;"></i>
                                </a>
                            </div>

                        </div>
                    </div>
                </nav>
            </div>
        </div>

        <div class="others-option-for-responsive">
            <div class="container">
                <div class="dot-menu">
                    <div class="inner">
                        <div class="circle circle-one"></div>
                        <div class="circle circle-two"></div>
                        <div class="circle circle-three"></div>
                    </div>
                </div>

                <div class="container">
                    <div class="option-inner">
                        <div class="others-option justify-content-center d-flex align-items-center">
                            <div class="cart-icon">
                                <a class="p-2 default-btn" (click)="userverify1()"
                                    style="cursor: pointer;font-size: 12px;background-color: #ffffff;color:#ef8017">
                                    <i class="pr-2 bx bx-upload"
                                        style="line-height: 0px;font-size: 14px;color:#ef8017"></i>Upload
                                    Prescription</a>
                            </div>
                            <div class="cart-icon">
                                <a (click)="userVerify()"><i class="bx bx-cart"></i>
                                    <span *ngIf="!classReference?.isUserLoggedIn else cartcount">0</span></a>
                                <ng-template #cartcount>
                                    <span>{{cartCount}}</span>
                                </ng-template>
                            </div>
                            <div class="cart-icon">
                                <a (click)="userVerifyFOrWallet()"><i class="bx bx-wallet"></i>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="classReference.isLoginDialog">
        <div
            style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(1px);">
            <div style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
                role="dialog">
                <div class="modal-content resmodal">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Login/Register</h5>
                        <button type="button" (click)="closeLogin()" class="close" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body modal13" style="max-height: calc(100vh - 240px);overflow: auto;">
                        <div class="container-fluid p-0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="modal-bg checkout-page">
                                        <div class="age-content checkout-form">

                                            <!-- <h4>Set Location For Rent Toys</h4> -->
                                            <form id="LoginForm" #LoginForm="ngForm">
                                                <div class="row check-out">
                                                    <div class="form-group col-md-12 col-sm-12 col-xs-12"
                                                        *ngIf="!isRegistration">
                                                        <div class="field-label">Enter your mobile number to continue
                                                        </div>
                                                        <div class="shortborder"></div>
                                                        <div class="form-group row" style="margin-top:10px;">
                                                            <label class="col-sm-3 col-form-label" for="MobileNumber"><b>Mobile Number :</b></label>
                                                            <div class="col-sm-9"><input appMobilevalidation="true" type="text"
                                                                class="form-control" id="MobileNumber" name="mobileNo"
                                                                #FirstFocus placeholder="Enter your mobile number" [(ngModel)]="mobileNo"
                                                                #Mobile="ngModel" (keyup.enter)="login()" ContactNo
                                                                required>
                                                                <div class="text text-danger"
                                                                    *ngIf="Mobile.invalid && (Mobile.dirty || Mobile.touched)">
                                                                    Mobile Number is Required..!!
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="recaptcha-container"></div>
                                                <div *ngIf="isVerifyOtp">
                                                    <div class="text-center card border-0">
                                                        <p class="a-o-i">Verification</p>
                                                        <p class="text-muted">Enter OTP sent to your registered
                                                            mobile
                                                            number</p>
                                                        <ng-otp-input [config]="config" #ngOtpInput
                                                            (onInputChange)="onOtpChange($event)"></ng-otp-input>
                                                        <span class="text-center">{{errormsg}}</span>
                                                    </div>
                                                </div>
                                                <div *ngIf="isCustomerDetail">
                                                    <form id="RegisterForm" #RegisterForm="ngForm" novalidate
                                                        style="max-height: calc(100vh - 240px);overflow: auto;">
                                                        <div class=" form-group">
                                                            <input type="text" class=" form-control"
                                                                [(ngModel)]="customer.Cust_Name" name="name"
                                                                #name="ngModel" required pattern="[a-zA-Z ]*"
                                                                placeholder="Enter Name">
                                                            <span class="text-danger"
                                                                *ngIf="(name.touched || RegisterForm.submitted) && name.errors?.required">
                                                                Name is required
                                                            </span>
                                                            <span class="text-danger"
                                                                *ngIf="(name.touched || RegisterForm.submitted) && name.errors?.pattern">
                                                                Name only allow a-z A-Z ,-,_
                                                            </span>
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="Email" class="form-control"
                                                                [(ngModel)]="customer.Email_Id" id="Email" name="email"
                                                                placeholder="Enter Email" required #Email="ngModel"
                                                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                                                            <span class="text text-danger"
                                                                *ngIf="Email.invalid && (Email.dirty || Email.touched)">
                                                                Email is required!
                                                            </span>
                                                            <span class="text text-danger"
                                                                *ngIf="Email.touched && Email.hasError('pattern')">
                                                                Enter valid email address!
                                                            </span>
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" class="form-control"
                                                                [(ngModel)]="customer.Cust_Age" id="Age" name="age"
                                                                placeholder="Enter Age" required #Age="ngModel">
                                                            <span class="text text-danger"
                                                                *ngIf="Age.invalid && (Age.dirty || Age.touched)">
                                                                Age is required!
                                                            </span>
                                                        </div>
                                                        <div class="form-group">
                                                            <select name="Gender" class="form-control select-text"
                                                                [(ngModel)]="customer.Gender" name="Gender" required
                                                                #Gender="ngModel">
                                                                <option class="dropdown-item" disabled selected
                                                                    value="undefined">Select Gender</option>
                                                                <option class="dropdown-item" value="Male">Male
                                                                </option>
                                                                <option class="dropdown-item" value="Female">Female
                                                                </option>
                                                            </select>
                                                            <span class="text text-danger"
                                                                *ngIf="Gender.invalid && (Gender.dirty || Gender.touched)">
                                                                Gender is required!
                                                            </span>
                                                        </div>
                                                        <div class="form-group">
                                                            <select [(ngModel)]="selectedCity" name="City"
                                                                class="form-control select-text" #City="ngModel"
                                                                required>
                                                                <option class="dropdown-item" disabled selected
                                                                    value="undefined">Select City</option>
                                                                <option *ngFor="let getCity of GetCity"
                                                                    class="dropdown-item" [ngValue]="getCity">
                                                                    {{getCity.City_Name}}</option>
                                                            </select>
                                                            <span class="text-danger"
                                                                *ngIf="(City.touched || RegisterForm.submitted) && City.errors?.required">
                                                                City is required
                                                            </span>
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="text" class="border-bottom form-control"
                                                                id="Referral_Code" name="Referral_Code"
                                                                placeholder="Referral Code">
                                                        </div>

                                                        <div class="text-center">
                                                            <div class="form-group">
                                                                <button type="button" class="mr-2 default-btn"
                                                                    (click)="registerCustomer()"
                                                                    [disabled]="RegisterForm.invalid">Register</button>
                                                                <button type="button" class="default-btn"
                                                                    (click)="RegisterForm.reset()">Reset</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button *ngIf="!isRegistration" type="submit" [disabled]="!mobileNo" (click)="login()"
                            class="default-btn loginbtn">
                            Login
                        </button>
                        <button *ngIf="isVerifyOtp" type="button" class="text-center default-btn loginbtn"
                            (click)="verifyOTP()">Verify
                        </button>
                        <!-- <button *ngIf="isCustomerDetail" type="button" class="mr-2 default-btn"
                            (click)="registerCustomer()" [disabled]="RegisterForm.invalid">Register</button>
                        <button *ngIf="isCustomerDetail" type="button" class="default-btn"
                            (click)="RegisterForm.reset()">Reset</button> -->
                        <!-- <button *ngIf="isRegistration && !isCustomerDetail" type="submit" [disabled]="!OTP" (click)="verifyOTP()" class="btn btn-solid">
                  Verify
                </button> -->
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="cityselection">
        <div
            style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 1050; overflow: hidden; outline: 0; backdrop-filter: blur(2px);">
            <div style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
                role="dialog">
                <div class="inner-border modal-content d-block">
                    <div class="modal-header">
                        <h3 class="modal-title">Select City</h3>
                        <button type="button" (click)="closeCitySelection()" class="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body modal13" style="height: calc(100vh - 300px);overflow: auto;">
                        <div class="container-fluid p-0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="modal-bg checkout-page">
                                        <table class="table ">
                                            <tbody>
                                                <tr *ngFor="let getCity of GetCity; index as i">

                                                    <td style="cursor: pointer;border-bottom: 1px solid #dee2e6; border-top: none;"
                                                        (click)="changeCity(getCity.City_Name,getCity.City_Id)">
                                                        {{getCity.City_Name}}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" style="justify-content: flex-start !important;">
                        <p class="modal-title text-justify" id="exampleModalLabel" style="color:#e28d1d;">Change
                            location
                            will also delete item in your
                            cart</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="uploadprescription">
        <div
            style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 1050; overflow: hidden; outline: 0; backdrop-filter: blur(1px);">
            <div style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
                role="dialog">
                <div class="inner-border modal-content d-block">
                    <div class="modal-header">
                        <h3 class="modal-title">{{title}}</h3>
                        <button type="button" (click)="closeUploadPrescription()" class="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form class="theme-form" style="padding: 10px;">
                        <div class="modal-body modal13" style="max-height: calc(100vh - 240px);overflow: auto;">
                            <div class="container-fluid p-0">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="modal-bg checkout-page">
                                            <div *ngIf="ismemeberlist">
                                                <form class="theme-form" style="padding: 10px;"
                                                    #uploadPrescription="ngForm">
                                                    <div class="packagecard card-body border-0"
                                                        style="box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.30);margin-bottom: 0;margin-top: 10px;">
                                                        <h5 class="d-inline" style="color: #079ca3;">{{memberName |
                                                            titlecase}}
                                                        </h5>
                                                        <button style="cursor: pointer;"
                                                            class="default-btn viewbtn d-inline float-right"
                                                            (click)="ismemeberlist=false;isfamily=true; changeMember()">Change</button>
                                                        <h6>{{memberGender | titlecase}} | {{memberAge}} years</h6>
                                                    </div>

                                                    <div class="text-center d-inline">
                                                        <label class="default-btn border-0" for="file"
                                                            style="background-color:#ef8017;color: white;cursor:pointer;margin-top: 10px;">
                                                            <i class="bx bx-upload p-1"></i>Upload Prescription
                                                            <input type="file" id="file" style="display: none"
                                                                name="image" accept="image/*"
                                                                data-original-title="upload photos"
                                                                (change)="selectedImage($event);">
                                                        </label>
                                                    </div>
                                                    <div class="d-inline">
                                                        <img class="profilepic" *ngIf="prescription"
                                                            [src]="prescription" style="height: 105px;
                                                            width: auto;
                                                            margin-left: 45px;
                                                            margin-top: 10px;">
                                                    </div>
                                                </form>
                                                <p class="mt-1" style="font-size: 13px;">*Prescription should be image file. </p>
                                                <p class="" style="font-size: 13px;">*Image Size
                                                        should be less than 1MB*. </p>
                                            </div>
                                            <div *ngIf="isfamily">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="mt-2 card member-border"
                                                            style="border:none;box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.15);"
                                                            *ngFor="let familymember of familymember;index as i">
                                                            <div class=" p-2  card-body">
                                                                <h5 class="d-inline" style="color: #079ca3;">
                                                                    {{familymember.Member_Name | titlecase}}</h5>

                                                                <button (click)="deleteDialog(familymember)"
                                                                    class="default-btn viewbtn deletebtn mr-2"
                                                                    style="width:40px;cursor: pointer;float: right;padding: 9px 10px;">
                                                                    <i class="bx bx-trash">
                                                                    </i>
                                                                </button>


                                                                <button
                                                                    (click)="isaddmemberform = !isaddmemberform;isfamily= false;ismemeberlist = false;bindEditData(familymember)"
                                                                    class="default-btn viewbtn mr-2"
                                                                    style="width:40px; cursor: pointer;float: right;padding: 9px 10px;">
                                                                    <i class="bx bx-edit">
                                                                    </i>
                                                                </button>

                                                                <button (click)="selectMemeberForTest(familymember)"
                                                                    class="default-btn viewbtn mr-2"
                                                                    style="cursor:pointer;float:right;">
                                                                    Select
                                                                </button>
                                                                <h6>{{familymember.Member_Gender}} |
                                                                    {{familymember.Member_Age}}
                                                                    years</h6>
                                                            </div>
                                                        </div>
                                                        <div class="text-center mt-5">
                                                            <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
                                                                <button type="submit"
                                                                    (click)="isfamily=false;addNewMember()"
                                                                    class="default-btn viewbtn" style="width: 200px;">
                                                                    Add New Member
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="isaddmemberform" class="container">
                                                <form class="theme-form" style="padding: 10px;" #AddMemberForm="ngForm">
                                                    <label for="Name">Name</label>
                                                    <div class=" form-group">
                                                        <input type="text" class=" form-control" name="name"
                                                            #name="ngModel" required pattern="[a-zA-Z ]*"
                                                            placeholder="Enter Name"
                                                            [(ngModel)]="selectedMember.Member_Name">
                                                        <span class="text-danger"
                                                            *ngIf="(name.touched || AddMemberForm.submitted) && name.errors?.required">
                                                            Name is required!!
                                                        </span>
                                                        <span class="text-danger"
                                                            *ngIf="(name.touched || AddMemberForm.submitted) && name.errors?.pattern">
                                                            Name only allow a-z A-Z ,-,_
                                                        </span>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Gender</label>
                                                        <div class="form-control">
                                                            <label class="radio-inline mr-3">
                                                                <input type="radio" name="gender" value="male"
                                                                    [(ngModel)]="selectedMember.Member_Gender" required
                                                                    #gender="ngModel">
                                                                Male
                                                            </label>
                                                            <label class="radio-inline">
                                                                <input type="radio" name="gender" value="female"
                                                                    [(ngModel)]="selectedMember.Member_Gender" required>
                                                                Female
                                                            </label>
                                                            <span class="text-danger"
                                                                *ngIf="(gender.touched || AddMemberForm.submitted) && gender.errors?.required">
                                                                Gender is required!!
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <label for="Age">Age</label>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="selectedMember.Member_Age" id="Age" name="age"
                                                            placeholder="Enter Age" required #Age="ngModel">
                                                        <span class="text text-danger"
                                                            *ngIf="Age.invalid && (Age.dirty || Age.touched)">
                                                            Age is required!!
                                                        </span>
                                                    </div>
                                                    <label for="Relation">Relation</label>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" required
                                                            #Relation="ngModel"
                                                            [(ngModel)]="selectedMember.Member_Relation" name="relation"
                                                            placeholder="Enter Relation" />
                                                        <span class="text text-danger"
                                                            *ngIf="Relation.invalid && (Relation.dirty || Relation.touched)">
                                                            Relation is required!!
                                                        </span>
                                                    </div>
                                                    <div class="text-center">
                                                        <button type="submit" [disabled]="!(AddMemberForm.valid)"
                                                            (click)="addMember(AddMemberForm)" class="default-btn viewbtn">
                                                            Save
                                                        </button>
                                                        <button type="submit" (click)="closeaddmember();"
                                                            class="ml-2 default-btn viewbtn">
                                                            cancel
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="ismemeberlist == true" class="modal-footer">
                            <button type="submit" [disabled]="!(prescription)" (click)="addPrescription()"
                                class="default-btn viewbtn">
                                Save
                            </button>
                        </div>
                        <div *ngIf="isfamily == true" class="modal-footer">
                            <button type="button" (click)="closefamily()" class="default-btn viewbtn">
                                cancel
                            </button>
                        </div>


                    </form>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isDelete">
        <div
            style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
            <div style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
                role="dialog">
                <div class="modal-content resmodal">
                    <div class="modal-body modal13" style="overflow: auto;">
                        <div class="container-fluid p-0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="modal-bg checkout-page">
                                        <div class="container" style="padding: 16px;text-align: center;">
                                            <h2 class="headingcolor">{{mainTitle}}</h2>
                                            <p>{{deleteMsg}}</p>

                                            <div class="mt-3 clearfix">
                                                <button type="button" class="default-btn viewbtn" style="
                                                background-color: darkgray;
                                                color: #fff;
                                                border-color: #079ca3;
                                                margin-right: 10px;" (click)="closeDeleteMemeberDialog()">No</button>
                                                <button type="button"
                                                    (click)="deletemember(deletefamilymem.Cust_Family_Member_Id)"
                                                    class="default-btn viewbtn">Yes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="iscall">
        <div
            style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
            <div style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
                role="dialog">
                <div class="modal-content resmodal">
                    <div class="modal-header text-center">
                        <h3 class="modal-title">Thank you For Submitting Call Request</h3>
                        <p class="text-right"><i class="flaticon-close" style="cursor: pointer;"
                                (click)="iscall=false;"></i></p>

    
                    </div>
                    <div class="modal-body modal13" style="max-height: calc(100vh - 240px);overflow: auto;">
                        <div class="container-fluid p-0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="modal-bg checkout-page">
                                        <div class="container" style="padding: 16px;text-align: center;">

                                            <p>Our Health Advisers Will Contact You instantly</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>
</header>


<!-- End Header -->