import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../src/environments/environment";
import { TestCategoryResponse } from '../entities/home/testcategoryresponse';
@Injectable({
    providedIn: 'root'
  })
  export class CategoryService {
  
    constructor(private http: HttpClient) { }
  
    async getAllTestCategories(): Promise<TestCategoryResponse> {
        let parameter = JSON.parse(JSON.stringify({ "Type": "All" }))
        return await this.http.post(environment.apiUrl + "lab_door/api/get_test_categories_for_home_page", parameter)
          .toPromise() as TestCategoryResponse;
      }
}