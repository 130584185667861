import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyData } from 'src/app/entities/home/companydata';
import { CompanyDataResponse } from 'src/app/entities/home/companydataresponse';
import { HomeService } from 'src/app/service/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  public companyData : CompanyData[]; 
  
  constructor(
    private homeService: HomeService,
    private toasterService: ToastrService
  ) { }

  async ngOnInit() {
    this.getcompanydata();    
  }

  private async getcompanydata() {
    try {
      let res: CompanyDataResponse = await this.homeService.getcompanydata();
      if (res && res.data) {
        this.companyData =res.data
      }
    }
    catch(error) {
      this.toasterService.error(error,"Error")
    }
  }
}
