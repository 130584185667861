import { MyOrder } from "./myorder"

export class MyOrderGroup {
    Order_Id: number
    Payment_Mode: string
    TotalAmount: number
    Orders: MyOrder[]

    constructor() {
        this.Orders = new Array<MyOrder>();
    }
    
}