export class PlaceOrder {
    Payment_Mode:string
    Payment_Transaction_ID: string
    Cust_Id: number
    Cust_Name: string
    Mobile_Number: string
    Email_Id: string
    App_Type: string
    Delivery_Address_Id: number
    Full_Name: string
    Mobile_No_Address: number
    Flat_House_No: string
    Area_Colony_Sreet_Name: string
    Landmark: string
    Pincode: number
    Address_City_Id: number
    Address_City: string
    State: string
    Address_Type: string
    Delivery_LT: number
    Delivery_LG: number
    Member_Name: string
    Member_Gender: string
    Member_Age: number
    Sample_Collection_Date: string
    Time_Slot_Id: number
    Time_Slot_Name: string
    Coupon_Code_Name: string
    Coupon_Code_Amount: number
    Lab_City_Test_Rate_Id: string
    Lab_City_Id: string
    Lab_Id: string
    City_Id: string
    Test_Id: string
    Qty: string
    Test_MRP: string
    Disc_Per: string
    Disc_Amt: string
    Selling_Amt: string


    constructor() { }
}