<app-navbar></app-navbar>
<div class="page-title-area register-page">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-12">
                <a routerLink="/" style="display:inline-block;">Home</a>
                / <span style="color: #ef8017;"> Packages</span>
            </div>
        </div>
    </div>
</div>
<!--Breadcrumb-->
<section class="contact-page">
    <div class="container">
        <div class="section-title text-center mt-3">
            <h2 style="color:#079ca3;">All Packages</h2>
            <div class="shortborder2"></div>
        </div>
        
    </div>
</section>
<!--Breadcrumb-->
<section class="services-area-two bg-t pb-5">
    <div class="container">
        <div class="row" *ngIf="labPackages && labPackages.length>0 else NoData">
            <div class="col-lg-offset-1 col-lg-4 col-sm-6 pb-2 pr-0" style="padding: 10px 40px;" *ngFor="let pkg of labPackages">
                <div class="packagecard card border-0 rightMargin"
                    style="box-shadow:0 0 10px 3px rgba(0, 0, 0, 0.30); padding-top:5px;margin-bottom: 10px;">
                    <div class="card-body" style="height: 330px;padding: 15px;">
                        <div style="cursor: pointer;" (click)="redirectPackageDetail(pkg.Lab_Id,pkg.Test_Id)">
                            <h6 class=" mb-2" style="height: 30px;">{{pkg.Test_Name}}</h6>
                            <div style="height: 225px;">
                                <div style="height:130px">
                                    <p class="mb-0 pt-1"> Includes:<b>{{pkg.Parameter_Included}}
                                            Parameter</b></p>
                                    <ul class="highlight-test mt-1" >
                                        <li *ngIf="pkg.Highlighted_Test_1_Web">
                                            {{pkg.Highlighted_Test_1_Web}}
                                        </li>
                                        <li *ngIf="pkg.Highlighted_Test_2_Web">
                                            {{pkg.Highlighted_Test_2_Web}}
                                        </li>
                                        <li *ngIf="pkg.Highlighted_Test_3_Web">
                                            {{pkg.Highlighted_Test_3_Web}}
                                        </li>
                                    </ul>
                                </div>
                                <p class="font-weight-bolder mb-0"
                                    style="text-align: center;font-size: 18px;border-bottom: 1px solid #919292;margin-top: 5px;color: #079ca3;padding-bottom: 10px;">
                                    {{pkg.Lab_Name}}
                                </p>
                                <div class="mt-2" style="border-bottom: 1px solid #919292;padding-bottom: 4px;">
                                    <h4 style="font-size:20px;display: inline;">₹{{pkg.Selling_Amt}}
                                        <del *ngIf="pkg.Disc_Per!=0" style="color: #aaa;font-size: 16px;">
                                            ₹{{pkg.Test_MRP}}
                                        </del>
                                    </h4>
                                    <span *ngIf="pkg.Disc_Per>0;"
                                        style="color: #ef8017;border-radius: 5px 0 0 5px;float: right;">
                                        {{pkg.Disc_Per | number:'0.0-0'}}% OFF
                                    </span>
                                </div>
                            </div>
                            <ul style="padding-top: 10px;">
                                <li class="read">
                                    <a style="cursor: pointer;color:#ef8017"
                                        (click)="redirectPackageDetail(pkg.Lab_Id,pkg.Test_Id)">
                                        Know More <i class="flaticon-right-arrow"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="text-center">
                    <app-pagination [labPackages]="labPackages" [paginate]="paginate" (setPage)="setPage($event)">
                    </app-pagination>
                </div>
            </div>
        </div>
        <ng-template #NoData>
            <h2 class="py-5 text-center">No Data Avaliable</h2>
        </ng-template>
    </div>
</section>
<app-footer></app-footer>