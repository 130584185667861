import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginCredential } from 'src/app/entities/login/logincredential';
import { MyPrescriptionOrder } from 'src/app/entities/order/myprescriptionorder';
import { MyPrescriptionOrderResponse } from 'src/app/entities/order/myprescriptionorderresponse';
import { ResponseWithoutData } from 'src/app/entities/responsewithoutdata';
import { OrderService } from 'src/app/service/order.service';

@Component({
  selector: 'app-myprescriptionorder',
  templateUrl: './myprescriptionorder.component.html',
  styleUrls: ['./myprescriptionorder.component.scss']
})
export class MyprescriptionorderComponent implements OnInit {

  public cred: LoginCredential;
  public orderColor: string[] = ['#ffbc00', '#00ff00', '#ff0000', '#0095ff'];
  public myOrders: MyPrescriptionOrder[] = new Array<MyPrescriptionOrder>();
  public selectedOrder: MyPrescriptionOrder = new MyPrescriptionOrder();
  public isCancelPrescriptionOrderDialog: boolean;
  public reasonDesc: string;
  public isPrescriptionImageDialog: boolean;
  public prescriptionImg: string;

  constructor(
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router,
    private orderService: OrderService
  ) { }

  async ngOnInit() {
    this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
    if (this.cred && this.cred.Cust_Id) {
      await this.getMyPrescriptionOrders();
    }
    else {
      this.router.navigate(["/"]);
    }
  }

  private async getMyPrescriptionOrders() {
    try {
      this.spinnerService.show();
      let res: MyPrescriptionOrderResponse = await this.orderService.getMyPrescriptionOrders(this.cred.Cust_Id);
      if (res && res.res_code == 1) {
        this.myOrders = res.data;
        this.myOrders.forEach(element => {
          if (element.Order_status.toLowerCase() == "pending")
            element.OrderStatusColorCode = this.orderColor[0];
          if (element.Order_status.toLowerCase() == "accept")
            element.OrderStatusColorCode = this.orderColor[1];
          if (element.Order_status.toLowerCase() == "cancelled")
            element.OrderStatusColorCode = this.orderColor[2];
        });
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public async cancelOrder(form: NgForm) {
    try {
      if (form.valid) {
        this.spinnerService.show();
        let res: ResponseWithoutData = await this.orderService.cancelMyPrescriptionOrders(this.selectedOrder.Prescription_Order_Id, this.reasonDesc);
        if (res && res.res_code == 1) {
          this.toastrService.success("Your Order is cancelled");
          let index = this.myOrders.findIndex(c => c.Prescription_Order_Id == this.selectedOrder.Prescription_Order_Id);
          this.myOrders[index].Order_status = "Cancelled";
          this.myOrders[index].Order_Cancelation_Reason = this.reasonDesc;
          this.myOrders[index].OrderStatusColorCode = this.orderColor[2];
          this.isCancelPrescriptionOrderDialog = false;
        }
        else {
          Object.keys(form.controls).forEach(key => {
            form.controls[key].markAsTouched();
          });
        }
        this.spinnerService.hide();
      }
      else {
        Object.keys(form.controls).forEach(key => {
          form.controls[key].markAsTouched();
        });
      }
    } catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }

  public viewPrescriptionImage(imageUrl: string) {
    this.isPrescriptionImageDialog = true;
    this.prescriptionImg = imageUrl
  }
  public closePrescriptionImageDialog() {
    this.isPrescriptionImageDialog = false;
    this.prescriptionImg = null;
  }

  public openCancelOrderDialog(order: MyPrescriptionOrder) {
    this.isCancelPrescriptionOrderDialog = true;
    this.selectedOrder = JSON.parse(JSON.stringify(order));
    this.reasonDesc = "";
  }

  public closeCancelOrderDialog() {
    this.isCancelPrescriptionOrderDialog = false;
  }

}
