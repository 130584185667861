import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMobilevalidation]'
})
export class MobilevalidationDirective {
  private regex = /^((\\+91-?)|0)?[0-9]{1,10}$/;
  constructor(public el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {

    if (["Backspace", "Tab", "End", "Home", "Delete", "ArrowLeft", "ArrowRight"].indexOf(e.key) != -1)
      return;
   
    let current: string = this.el.nativeElement.value;

    let next: string = current.concat(e.key);
    if (next && !this.regex.test(next))
      e.preventDefault();
  }
}




