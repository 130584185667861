import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CouponCodeResponse } from '../entities/order/couponcoderesponse';
import { MyOrderResponse } from '../entities/order/myorderresponse';
import { MyPrescriptionOrderResponse } from '../entities/order/myprescriptionorderresponse';
import { MyReportsResponse } from '../entities/order/myreportsresponse';
import { PlaceOrder } from '../entities/order/placeorder';
import { ResponseWithoutData } from '../entities/responsewithoutdata';

@Injectable({
    providedIn: 'root'
})

export class OrderService {

    constructor(private http: HttpClient) { }

    async checkCouponCode(couponCode: string): Promise<CouponCodeResponse> {
        let parameter = JSON.parse(JSON.stringify({ "Coupon_Code": couponCode }))
        return await this.http.post(environment.apiUrl + "lab_door/api/check_coupon_code", parameter)
            .toPromise() as CouponCodeResponse;
    }

    async placeOrder(parameter: PlaceOrder): Promise<ResponseWithoutData> {
        return await this.http.post(environment.apiUrl + "lab_door/api/place_order", parameter)
            .toPromise() as ResponseWithoutData;
    }

    async getMyOrders(custId: number): Promise<MyOrderResponse> {
        let parameter = { "Cust_Id": custId }
        return await this.http.post(environment.apiUrl + "lab_door/api/get_my_order", parameter)
            .toPromise() as MyOrderResponse;
    }

    async getMyPrescriptionOrders(custId: number): Promise<MyPrescriptionOrderResponse> {
        let parameter = { "Cust_Id": custId }
        return await this.http.post(environment.apiUrl + "lab_door/api/get_my_uploaded_prescription", parameter)
            .toPromise() as MyPrescriptionOrderResponse;
    }

    async cancelMyPrescriptionOrders(prescriptionOrderId: number, reason: string): Promise<ResponseWithoutData> {
        let parameter = { "Prescription_Order_Id": prescriptionOrderId, "Order_Cancelation_Reason": reason }
        return await this.http.post(environment.apiUrl + "lab_door/api/cancel_prescription_order", parameter)
            .toPromise() as ResponseWithoutData;
    }

    async cancelMyOrdersTest(Order_Detail_Id: number, reason: string): Promise<ResponseWithoutData> {
        let parameter = { "Order_Detail_Id": Order_Detail_Id, "Cancellation_Reason": reason }
        return await this.http.post(environment.apiUrl + "lab_door/api/cancel_my_ordered_test", parameter)
            .toPromise() as ResponseWithoutData;
    }

    async getMyReports(custId: number): Promise<MyReportsResponse> {
        let parameter = { "Cust_Id": custId }
        return await this.http.post(environment.apiUrl + "lab_door/api/get_my_report", parameter)
            .toPromise() as MyReportsResponse;
    }

}