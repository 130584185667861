import { Component, OnInit } from '@angular/core';
import { LoginCredential } from 'src/app/entities/login/logincredential';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerProfileImage } from '../../entities/customerprofile';
import { LoginService } from 'src/app/service/login.service';
import { CustomerDetail } from 'src/app/entities/login/customerdetail';
import { formatCurrency } from '@angular/common';
import { NgForm } from '@angular/forms';
import { CustomerService } from 'src/app/service/customer.service';
import { MyCartService } from 'src/app/service/mycart.service';
import { AddMember } from 'src/app/entities/cart/addmember';
import { AddMemberResponse } from 'src/app/entities/cart/addmemberresponse';

@Component({
  selector: 'app-viewprofile',
  templateUrl: './viewprofile.component.html',
  styleUrls: ['./viewprofile.component.scss']
})
export class ViewprofileComponent implements OnInit {

  public profileBase: string;
  private cred: LoginCredential;
  private custId: number;
  public customerdetail: CustomerDetail = new CustomerDetail;
  public familyMember: AddMember[] = new Array<AddMember>();
  public isaddmemberform: boolean = false;
  public title: string;
  public selectedMember: AddMember;
  public isOpenMemberDialog: boolean;
  public mainTitle: string;
  public deleteMsg: string;
  public isDeleteMemeber: boolean;
  public deletefamilymem: AddMember = new AddMember();

  constructor(
    private spinnerService: NgxSpinnerService,
    private loginService: LoginService,
    private toastrService: ToastrService,
    public route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private mycartservice: MyCartService
  ) { }

  async ngOnInit() {
    let sub = this.route.params.subscribe(async (params) => {
      this.custId = +params['custId'];
      this.cred = JSON.parse(sessionStorage.getItem("Credential")) as LoginCredential;
     
      await this.getprofile();
      await this.getmember(this.cred.Cust_Id);
    });
  }
  public async selectedImage(e: any) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    let reader = e.target;
    this.customerdetail.Profile_Image = "";
    this.profileBase = reader.result;
    this.uploadImage();

  }

  private async uploadImage() {
    try {
      this.spinnerService.show();
      let parameter: CustomerProfileImage = new CustomerProfileImage();

      parameter.Cust_Id = this.custId;
      parameter.Profile_Image = this.profileBase.split(",")[1];
      let res = await this.loginService.updateProfileImage(parameter);
      if (res && res.res_code == 1 && res.data) {
        this.toastrService.success("Profile image uploaded");
        this.customerdetail.Profile_Image = res.data[0].Profile_Image;

        this.cred.Profile_Image = res.data[0].Profile_Image;
      }
      this.spinnerService.hide()
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }

  private async getprofile() {
    try {
      this.spinnerService.show();
      let res = await this.loginService.getProfile(this.custId);
      if (res && res.res_code == 1) {        

        this.customerdetail = res.data[0];


      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }
  public async profileupdate(form: NgForm) {
    try {
      if (form.valid) {
        this.spinnerService.show();
        let profile: CustomerDetail = new CustomerDetail();
        profile.Cust_Id = this.custId;
        profile.Cust_Name = this.customerdetail.Cust_Name;
        profile.Cust_Age = this.customerdetail.Cust_Age;
        profile.Email_Id = this.customerdetail.Email_Id;
        profile.Gender = this.customerdetail.Gender;
        let res = await this.loginService.updateProfile(profile);
        if (res && res.res_code == 1) {
          this.toastrService.success("Profile Updated Suucessfully")
          sessionStorage.setItem("Credential", JSON.stringify(res.data[0]));
        }
        this.spinnerService.hide();
      }
      else {
        Object.keys(form.controls).forEach(key => {
          form.controls[key].markAsTouched();
        });
      }

    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }

  public changeGender(gender: string) {
    this.customerdetail.Gender = gender
  }

  public async deleteAccount() {
    try {
      this.spinnerService.show();
      // let isConfirm = confirm("You Want To Delete Account??")
      // if (isConfirm) {
      let res = await this.customerService.deleteAccount(this.cred.Cust_Id)
      if (res && res.res_code == 1) {
        sessionStorage.clear();
        //this.isLogin = !this.isLogin;
        //NavbarComponent.isUserLoggedIn = false;
        //this.refresh();
        sessionStorage.removeItem("Credential");
        this.router.navigate(["/"])
      }
      this.spinnerService.hide();
      // }
      // else {
      //   this.isLogin = !this.isLogin;
      //   this.spinnerService.hide();
      // }
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error);
    }

  }

  private async getmember(custId) {
    try {
      this.spinnerService.show();
      // this.editMemberId = this.cred.Cust_Id;
      let res = await this.mycartservice.getmyfamilymember(custId)
      if (res && res.res_code == 1) {
        if (res.data && res.data) {
          this.familyMember = res.data;
        }
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error")
    }
  }

  public addNewMember() {
    this.isaddmemberform = true;
    this.isOpenMemberDialog = true;
    this.title = "Add Member";
    this.selectedMember = new AddMember();
  }

  public async addMember(form: NgForm) {
    try {
      this.spinnerService.show();
      if (form.valid) {
        this.selectedMember.Cust_Id = this.cred.Cust_Id;
        if (!this.selectedMember.Cust_Family_Member_Id) {
          let res: AddMemberResponse = await this.mycartservice.addmemeber(this.selectedMember)
          if (res && res.res_code == 1 && res.data) {
            this.familyMember.push(res.data);
            this.closeAddMemberDialog();
            await this.getmember(this.cred.Cust_Id)
          }
        }
        else {
          let res: AddMemberResponse = await this.mycartservice.updatefamilymember(this.selectedMember);
          if (res && res.res_code == 1 && res.data) {
            let index = this.familyMember.findIndex(c => c.Cust_Family_Member_Id == this.selectedMember.Cust_Family_Member_Id);
            if (index >= 0) {
              this.closeAddMemberDialog();
              // this.ismemeberlist = true;
              this.familyMember[index].Member_Name = res.data.Member_Name;
              this.familyMember[index].Member_Gender = res.data.Member_Gender;
              this.familyMember[index].Member_Age = res.data.Member_Age;
              this.familyMember[index].Member_Relation = res.data.Member_Relation;
            }
          }

        }
      }
      else{
        Object.keys(form.controls).forEach(key => {
          form.controls[key].markAsTouched();
        });
      }
      this.spinnerService.hide();
    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error, "Error");
    }
  }
  public closeAddMemberDialog() {
    this.isOpenMemberDialog = false;
  }

  public bindEditData(member: AddMember) {
    this.title = "Edit Member";
    this.selectedMember = JSON.parse(JSON.stringify(member));
    this.isOpenMemberDialog = true;
    // this.selectedCity = this.cities.find(c => c.City_Id == this.selectedAddress.City_Id);
  }

  public async deleteDialog(member: AddMember) {
    this.mainTitle = "Delete Member";
    this.deleteMsg = "Are You Sure You want to Delete Memeber?"
    this.isDeleteMemeber = true;
    this.deletefamilymem = JSON.parse(JSON.stringify(member));
  }

  public closeDeleteMemeberDialog() {
    this.isDeleteMemeber = false;
    this.deletefamilymem = new AddMember();
  }

  public async deletemember(CustFamilyMemberId) {
    try {
      this.spinnerService.show();
      let res = await this.mycartservice.removemember(CustFamilyMemberId)
      if (res && res.res_code == 1) {
        let index = this.familyMember.findIndex(c => c.Cust_Family_Member_Id == CustFamilyMemberId);
        if (index >= 0) {
          this.familyMember.splice(index, 1);
        }
        // if (this.deletedMember == CustFamilyMemberId) {
        //   this.memberGender = this.cred.Gender;
        //   this.memberName = this.cred.Cust_Name;
        //   this.memberAge = this.cred.Cust_Age;
        // }
        this.toastrService.success("Member Deleted Successfully");
        this.isDeleteMemeber = false;

      }
      this.spinnerService.hide();

    }
    catch (error) {
      this.spinnerService.hide();
      this.toastrService.error(error);
    }
  }
}
